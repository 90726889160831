<template>
  <b-modal
    id="organization-changed"
    class="organizationChanged"
    ref="modal"
    header-class="organizationChanged__header"
    title-class="h3 organizationChanged__title"
    content-class="organizationChanged__content"
    body-class="organizationChanged__body"
    footer-class="organizationChanged__footer"
    hide-footer
    :title="$t('groups.announcement.changingOrgs.title')"
    @close="$emit('viewed', 'organization_changed')"
  >
    <template #modal-header-close>
      <x-icon class="organizationChanged__close" variant="dark" />
    </template>
    <template #default>
      <h2 class="organizationChanged__text" v-html="$t('groups.announcement.changingOrgs.description', { organizationName: organizationName })" />
      <span class="organizationChanged__subtitle" v-html="$t('groups.announcement.changingOrgs.subtitle')" />
      <div class="organizationChanged__cards">
        <div class="card">
          <span class="card__title" v-html="$t('groups.announcement.changingOrgs.cards.colleagues')" />
          <p class="card__body" v-html="$t('groups.announcement.changingOrgs.cards.colleaguesInfo')" />
          <div class="card__halo-bubble">
            <style-bubble class="bubble" :flipped="flipped" :learnerStyle="learnerStyle" />
            <graph-halo class="halo" :variant="learnerStyle"/>
          </div>
        </div>
        <div class="card">
          <span class="card__title" v-html="$t('groups.announcement.changingOrgs.cards.groups')" />
          <p class="card__body" v-html="$t('groups.announcement.changingOrgs.cards.groupsInfo')" />
          <img class="card__image" :src="imagePath" alt="">
        </div>
      </div>
      <p class="organizationChanged__body" v-html="$t('groups.announcement.changingOrgs.moreInfo', {adminEmail: assigneeEmail})" />
    </template>
  </b-modal>
</template>

<script>
import GraphHalo from '../graphs/GraphHalo.vue'
import StyleBubble from '../icons/StyleBubble.vue'
import XIcon from '../icons/XIcon.vue'
import messages from '../../translations/groups'

export default {
  name: 'organization-changed',
  components: { XIcon, GraphHalo, StyleBubble },
  i18n: {
    fallbackLocale: 'en-US',
    messages
  },
  props: {
    flipped: {
      type: Boolean,
      default: true
    },
    learnerStyle: {
      type: String,
      default: 'D'
    },
    imagePath: {
      type: String,
      default: '/images/Groups/NoGroups.svg'
    },
    assigneeEmail: {
      type: String,
      default: ''
    },
    organizationName: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/_base.scss";

::v-deep .organizationChanged {
  &__header {
    padding: 2rem 1rem 0;

    @include media-breakpoint-up ('md') {
      padding: 2rem 2rem 0;
    }
  }

  &__text {
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: $font-size-md;
    font-weight: $font-weight-normal;
  }

  &__title {
    margin: 0;
    width: 100%;
    text-align: center;
  }

  &__subtitle {
    color: $black;
    margin: 0;
    text-align: center;
  }

  &__close {
    width: 1rem;
    height: 1rem;
  }

  &__content {
    max-height: 100%;
    overflow-y: hidden;
  }

  &__body {
    padding: 0;
    text-align: center;
    margin: 0 1rem 1rem;
    font-weight: $font-weight-normal;

    @include media-breakpoint-up('md') {
      margin: 0 2rem 1rem;
    }
  }

  &__image {
    max-width: 20rem;
  }

  &__footer {
    justify-content: center;
    padding: 2rem 1rem;

    @include media-breakpoint-up('md') {
      padding: 2rem;
    }
  }

  &__cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0.5rem 0 1rem;

    @include media-breakpoint-up('md') {
      flex-direction: row;
    }

    .card {
      color: $black;
      background-color: $gray-110;
      border: none;
      margin: 1rem 0 0;
      padding: 1.5rem;
      flex: 1;

      @include media-breakpoint-up('md') {
        margin: 0 0.5rem;
      }

      &__title {
        padding: 0;
        margin: 0;
        font-size: $font-size-base;
        font-weight: $font-weight-bold;
      }

      &__body {
        padding: 0;
        margin: 0.75rem 0 1rem;
        max-width: 100%;
      }

      &__halo-bubble {
        display: grid;
        grid-template-rows: 1fr 9fr;
        column-gap: 0.5rem;
        grid-template-columns: 4fr minmax(2rem, 1fr);
        grid-template-areas:
          "... bubble"
          "halo bubble";

          .halo {
            grid-area: halo;
            max-width: 8rem;
            margin-left: 4.5rem;

            @include media-breakpoint-up('md') {
              margin-left: 3rem;
            }
          }

          .bubble {
            grid-area: bubble;
            max-width: 2rem;

            @include media-breakpoint-up('md') {
              max-width: 3rem;
              justify-self: end;
            }
          }
      }
    }
  }
}

::v-deep .modal-dialog {
  margin-top: 7rem;

  &.modal-md {
    max-width: 37rem;
  }

  p.organizationChanged__text {
    margin-bottom: 2rem;
  }

  p.organizationChanged__body {
    font-size: 0.938rem;

    @include media-breakpoint-up('md') {
      font-size: $font-size-sm;
    }
  }

}

</style>
