import AppLayoutNoScroll from '../app/AppLayoutNoScroll.vue'
import { compose, mapGettersToProps } from 'vuex-compose'

const wrapper = compose(
  mapGettersToProps({
    assigneeEmail: 'account/currentAssigneeEmail'
  })
)

export default wrapper(AppLayoutNoScroll)
