import { UserService } from '../../services/user'
import { profileFields } from '../account'

const state = {
  search: {
    query: '',
    status: 0,
    pending: false,
    error: '',
    suggestions: []
  },
  people: {
    pending: false,
    status: 0,
    error: '',
    pages: 1,
    count: null,
    results: []
  },
  peer: {
    pending: false,
    status: 0,
    error: '',
    profile: {
      ...profileFields
    }
  }
}

const getters = {
  peer: state => state.peer,
  people: state => state.people.results,
  searchQuery: state => state.search.query,
  searchSuggestions: state => state.search.suggestions
}

const actions = {
  async fetch ({ commit, state }, options) {
    commit('peopleRequest')
    try {
      // request account
      if (typeof (options.groupIds) === 'string') {
        options.groupIds = options.groupIds.split(',')
      }
      const pagedResults = await UserService.comparisons(options)
      // commit new profile data
      commit('peopleRequestSuccess', pagedResults)
      return state.people
    } catch (e) {
      commit('peopleRequestError', {
        errorCode: e.errorCode,
        errorMessage: e.message
      })
      return false
    }
  },

  async search ({ commit }, query) {
    commit('peopleSearchRequest', query)
    try {
      const suggestions = await UserService.search(query)

      commit('peopleSearchSuccess', suggestions.people)
      return suggestions.people
    } catch (e) {
      commit('peopleSearchError', {
        errorCode: e.errorCode,
        errorMessage: e.message
      })

      return []
    }
  },

  async one ({ commit }, id) {
    commit('peoplePeerRequest')
    try {
      const peer = await UserService.learner(id)

      commit('peoplePeerSuccess', peer)
      return true
    } catch (e) {
      commit('peoplePeerError', {
        errorCode: e.errorCode,
        errorMessage: e.message
      })
      return false
    }
  }
}

const mutations = {
  peopleRequest (state) {
    state.people.pending = true
    state.people.status = 0
    state.people.error = ''
  },
  peopleRequestSuccess (state, { pages, people, count }) {
    state.people.pages = pages
    state.people.results = people
    state.people.count = count
    state.people.pending = false
    state.people.status = 200
    state.people.error = ''
  },
  peopleRequestError (state, { errorMessage, errorCode }) {
    state.people.pending = false
    state.people.error = errorMessage
    state.people.status = errorCode
  },
  peopleSearchRequest (state, query) {
    state.search.query = query
    state.search.pending = true
    state.search.status = 0
    state.search.error = ''
  },
  peopleSearchSuccess (state, people = []) {
    state.search.suggestions = people
    state.search.token = null
    state.search.pending = false
    state.search.status = 200
    state.search.error = ''
  },
  peopleSearchError (state, { errorMessage, errorCode }) {
    state.search.pending = false
    state.search.error = errorMessage
    state.search.status = errorCode
  },
  peoplePeerRequest (state, query) {
    state.peer.pending = true
    state.peer.status = 0
    state.peer.error = ''
  },
  peoplePeerSuccess (state, peerProfile) {
    state.peer.profile = peerProfile
    state.peer.pending = false
    state.peer.status = 200
    state.peer.error = ''
  },
  peoplePeerError (state, { errorMessage, errorCode }) {
    state.peer.pending = false
    state.peer.error = errorMessage
    state.peer.status = errorCode
  },
  resetPeerProfile (state) {
    state.peer.profile = { ...profileFields }
  }
}

export const people = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
