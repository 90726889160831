<template>
  <svg
    v-if="learnerStyle !== 'all'"
    @click="$emit('on-click')"
    class="bubble__style"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 39 48"
    data-testid="StyleBubble-test-id"
  >
    <g :class="`fill-${learnerStyle}`">
      <polygon points="0 0 39 0 39 48 31 40 0 40 0 0" class="bubble__style--bubble" :transform="orientation" />
      <text
        data-testid="StyleBubble-text-test-id"
        x="50%"
        y="50%"
        dominant-baseline="middle"
        text-anchor="middle"
        :class="`bubble__style--${isDarkText}`"
        class="bubble__style--learner-style"
        font-size="1.125em"
        font-weight="bold"
      >
        {{ translatedLearnerStyle }}
      </text>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'style-bubble',
  props: {
    learnerStyle: {
      type: String,
      default: ''
    },
    flipped: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isDarkText () {
      return this.learnerStyle.toUpperCase().charAt(0) === 'C' ? 'dark' : 'light'
    },
    orientation () {
      const { flipped } = this
      return flipped ? 'scale(-1, 1) translate(-39, 0)' : null
    },
    translatedLearnerStyle () {
      return this.$tt(`products.default.styles.${this.learnerStyle}.name`)
    }
  }
}
</script>
<style lang="scss">
@import "../../styles/base";

.bubble__style {
  position: relative;
  display: block;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: rem-calc(18px);
  color: white;
  font-weight: 400;
  max-width: 100%;

  &::after {
    display: block;
    position: absolute;
    top: 95%;
    right: 0;
    border-bottom: 0.6em solid transparent !important;
    border-right: 0.6em solid;
    border-right-color: inherit;
    content: "";
  }

  &--dark {
    fill: $dark;
  }

  &--light {
    fill: $light;
  }
}
</style>
