export default {
  pageTitles: {
    landingyou: 'Workplace - Everything DiSC',
    whatDrivesYou: 'What drives you - Workplace - Everything DiSC',
    youAndOtherStyles: 'You and other styles - Workplace - Everything DiSC',
    buildBetterRelationships: 'Build better relationships - Workplace - Everything DiSC',
    landingmanagement: 'Management - Everything DiSC ',
    managementOverview: 'Management overview - Management - Everything DiSC',
    'your-management-style': 'Your management style - Management - Everything DiSC',
    'direct-and-delegate': 'Direct & delegate - Management - Everything DiSC',
    motivation: 'Motivation - Management - Everything DiSC',
    developTalentLanding: 'Develop talent - Management - Everything DiSC',
    manageUp: 'Manage up - Management - Everything DiSC',
    landingeq: 'Agile EQ - Everything DiSC',
    whatIsEQ: 'What is EQ? - Agile EQ - Everything DiSC',
    yourEQ: 'Your EQ strengths - Agile EQ - Everything DiSC',
    beyoundYourComfortZone: 'Beyond your comfort zone – Agile EQ - Everything DiSC ',
    developYourEQ: 'Develop your EQ – Agile EQ – Everything DiSC',
    landingbasics: 'DiSC-ology – Everything DiSC',
    overview: 'The DiSC Model – DiSC-ology – Everything DiSC',
    styles: 'The 12 styles – DiSC-ology – Everything DiSC',
    research: 'DiSC research – DiSC-ology – Everything DiSC',
    theory: 'DiSC theory – DiSC-ology – Everything DiSC',
    history: 'DiSC history – DiSC-ology – Everything DiSC',
    accessCode: 'Create your Account - Everything DiSC',
    register: 'Create your Profile - Everything DiSC ',
    registerProfile: 'Create your Profile - Everything DiSC',
    login: 'Login - Everything DiSC',
    password: 'Login - Everything DiSC',
    requestReset: 'Login - Everything DiSC',
    home: 'Welcome - Everything DiSC',
    settings: 'Account Settings - Everything DiSC',
    assessmentIntroduction: 'Introduction - Assessment - Everything DiSC',
    demographics: 'Demographical Information - Assessment - Everything DiSC',
    assessment: 'Assessment - Everything DiSC',
    disc: 'Your DiSC Style - Everything DiSC',
    getAdvice: {
      landing: 'Get Advice - Everything DiSC',
      search: 'Get Advice - {topic} - Everything DiSC',
      compareWithPeer: 'Get Advice - {topic} - {fullName} - Everything DiSC'
    },
    people: 'People - Everything DiSC',
    compare: '{fullName} - People - Everything DiSC',
    reports: 'Download reports – Everything DiSC',
    groups: {
      wizardSteps: {
        0: 'Things to know - Your groups - Everything DiSC',
        1: 'Group information - Your groups - Everything DiSC',
        2: 'Add colleagues - Your groups - Everything DiSC'
      },
      tabs: {
        'group-map': 'Group map - {groupName} - Your Groups - Everything DiSC',
        'the-big-picture': 'The big picture - {groupName} - Everything DiSC',
        'style-insights': 'Style insights - {groupName} - Your groups - Everything DiSC'
      },
      noGroup: 'Create a group - Your groups - Everything DiSC',
      privacyEnabled: 'Privacy-enabled - Your Groups - Everything DiSC'
    },
    terms: 'Terms of Use - Everything DiSC',
    privacyPolicy: 'Privacy Policy - Everything DiSC',
    accessibilityStatement: 'Commitment to Accessibilty - Everything DiSC',
    missingKey: 'Everything DiSC'
  }
}
