<template>
  <div class="dismissableMessage" v-if="!closed">
    <div class="dismissableMessage__text">
      <slot>
          <span :class="`text-${variant}`">{{ message }}</span>
      </slot>
      <b-button class="dismissableMessage__close" variant="link" @click="dismiss">
        <x-icon class="dismissableMessage__closeIcon" :variant="variant" />
      </b-button>
    </div>
  </div>
</template>

<script>
import XIcon from '../icons/XIcon'

export default {
  name: 'dismissable-message',
  components: { XIcon },
  data: () => ({
    closed: false
  }),
  props: {
    message: {
      type: String,
      default: ''
    },
    variant: {
      type: String,
      default: 'disabled'
    }
  },
  methods: {
    dismiss () {
      this.closed = true
      this.$emit('dismissed')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/base";

.dismissableMessage {
  &__text {
    display: flex;
    align-items: center;
    font-style: italic;
    font-size: 0.875rem;
  }

  &__close {
    padding: 0.75rem;
    min-width: 0;
    line-height: 0.625rem;
  }

  &__closeIcon {
    height: 0.625rem;
    width: 0.625rem;
    vertical-align: top;
  }
}
</style>
