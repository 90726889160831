<template>
  <dismissable-message
    variant="darkblue"
    class="dismissableAlert"
    @dismissed="$emit('dismissed')"
  >
    <alert-icon class="dismissableAlert__icon" fillVariant="darkblue" symbolVariant="alertblue" />
      <span class="dismissableAlert__text">
        <slot>{{ text }}</slot>
      </span>
  </dismissable-message>
</template>

<script>
import DismissableMessage from '../content/DismissableMessage'
import AlertIcon from '../icons/AlertIcon'

export default {
  name: 'dismissable-alert',
  components: { DismissableMessage, AlertIcon },
  props: {
    text: String
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/base';

.dismissableAlert {
  background-color: $light-blue;
  border-radius: 0.25rem;

  &__text {
    color: $blue-dark;
    flex: 1 0;
  }

  &__icon {
    margin-left: 0.5rem;
    margin-right: 0.625rem;
    height: 1rem;
    width: 1rem;

    @include media-breakpoint-down('sm') {
      margin-left: 0.625rem;
    }
  }

  ::v-deep .dismissableMessage {
    &__text {
      font-weight: $font-weight-medium;
      font-style: normal;
    }

    &__close {
      @include media-breakpoint-up('md') {
        padding: 0.375rem 0.625rem;
      }
    }
  }
}
</style>
