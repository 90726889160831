import Vue from 'vue'
import { createFocusTrap } from 'focus-trap'

export default Vue.component('focus-trap', {
  data: () => ({
    originalRole: null,
    originalAriaModal: null,
    trap: null
  }),
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    const content = this.$el
    this.trap = createFocusTrap(content)

    this.originalRole = content.role
    this.originalAriaModal = content.ariaModal

    this.setScreenReaderTrap(this.active)
  },
  beforeDestroy () {
    this.trap && this.trap.deactivate()
    this.trap = null
  },
  methods: {
    setScreenReaderTrap (active) {
      const content = this.$el
      if (active) {
        content.role = 'dialog'
        content.ariaModal = true
        this.trap = createFocusTrap(content)
        this.trap.activate()
      } else {
        content.role = this.originalRole
        content.ariaModal = this.originalAriaModal
        this.trap && this.trap.deactivate()
      }
    }
  },
  watch: {
    active (active) {
      this.setScreenReaderTrap(active)
    }
  },
  render () {
    const content = this.$slots.default
    if (!content || content.length > 1) {
      throw new Error('FocusTrap requires exactly one child')
    }
    return content[0]
  }
})
