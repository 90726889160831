import AssessmentServiceMocks from '../assessment/assessment.service.mock'
import DemographicsServiceMocks from '../demographics/demographics.service.mock'
import GroupsServiceMocks from '../groups/groups.service.mock'
import HealthServiceMocks from '../health/health.service.mock'
import LocationServiceMocks from '../location/location.service.mock'
import OrganizationServiceMocks from '../organization/organization.service.mock'
import TextServiceMocks from '../text/text.service.mock'
import UserServiceMocks from '../user/user.service.mock'

export const handlers = (baseURL) => [
  ...AssessmentServiceMocks(baseURL),
  ...DemographicsServiceMocks(baseURL),
  ...GroupsServiceMocks(baseURL),
  ...HealthServiceMocks(baseURL),
  ...LocationServiceMocks(baseURL),
  ...OrganizationServiceMocks(baseURL),
  ...TextServiceMocks(baseURL),
  ...UserServiceMocks(baseURL)
]
