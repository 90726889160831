<template>
  <app-layout-base class="app-layout--no-scroll">
    <template #main>
      <slot />
    </template>
    <template #footer>
      <app-footer :assigneeEmail="assigneeEmail" />
    </template>
  </app-layout-base>
</template>

<script>
import { appLayouts } from './AppConstants'
import AppLayoutBase from './AppLayoutBase'
import AppFooter from './AppFooter'

export default {
  name: appLayouts.NOSCROLL,
  components: { AppLayoutBase, AppFooter },
  props: {
    assigneeEmail: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss">
@import "../styles/base";
.app-layout--no-scroll {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr min-content;
  grid-template-areas:
  "content"
  "footer";
  & > *:not(footer) {
    grid-area: content;
  }
  footer {
    grid-area: footer;
  }
}
</style>
