import StorageService from '../../services/storage'
import store from '../../store'
import { find, get } from 'lodash'

const REPORT_IDS = {
  WP: 12,
  EQ: 13,
  MGMT: 15
}

const LEARNER_CONTEXTS = {
  1: 'Virtual Training',
  2: 'In-Person Training',
  3: 'Onboarding',
  4: 'Applicant',
  5: 'Coaching',
  6: 'Other',
  7: "Don't know"
}

const pendo = {
  initialize: () => window.pendo.initialize(),

  identify: () => {
    try {
      const profile = store.getters['account/currentUserProfile']

      if (profile.accountID) {
        window.pendo.identify({
          visitor: {
            id: profile.accountID
          },
          account: {
            id: profile.admin.accountID
          }
        })

        StorageService.setPendoInfo({ identified: true })
      }
    } catch {}
  },

  updateProfile: () => {
    try {
      const pendoInfo = StorageService.getPendoInfo()

      if (pendoInfo && pendoInfo.identified) {
        const profile = store.getters['account/currentUserProfile']
        const assessment = store.getters['account/assessment']
        const learnerStyle = profile.style
        const wpOption = find(assessment.reportOptions, option => option.reportID === REPORT_IDS.WP)
        const eqOption = find(assessment.reportOptions, option => option.reportID === REPORT_IDS.EQ)
        const mgmtOption = find(assessment.reportOptions, option => option.reportID === REPORT_IDS.MGMT)

        window.pendo.updateOptions({
          visitor: {
            style: learnerStyle ? learnerStyle.style : null,
            styleVector: learnerStyle ? learnerStyle.vector : null,
            assessmentStarted: store.getters['account/assessmentsStarted'],
            assessmentComplete: store.getters['account/assessmentsComplete'],
            adminID: profile.admin.accountID,
            distributorID: profile.admin.distributorID,
            genesis: !!assessment && !!assessment.fromProduct ? 'Upgrade' : 'New',
            EQ: eqOption ? eqOption.assignDT : null,
            workplace: wpOption ? wpOption.assignDT : null,
            management: mgmtOption ? mgmtOption.assignDT : null
          }
        })

        StorageService.setPendoInfo({ profileAdded: true })
      }
    } catch { }
  },

  updateOrganization: () => {
    try {
      const pendoInfo = StorageService.getPendoInfo()

      if (pendoInfo && pendoInfo.identified) {
        const profile = store.getters['account/currentUserProfile']
        const org = store.state.organization

        window.pendo.updateOptions({
          visitor: {
            organization: store.getters['organization/organizationName'],
            department: get(org.departments.find(dept => dept.departmentId === profile.departmentID), 'departmentName', null),
            location: get(org.locations.find(location => location.locationId === profile.locationID), 'locationName', null)
          },
          account: {
            name: store.getters['organization/organizationName']
          }
        })

        StorageService.setPendoInfo({ orgAdded: true })
      }
    } catch { }
  },

  updateComparisons: (count) => {
    try {
      const pendoInfo = StorageService.getPendoInfo()

      if (pendoInfo && pendoInfo.identified) {
        window.pendo.updateOptions({
          visitor: {
            comparisonCount: count
          }
        })

        StorageService.setPendoInfo({ comparisonsAdded: true })
      }
    } catch { }
  },

  updateLearnerContext: (contextValue) => {
    try {
      const pendoInfo = StorageService.getPendoInfo()

      if (pendoInfo && pendoInfo.identified && contextValue) {
        const context = LEARNER_CONTEXTS[contextValue]

        window.pendo.updateOptions({
          visitor: {
            learnerContext: context
          }
        })
      }
    } catch { }
  },

  resetIdentity: () => {
    window.pendo && window.pendo.clearSession && window.pendo.clearSession()
  }
}

export default pendo
