<template>
  <div class="logo-brand-mark">
    <img
      class="logo-brand-mark__image"
      alt="Everything DiSC"
      :src="`/images/logo_${variant}.svg`"
    >
  </div>
</template>

<script>
export default {
  name: 'logo-brand-mark',
  props: {
    variant: {
      type: String,
      default: 'dark'
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/base';

.logo-brand-mark {
  width: 120px;
  height: auto;

  @include media-breakpoint-up('sm') {
    width: 140px;
  }
}
.logo-brand-mark__image {
  width: 100%;
  height: 100%;
}
</style>
