<template>
  <div class="graph--halo">
    <div class="graph--halo__content">
      <slot />
    </div>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0"
      y="0"
      viewBox="16 16 403 403"
      class="graph--halo__halo"
    >
      <g id="d-group">
        <path
          id="tick-1"
          :class="{
            'fill-d active': ['cd', 'd', 'di', 'dc', 'all'].includes(variant.toLowerCase()),
            'opacity-55': ['d', 'di'].includes(variant.toLowerCase())
          }"
          d="M64.6,188.4l-44.74-8.52c-1.55,8.11-2.6,16.42-3.13,24.68l45.46,2.92C62.6,201.09,63.41,194.67,64.6,188.4z"
        />
        <path
          id="tick-2"
          :class="{
            'fill-d active': ['cd', 'd', 'di', 'dc', 'all'].includes(variant.toLowerCase()),
            'opacity-77': ['d', 'di', 'cd'].includes(variant.toLowerCase())
          }"
          d="M77.39,149.82L36.4,129.94c-3.61,7.45-6.79,15.19-9.44,23l43.13,14.65C72.14,161.56,74.6,155.57,77.39,149.82z"
        />
        <path
          id="tick-3"
          :class="{
            'fill-d active': ['cd', 'd', 'di', 'dc', 'all'].includes(variant.toLowerCase()),
            'opacity-55': variant.toLowerCase() === 'cd'
          }"
          d="M99.77,115.8L65.28,86.05c-5.4,6.26-10.46,12.91-15.05,19.77l37.87,25.31 C91.66,125.81,95.58,120.65,99.77,115.8z"
        />
        <path
          id="tick-4"
          :class="{
            'fill-d active': ['d', 'di', 'dc', 'id', 'all'].includes(variant.toLowerCase()),
            'opacity-55': variant.toLowerCase() === 'id'
          }"
          d="M130.15,88.7l-25.63-37.65c-6.83,4.65-13.45,9.78-19.66,15.25l30.08,34.2C119.74,96.27,124.86,92.3,130.15,88.7z"
        />

        <path
          id="tick-5"
          :class="{
            'fill-d active': ['d', 'di', 'dc', 'id', 'all'].includes(variant.toLowerCase()),
            'opacity-77': ['d', 'dc', 'id'].includes(variant.toLowerCase())
            }"
          d="M166.49,70.4l-14.95-43.02C143.73,30.1,136,33.33,128.57,37l20.17,40.84C154.48,75,160.45,72.5,166.49,70.4z"
        />
        <path
          id="tick-6"
          :class="{
            'fill-d active': ['d', 'di', 'dc', 'id', 'all'].includes(variant.toLowerCase()),
            'opacity-55': ['d', 'dc'].includes(variant.toLowerCase())
          }"
          d="M202.99,16.76l-0.2,0.01c-8.19,0.61-16.4,1.73-24.39,3.31l8.86,44.68c6.2-1.23,12.57-2.09,18.93-2.57 L202.99,16.76z"
        />
      </g>
      <g id="i-group">
        <path
          id="tick-7"
          :class="{
            'fill-i active' :['di','i', 'id', 'is', 'all'].includes(variant.toLowerCase()),
            'opacity-55': ['i', 'is'].includes(variant.toLowerCase())
          }"
          d="M255.5,19.73c-8.12-1.54-16.43-2.59-24.69-3.12l-2.89,45.46c6.39,0.41,12.81,1.22,19.08,2.41L255.5,19.73z"
        />
        <path
          id="tick-8"
          :class="{
            'fill-i active' :['di','i', 'id', 'is', 'all'].includes(variant.toLowerCase()),
            'opacity-77': ['di', 'i', 'is'].includes(variant.toLowerCase())
          }"
          d="M305.44,36.25c-7.45-3.61-15.19-6.78-23.01-9.43l-14.62,43.14c6.04,2.05,12.03,4.5,17.79,7.29L305.44,36.25z"
        />
        <path
          id="tick-9"
          :class="{
            'fill-i active' :['di','i', 'id', 'is', 'all'].includes(variant.toLowerCase()),
            'opacity-55': variant.toLowerCase() === 'di'
          }"
          d="M349.36,65.1c-6.26-5.4-12.92-10.46-19.79-15.05l-25.29,37.88c5.32,3.55,10.48,7.47,15.33,11.66L349.36,65.1z"
        />
        <path
          id="tick-10"
          :class="{
            'fill-i active': ['si','i', 'id', 'is', 'all'].includes(variant.toLowerCase()),
            'opacity-55': variant.toLowerCase() === 'si'
          }"
          d="M384.37,104.31c-4.65-6.82-9.78-13.43-15.25-19.65l-34.19,30.09c4.24,4.81,8.21,9.93,11.81,15.22L384.37,104.31z"
        />
        <path
          id="tick-11"
          :class="{
            'fill-i active': ['si','i', 'id', 'is', 'all'].includes(variant.toLowerCase()),
            'opacity-77': ['di', 'i', 'id', 'si'].includes(variant.toLowerCase())
          }"
          d="M408.07,151.32c-2.73-7.82-5.97-15.55-9.64-22.96l-40.82,20.2c2.83,5.73,5.34,11.7,7.44,17.74L408.07,151.32z"
        />
        <path
          id="tick-12"
          :class="{
            'fill-i active': ['si','i', 'id', 'is', 'all'].includes(variant.toLowerCase()),
            'opacity-55': ['di', 'i', 'id'].includes(variant.toLowerCase())
          }"
          d="M418.71,202.78l-0.01-0.1c-0.61-8.21-1.73-16.46-3.33-24.5l-44.68,8.88c1.24,6.24,2.11,12.64,2.59,19.02 L418.71,202.78z"
        />
      </g>
      <g id="s-group">
        <path
          id="tick-13"
          :class="{
            'fill-s active': ['is','s','sc','si', 'all'].includes(variant.toLowerCase()),
            'opacity-55': ['s', 'sc'].includes(variant.toLowerCase())
          }"
          d="M418.87,230.59l-45.46-2.87c-0.4,6.4-1.21,12.82-2.4,19.09l44.75,8.47 C417.31,247.17,418.35,238.86,418.87,230.59z"
        />
        <path
          id="tick-14"
          :class="{
            'fill-s active': ['is','s','sc','si', 'all'].includes(variant.toLowerCase()),
            'opacity-77': ['is', 's', 'sc'].includes(variant.toLowerCase())
          }"
          d="M408.7,282.22l-43.14-14.62c-2.05,6.06-4.5,12.05-7.29,17.8l41.01,19.82 C402.88,297.79,406.05,290.05,408.7,282.22z"
        />
        <path
          id="tick-15"
          :class="{
            'fill-s active': ['is','s','sc','si', 'all'].includes(variant.toLowerCase()),
            'opacity-55': ['is'].includes(variant.toLowerCase())
          }"
          d="M385.48,329.38l-37.89-25.28c-3.56,5.34-7.48,10.5-11.66,15.35l34.51,29.72 C375.84,342.91,380.89,336.25,385.48,329.38z"
        />
        <path
          id="tick-16"
          :class="{
            'fill-s active': ['cs','s','sc','si', 'all'].includes(variant.toLowerCase()),
            'opacity-55': ['cs'].includes(variant.toLowerCase())
          }"
          d="M350.9,368.93l-30.12-34.17c-4.8,4.23-9.91,8.21-15.2,11.82l25.68,37.62 C338.09,379.54,344.7,374.4,350.9,368.93z"
        />
        <path
          id="tick-17"
          :class="{
            'fill-s active': ['cs','s','sc','si', 'all'].includes(variant.toLowerCase()),
            'opacity-77': ['si', 's', 'cs'].includes(variant.toLowerCase())
          }"
          d="M307.23,398.27L287,357.46c-5.73,2.84-11.7,5.35-17.74,7.45l15,43.01C292.08,405.2,299.81,401.95,307.23,398.27z"
        />
        <path
          id="tick-18"
          :class="{
            'fill-s active': ['cs','s','sc','si', 'all'].includes(variant.toLowerCase()),
            'opacity-55': ['si', 's'].includes(variant.toLowerCase())
          }"
          d="M257.41,415.24l-8.91-44.67c-6.25,1.25-12.68,2.12-19.1,2.6l-0.4,0.03l3.39,45.42l0.23-0.02 C241.06,417.98,249.34,416.85,257.41,415.24z"
        />
      </g>
      <g id="c-group">
        <path
          id="tick-19"
          :class="{
            'fill-c active': ['sc','cs', 'cd', 'c', 'all'].includes(variant.toLowerCase()),
            'opacity-55': ['c', 'cd'].includes(variant.toLowerCase())
          }"
          d="M207.5,373.29c-6.38-0.41-12.8-1.23-19.08-2.43l-8.55,44.74c8.12,1.55,16.42,2.61,24.69,3.14L207.5,373.29z"
        />
        <path
          id="tick-20"
          :class="{
            'fill-c active': ['sc','cs', 'cd', 'c', 'all'].includes(variant.toLowerCase()),
            'opacity-77': ['sc', 'c', 'cd'].includes(variant.toLowerCase())
          }"
          d="M167.62,365.36c-6.04-2.05-12.02-4.51-17.78-7.31l-19.9,40.97c7.45,3.61,15.19,6.8,23,9.45L167.62,365.36z"
        />
        <path
          id="tick-21"
          :class="{
            'fill-c active': ['sc','cs', 'cd', 'c', 'all'].includes(variant.toLowerCase()),
            'opacity-55': variant.toLowerCase() === 'sc'
          }"
          d="M131.17,347.34c-5.32-3.56-10.47-7.49-15.32-11.67l-29.79,34.46c6.26,5.41,12.91,10.48,19.77,15.07 L131.17,347.34z"
        />
        <path
          id="tick-22"
          :class="{
            'fill-c active': ['dc', 'cs', 'cd', 'c', 'all'].includes(variant.toLowerCase()),
            'opacity-55': ['dc'].includes(variant.toLowerCase())
          }"
          d="M100.55,320.49c-4.23-4.83-8.2-9.95-11.79-15.23l-37.66,25.61c4.64,6.82,9.76,13.44,15.23,19.66L100.55,320.49z"
        />
        <path
          id="tick-23"
          :class="{
            'fill-c active': ['dc', 'cs', 'cd', 'c', 'all'].includes(variant.toLowerCase()),
            'opacity-77': ['dc', 'cs', 'c'].includes(variant.toLowerCase())
          }"
          d="M77.9,286.66c-2.83-5.73-5.33-11.7-7.42-17.75l-43.03,14.93c2.72,7.83,5.95,15.56,9.61,22.97L77.9,286.66z"
        />
        <path
          id="tick-24"
          :class="{
            'fill-c active': ['dc', 'cs', 'cd', 'c', 'all'].includes(variant.toLowerCase()),
            'opacity-55': ['cs', 'c'].includes(variant.toLowerCase())
          }"
          d="M64.86,248.14c-1.22-6.18-2.08-12.53-2.55-18.85l-0.02-0.3l-45.42,3.39l0.02,0.3 c0.61,8.14,1.71,16.3,3.29,24.28L64.86,248.14z"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'graph-halo',
  props: {

    /**
     * Portion of halo to be highlighted
     */
    variant: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
@import "../../styles/main.scss";

.graph--halo {
  fill: $gray-100;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "content";

  &__halo,
  &__content {
    grid-area: content;
  }

  &__content {
    padding: 12.5%;
  }

  .active {
    &.opacity-55 {
      fill-opacity: 0.55;
    }

    &.opacity-77 {
      fill-opacity: 0.77;
    }
  }
}
</style>
