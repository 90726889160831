import { ExtendableError } from '../../util/error'
import ApiService from '../api'

class TextError extends ExtendableError {
  constructor (errorCode, message) {
    super(message)
    this.name = 'TextError'
    this.message = message
    this.errorCode = errorCode
  }
}

const TextService = {

  /**
   * Fetch translated text for a page
   * @param { string } route
   * @param { string } language
   * @returns PageText
   * @throws TextError
   */
  async fetchPage (route, language) {
    const requestData = {
      method: 'GET',
      url: '/page' + route + '/' + language
    }

    try {
      const response = await ApiService.customRequest(requestData, '/v2')

      return response.data
    } catch (error) {
      throw new TextError(error.response.status, error.response.data.message)
    }
  },

  async process ({
    content = 'catalyst-learner-ui-static',
    context,
    language,
    extraParameters = {}
  }) {
    const requestData = {
      method: 'POST',
      baseURL: window.env.PUBLIC_DARWIN_TEXT_API,
      url: '/texts/process',
      ignoredInterceptors: [404],
      withCredentials: false,
      data: {
        content,
        context,
        // i18next plural resolver only supports langs with dashes
        // but we use underscores in our APIs, therefore
        // we have to define with dashes on the i18next configuration
        // and transform when we do the API call. :{
        language: language.replace('-', '_'),
        extraParameters
      }
    }

    try {
      const response = await ApiService.customRequest(requestData, '/v4')

      return response.data
    } catch (error) {
      throw new TextError(error.response.status, error.response.data.message)
    }
  }
}

export default TextService
export { TextError, TextService }
