import {
  DemographicsService,
  DemographicsError
} from '../../services/demographics'
import { questionFormats } from '../../components/question/QuestionConstants'
import { get } from 'lodash'
import pendo from '../../util/integrations/pendo'
import { stateMerge } from '../../util/stateMerge'

/**
 * @module Survey Module
 * @description Vuex module responsible for fetching non-assessment questions and saving responses
 */
const defaultDemographics = {
  intro: '',
  questions: []
}

const state = {
  pageIntro: true,
  page: 1,
  pending: false,
  answers: {},
  demographics: Object.assign({}, defaultDemographics),
  status: 0,
  error: '',
  panel: 0,
  saveStatus: 0
}

const getters = {
  demographicsIntro: state => state.demographics.intro,
  // Don't show the race or ethnicity question if the country isn't the USA.
  demographicsQuestions: state => get(state.answers[1], 'value', 0) === 1
    ? state.demographics.questions
    : state.demographics.questions.filter(question => question.id !== 7),
  completed: (state, getters) => state.saveStatus > 0 || state.panel > getters.demographicsQuestions.length,
  answers: state => state.answers,
  panels: (state, getters) => {
    const panels = getters.demographicsQuestions.map((question, index) => {
      const metadata = Object.assign({},
        question.metadata,
        { questionLabel: `${index + 1}. ${question.metadata.questionLabel}` })
      return Object.assign({}, question, { metadata })
    })

    // add intro panel if necessary
    if (state.pageIntro && state.demographics.intro.length) {
      panels.unshift({
        id: `intro-${state.page}`,
        metadata: { questionLabel: state.demographics.intro },
        interstitial: state.page === 1
      })
    }

    // insert a review step once complete
    panels.push({
      id: `review-${state.page}`,
      format: questionFormats.REVIEW
    })

    return panels
  }
}

const actions = {
  async fetchDemographics ({ commit, rootGetters }) {
    commit('fetchDemographicsRequest')
    try {
      const accessCode = rootGetters['account/accessCode']
      const demographics = await DemographicsService.fetchQuestions(accessCode)
      commit('fetchDemographicsSuccess', demographics)
    } catch (e) {
      commit('fetchDemographicsError', {
        errorMessage: e.message,
        errorCode: e.errorCode
      })
    }
  },

  async saveDemographics ({ commit, state, rootGetters }) {
    commit('saveDemographicsRequest')
    try {
      const answers = Object.keys(state.answers)
        .map((id) => {
          const answer = state.answers[id]
          if (answer === null || answer.value === null) {
            throw new DemographicsError(400, 'partial survey answers')
          }

          const response = {
            id: Number(id),
            values: Array.isArray(answer.value)
              ? answer.value
              : [
                  answer.value
                ]
          }

          if (answer.otherValue) {
            response.otherValue = answer.otherValue
          }

          return response
        })

      // write "why are you taking this?" question to Pendo
      if (state.answers[8] && state.answers[8].value) {
        pendo.updateLearnerContext(state.answers[8].value)
      }

      const params = {
        accessCode: rootGetters['account/accessCode'],
        answers
      }

      await DemographicsService.saveResponses(params)
      commit('saveDemographicsSuccess')
    } catch (e) {
      commit('saveDemographicsError', {
        errorMessage: e.message,
        errorCode: e.errorCode
      })
    }
  }
}

const mutations = {
  fetchDemographicsRequest (state) {
    state.pending = true
    state.status = 0
    state.error = ''
    stateMerge(state.demographics, defaultDemographics)
    state.answers = {}
  },
  fetchDemographicsSuccess (state, demographics) {
    state.pending = false
    state.status = 200
    state.error = ''
    state.demographics = demographics
  },
  fetchDemographicsError (state, { errorMessage, errorCode }) {
    state.pending = false
    state.status = errorCode
    state.error = errorMessage
    stateMerge(state.demographics, defaultDemographics)
    state.answers = {}
  },
  saveDemographicsRequest (state) {
    state.pending = true
    state.saveStatus = 0
    state.error = ''
  },
  saveDemographicsSuccess (state) {
    state.pending = false
    state.saveStatus = 200
    state.error = ''
  },
  saveDemographicsError (state, { errorMessage, errorCode }) {
    state.pending = false
    state.saveStatus = errorCode
    state.error = errorMessage
  },
  answers (state, answers) {
    stateMerge(state.answers, answers)
  },
  setPanel (state, panel) {
    state.panel = panel
  },
  skipDemographics (state) {
    state.pending = false
    state.saveStatus = 200
    state.error = ''
  }
}

export const survey = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
