<template>
  <div :class="`hero hero--tagline hero--${variant}`">
    <div class="hero__graphic">
      <img
        :src="`/images/${variant}-top-dt.svg`"
        class="d-none d-lg-block hero__tagline--top"
        alt=""
      />
      <img
        :src="`/images/${variant}-top-tablet.svg`"
        class="d-none d-md-block d-lg-none hero__tagline--top"
        alt=""
      />
      <img
        :src="`/images/${variant}-top-mobile.svg`"
        class="d-block d-md-none hero__tagline--top"
        alt=""
      />
      <h2
        class="h2 underline hero__tagline mt-lg-none"
        v-html="$tt('brand.tagline')"
      />
      <img
        :src="`/images/${variant}-bottom-mobile.svg`"
        class="d-block d-md-none hero__tagline--bottom"
        alt=""
      />
      <img
        :src="`/images/${variant}-bottom-tablet.svg`"
        class="d-none d-md-block d-lg-none hero__tagline--bottom"
        alt=""
      />
      <img
        :src="`/images/${variant}-bottom-dt.svg`"
        class="d-none d-lg-block hero__tagline--bottom"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'hero-tagline',
  props: {
    variant: {
      type: String,
      validator: prop => ['login', 'header'].includes(prop),
      default: 'login'
    },
    lgTopImg: {
      type: String,
      default: '/images/login-top-dt.svg'
    },
    lgBottomImg: {
      type: String,
      default: '/images/login-bottom-dt.svg'
    },
    mdTopImg: {
      type: String,
      default: '/images/login-top-tablet.svg'
    },
    mdBottomImg: {
      type: String,
      default: '/images/login-bottom-tablet.svg'
    },
    smTopImg: {
      type: String,
      default: '/images/login-top-mobile.svg'
    },
    smBottomImg: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
@import "../../styles/base";

.hero.hero--tagline {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
  align-items: center;

  @include media-breakpoint-up("lg") {
    padding-top: 0;

    .logo-tagline {
      position: relative;
      z-index: 2;
    }
  }

  .hero__graphic {
    display: grid;

    .hero__tagline {
      margin-top: 0;
      margin-bottom: 0;
      grid-area: 2 / 3 / 4 / 6;
      align-self: start;
      font-size: $font-size-lg;
      line-height: 2rem;

      @include media-breakpoint-up("md") {
        font-size: rem-calc(32px);
        line-height: rem-calc(42px);
      }

      @include media-breakpoint-up("lg") {
        line-height: rem-calc(50px);
        font-size: $font-size-xxl;
        align-self: end;
      }

      &--top {
        grid-area: 1 / 6 / 2 / 7;

        @include media-breakpoint-up("md") {
          grid-area: 1 / 5 / 2 / 7;
        }
      }

      &--bottom {
        grid-area: 3 / 2 / 5 / 4;

        @include media-breakpoint-up("lg") {
          grid-area: 3 / 2 / 5 / 3;
        }
      }

      &--top,
      &--bottom {
        align-self: end;
      }
    }
  }
}

.hero--login .hero__graphic {
  // Rows:
  // min-content for top of artwork
  // 30px for the overlap of the right artwork with tagline
  // 45px for overlap of left artwork with tagline
  // 60px for padding since SVG is shorter than needed
  grid-template-rows: min-content 30px 45px 60px;

  // Columns:
  // min-content to collapse column
  // minmax(15%, 100px) to constrain size of svg while clearing tagline
  // 15px to overlap tagline
  // 2 90px columns to give structure to tagline while preserving number of columns
  // minmax(15%, 80px) to constrain size of svg while clearing tagline
  grid-template-columns:
    min-content minmax(15%, 100px) 15px repeat(2, 90px)
    minmax(15%, 80px);

  @include media-breakpoint-up("md") {
    // Rows:
    // min-content for top of artwork
    // 110px for the overlap of the right artwork with tagline
    // 40px for overlap of left artwork with tagline
    // min-content to fit svg height
    grid-template-rows: min-content 110px 40px min-content;

    // Columns:
    // min-content to collapse column
    // minmax(17.5%, 175px) to constrain size of svg while clearing tagline
    // 15px to overlap tagline
    // 2 110px columns to give structure to tagline while preserving number of columns
    // minmax(17.5%, 175px) to constrain size of svg while clearing tagline
    grid-template-columns:
      min-content minmax(17.5%, 175px)
      15px repeat(2, 110px) minmax(17.5%, 170px);
  }

  @include media-breakpoint-up("lg") {
    // Rows:
    // min-content for top of artwork
    // 100px for the overlap of the right artwork with tagline
    // 20px for overlap of left artwork with tagline
    // min-content to fit svg height
    grid-template-rows: min-content 100px 20px min-content;
    // Columns:
    // 45px for padding to align with brand mark
    // minmax(17.5%, 175px) to constrain size of svg while clearing tagline
    // 15px to overlap tagline
    // 2 146px columns to give structure to tagline while preserving number of columns
    // minmax(20%, 250px) to constrain size of svg while clearing tagline
    grid-template-columns:
      45px minmax(17.5%, 175px) 15px repeat(2, 146px)
      minmax(20%, 250px);
  }

  @include media-breakpoint-up("xl") {
    // Rows:
    // min-content for top of artwork
    // 90px for the overlap of the right artwork with tagline
    // 30px for overlap of left artwork with tagline
    // min-content to fit svg height
    grid-template-rows: min-content 90px 30px min-content;

    // Columns:
    // 45px for padding to align with brand mark
    // minmax(17.5%, 175px) to constrain size of svg while clearing tagline
    // 15px to overlap tagline
    // 2 146px columns to give structure to tagline while preserving number of columns
    // minmax(20%, 250px) to constrain size of svg while clearing tagline
    grid-template-columns: 45px minmax(17.5%, 175px) 15px repeat(2, 146px) minmax(
        20%,
        250px
      );
  }

  .hero__tagline {
    // safari doesn't support min-content se we need to specify max-widths for elements
    &--top {
      max-width: 65px;
      max-height: 115px;

      @include media-breakpoint-up("md") {
        max-width: 280px;
        max-height: 153px;
      }

      @include media-breakpoint-up("lg") {
        max-width: 261px;
        max-height: 183px;
      }

      @include media-breakpoint-up("xl") {
        max-width: 396px;
        max-height: 275px;
      }
    }

    &--bottom {
      max-width: 90px;
      max-height: 89px;

      @include media-breakpoint-up("md") {
        max-width: 190px;
        max-height: 174px;
      }

      @include media-breakpoint-up("lg") {
        max-width: 100px;
        max-height: 163px;
      }

      @include media-breakpoint-up("xl") {
        max-height: 281px;
        max-width: 175px;
      }
    }
  }
}

.hero--header .hero__graphic {
  grid-template-rows: min-content 110px 40px min-content;
  grid-template-columns:
    min-content minmax(10%, 100px)
    50px repeat(2, 110px) minmax(10%, 30px);

  @include media-breakpoint-up("lg") {
    grid-template-rows: min-content 90px 20px min-content;
    grid-template-columns:
      min-content minmax(25%, 130px) min-content 217px 90px
      minmax(25%, 150px);
  }

  .hero__tagline {
    // safari doesn't support min-content se we need to specify max-widths for elements
    &--top {
      max-width: 150px;
      max-height: 150px;

      @include media-breakpoint-up("lg") {
        max-width: 306px;
        max-height: 100px;
      }
    }

    &--bottom {
      max-width: 150px;
      max-height: 450px;

      @include media-breakpoint-up("lg") {
        max-width: 122px;
        max-height: 225px;
      }
    }
  }
}
</style>
