import LogRocket from 'logrocket'
import StorageService from '../../services/storage'
import store from '../../store'

const logRocket = {
  initialize: () => LogRocket.init(window.env.PUBLIC_LOGROCKET),

  identify: () => {
    try {
      if (store.getters['account/accountID']) {
        LogRocket.identify(store.getters['account/accountID'].toString())

        StorageService.setLogRocketInfo({ identified: true })
      }
    } catch {}
  },

  resetIdentity: () => {
    logRocket && LogRocket.identify()
  }
}

export default logRocket
