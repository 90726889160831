<template>
  <div class="maintPopup">
    <transition name="fade">
      <button v-if="collapsed" id="maintenance_expand_button" class="maintPopup__expand" @click="collapsed = false" :aria-label="$tt('maintenance.toast.expandButtonLabel')">
        <img class="maintPopup__expandIcon" src="/images/maintenance/MaintenanceTruck_greyoutline.svg" :alt="$tt('maintenance.toast.imageAlt')" />
      </button>
      <div v-else class="maintPopup__expanded" aria-live="polite" role="status">
        <div class="maintPopup__header">
          <button id="maintenance_collapse_button" class="maintPopup__collapse" @click="collapsed = true" :aria-label="$tt('maintenance.toast.collapseButtonLabel')">
            <x-icon class="maintPopup__collapseIcon" variant="white" />
          </button>
        </div>
        <div id="maintenance_body" class="maintPopup__body">
          <div class="maintPopup__text">
            <h3 class="maintPopup__title" tabindex="0">{{ $tt('maintenance.toast.heading') }}</h3>
            <p class="maintPopup__paragraph" tabindex="0">{{ $tt('maintenance.toast.body') }}</p>
          </div>
          <div class="maintPopup__imageContainer">
            <img class="maintPopup__image" src="/images/maintenance/MaintenanceTruck.svg" :alt="$tt('maintenance.toast.imageAlt')" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import XIcon from '../icons/XIcon'

export default {
  name: 'maintenance-popup',
  i18nOptions: { namespaces: 'common' },
  components: { XIcon },
  data: () => ({
    collapsed: false
  })
}
</script>

<style lang="scss" scoped>
  @import "../../styles/base";

  .maintPopup {
    &__expand,
    &__collapse {
      border: none;
      background: none;
    }

    &__expand {
      border-radius: 50%;
      position: absolute;
      bottom: 0;
    }

    &__expandIcon {
      width: 3.5rem;
      height: 3.5rem;
    }

    &__expanded {
      background-color: $dark;
      padding: 0.75rem;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      min-width: 0;
      position: absolute;
      bottom: 0;

      @include media-breakpoint-up('md') {
        padding: 1.5rem;
      }
    }

    &__collapse {
      line-height: 0.5rem;
      position: absolute;
      right: 0;
      top: 0;
      padding: 0.75rem;
      padding-bottom: 1.5rem;
    }

    &__collapseIcon {
      width: 0.75rem;
      height: 0.75rem;
    }

    &__body {
      display: flex;
      overflow-y: auto;
    }

    &__text {
      min-width: 0;
      overflow-wrap: break-word;
    }

    &__title {
      margin: 0;
      margin-bottom: 0.75rem;
      color: $white;
    }

    &__paragraph {
      font-size: $font-size-sm;
      color: $white;
      margin: 0;
    }

    &__imageContainer {
      flex: 0 0 4.25rem;
      padding: 0 1.5rem 0 0.5rem;

      @include media-breakpoint-up('md') {
        flex: 0 0 5.5rem;
        padding: 0 0.75rem;
      }

      @media (max-width: 275px) {
        display: none;
      }
    }
  }
</style>
