<template>
  <div class="nav-modules">
    <div
      v-for="({route, order}) in pathway.pages"
      :key="order"
      class="nav-modules__entry"
    >
      <img
        class="nav-modules__icon"
        :src="`/images/Home/${route}.svg`"
        alt=""
      />
      <router-link
        class="nav-modules__link rollover"
        :id="`nav-modules__link--${route}`"
        @click.native="$emit('closeNavMenu')"
        :to="`${pathway.value}/${route}`"
        v-html="noWrapLast($tt(`appHeader.${pathway.label}.${route}.label`), 'caret')"
      />
      <p
        class="nav-modules__description"
        v-html="$tt(`appHeader.${pathway.label}.${route}.description`)"
      />
    </div>
  </div>
</template>

<script>
import { noWrapLast, noSpaces } from '../../util/string'

export default {
  name: 'nav-modules',
  props: {
    pathway: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    noWrapLast,
    noSpaces
  }
}
</script>

<style lang="scss">
@import "../../styles/base";

.nav-modules {
  &__entry {
    display: grid;
    grid-template-columns: fit-content(100%) 1fr;
    grid-template-rows: fit-content(100%) 1fr;
    grid-template-areas:  'icon link'
                          'description description';

    > * {
      justify-self: flex-start;
      align-self: center;
    }

    &:not(:first-of-type) {
      margin-top: 1rem;
    }

    &:not(:last-of-type) {
      padding-bottom: 1rem;
      border-bottom: .125rem solid $gray-100;
    }
  }

  &__icon {
    grid-area: icon;
    width: 3rem;
    height: 3rem;

    margin-right: 1rem;

    @include media-breakpoint-down('sm') {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  &__link {
    grid-area: link;
    font-size: 1.375rem;

    &.rollover:not(.disabled) .caret {
      &::after {
        border-width: 3px;
        height: 10px;
        width: 10px;
      }
    }

    @include media-breakpoint-down('sm') {
      font-size: 1rem;
    }
  }

  &__description {
    grid-area: description;
    margin-top: 1rem;
    margin-bottom: 0;
  }
}

</style>
