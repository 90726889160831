<template>
  <div class="skip-link-wrapper">
    <a
      href="#app-main"
      class="skip-link"
      @click="skip"
      ref="link"
      tabindex="0"
    >
      {{ $t('skipLink.text') }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'skip-link',
  methods: {
    skip (e) {
      e.preventDefault()
      this.$refs.link.blur()
      document.getElementById('app-main').focus()
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/base';

.skip-link-wrapper {
  background-color: $white;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $zindex-fixed;
  position: fixed;
  height: 0;
  transition: ease 0.5s;

  .skip-link {
    display: inline-block;
    margin: 0.5rem;
    padding: 8px;
    position: relative;
    transition: ease 0.5s;
    color: transparent;
    top: -3.6rem;

    &:focus-visible {
      @extend %accessible-outline;
      transition: ease 0.5s;
      color: $blue-links-accessible;
      text-decoration: underline;
      top: 0;
    }
  }

  &:has(.skip-link:focus-visible) {
    transition: ease 0.5s;
    height: 3.6rem;
  }
}

body {
  #app-main,
  .osano-cm-window__dialog {
    transition: 0.5s $easeInOutSine;
  }

  &:has(.skip-link:focus-visible) {
    #app-header,
    .osano-cm-window__dialog {
      top: 3.6rem;
    }

    #app-main {
      margin-top: 3.6rem;
    }
  }
}
</style>
