import axios from 'axios'
import errorHandlers from './errorHandlers'
import requestRefreshInterceptor from './refreshInterceptor'

export const http = axios.create()
export const CancelToken = axios.CancelToken
export const isCancel = axios.isCancel
export const DEFAULT_API_VERSION = '/v1'
export const DEFAULT_ROUTE = '/api'

const ApiService = {
  _requestInterceptor: null,
  _responseInterceptor: null,

  init (baseURL) {
    http.defaults.baseURL = baseURL
    http.defaults.withCredentials = true
  },

  setAuthHeader (header) {
    http.defaults.headers.common.Authorization = `Bearer ${header}`
    http.defaults.headers.common['Cache-Control'] = 'no-cache'
  },

  removeAuthHeader () {
    http.defaults.headers.common = {}
  },

  get (resource, config) {
    return http.get(resource, config)
  },

  post (resource, data, config) {
    return http.post(resource, data, config)
  },

  put (resource, data, config) {
    return http.put(resource, data, config)
  },

  delete (resource, config) {
    return http.delete(resource, config)
  },

  /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
    **/
  customRequest (axiosConfig, version = DEFAULT_API_VERSION, routePrefix = DEFAULT_ROUTE) {
    axiosConfig.url = routePrefix + version + axiosConfig.url
    return http(axiosConfig)
  },

  mountRequestInterceptor () {
    this._requestInterceptor = http.interceptors.request.use(
      (request) => requestRefreshInterceptor(request),
      (error) => Promise.reject(error)
    )
  },

  mountResponseInterceptor () {
    this._responseInterceptor = http.interceptors.response.use((response) => response,
      (error) => {
        if (error.config.ignoredInterceptors && error.config.ignoredInterceptors.includes(error.response.status)) {
          return Promise.reject(error)
        }

        switch (error.response.status) {
          case 503:
            errorHandlers.handle503Error()
            break
          case 404:
            errorHandlers.handle404Error()
            break
        }

        return Promise.reject(error)
      })
  },

  unmountResponseInterceptor () {
    http.interceptors.response.eject(this._responseInterceptor)
    this._responseInterceptor = null
  }
}

export default ApiService
