import { DemographicsService } from '../../services/demographics'
import pendo from '../../util/integrations/pendo'

const state = {
  pending: false,
  error: undefined,
  demographics: undefined,
  answers: {}
}

const getters = {
  demographicsQuestions: state => state.demographics?.questions || [],
  answers: state => state.answers
}

const actions = {
  async fetchDemographics ({ commit, rootGetters }) {
    commit('fetchDemographicsRequest')
    try {
      const accessCode = rootGetters['account/accessCode']
      const demographics = await DemographicsService.fetchQuestions(accessCode)
      commit('fetchDemographicsSuccess', demographics)
      return true
    } catch (e) {
      commit('fetchDemographicsError', {
        errorMessage: e.message,
        errorCode: e.errorCode
      })
      return false
    }
  },
  async saveDemographics ({ commit, state, rootGetters }) {
    commit('saveDemographicsRequest')
    try {
      // write "why are you taking this?" question to Pendo
      const contextAnswer = state.answers[8]
      if (contextAnswer?.values?.[0]) {
        pendo.updateLearnerContext(contextAnswer.values[0])
      }

      const accessCode = rootGetters['account/accessCode']
      const answers = Object.keys(state.answers).map(questionId => ({
        ...state.answers[questionId],
        id: questionId
      }))

      await DemographicsService.saveResponses({ accessCode, answers })
      commit('saveDemographicsSuccess')
      return true
    } catch (e) {
      commit('saveDemographicsError', {
        errorMessage: e.message,
        errorCode: e.errorCode
      })
      return false
    }
  }
}

const mutations = {
  fetchDemographicsRequest (state) {
    state.pending = true
    state.error = undefined
    state.demographics = undefined
    state.answers = {}
  },
  fetchDemographicsSuccess (state, demographics) {
    state.pending = false
    state.demographics = demographics
  },
  fetchDemographicsError (state, { errorMessage, errorCode }) {
    state.pending = false
    state.error = { errorMessage, errorCode }
  },
  saveDemographicsRequest (state) {
    state.pending = true
    state.error = undefined
  },
  saveDemographicsSuccess (state) {
    state.pending = false
    state.answers = {}
  },
  saveDemographicsError (state, { errorMessage, errorCode }) {
    state.pending = false
    state.error = { errorMessage, errorCode }
  },
  answerQuestion (state, { questionId, answers }) {
    state.answers = {
      ...state.answers,
      [questionId]: { values: answers }
    }
  },
  setAlternativeAnswer (state, { questionId, answer }) {
    state.answers[questionId].otherValue = answer
  },
  setAnswers (state, answers) {
    state.answers = { ...answers }
  }
}

export const demographics = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
