import { get } from 'lodash'
import { ExtendableError } from '../../util/error'
import ApiService from '../api'

class GroupsError extends ExtendableError {
  constructor (errorCode, message) {
    super(message)
    this.name = 'GroupsError'
    this.message = message
    this.errorCode = errorCode
  }
}

const GroupsService = {
  async getGroups ({ memberID, type = 1, visibility = 'Owner', include = [] }) {
    try {
      let url = `/groups/search?memberID=${memberID}&context=Catalyst&memberType=Member&type=${type}&visibility=${visibility}&size=100`

      // include = members or details
      if (include.length > 0) {
        url += `&include=${include.join()}`
      }

      const response = await ApiService.customRequest({
        method: 'GET',
        url,
        ignoredInterceptors: [404]
      })

      return get(response.data, 'content')
    } catch (error) {
      throw new GroupsError(error.response.status, error.response.data.message)
    }
  },

  async getGroup (groupId) {
    try {
      const response = await ApiService.customRequest({
        method: 'GET',
        url: `/groups/${groupId}?include=members`,
        ignoredInterceptors: [404]
      })
      return response.data
    } catch (error) {
      throw new GroupsError(error.response.status, error.response.data.message)
    }
  },

  async createGroup (groupRequest) {
    try {
      const response = await ApiService.customRequest({
        method: 'POST',
        url: '/groups',
        data: groupRequest
      })
      return response.data
    } catch (error) {
      throw new GroupsError(error.response.status, error.response.data.message)
    }
  },

  async updateGroup (groupId, groupRequest) {
    try {
      const response = await ApiService.customRequest({
        method: 'PUT',
        url: `/groups/${groupId}`,
        data: groupRequest
      })
      return response.data
    } catch (error) {
      throw new GroupsError(error.response.status, error.response.data.message)
    }
  },

  async updatePersonalizedFlags ({ groupID, groupMemberID, personalizedFlagsRequest }) {
    try {
      const requestData = {
        method: 'PUT',
        url: `/groups/${groupID}/members/${groupMemberID}/personalized-flags`,
        data: personalizedFlagsRequest
      }
      const response = await ApiService.customRequest(requestData, '/v2')
      return response.data
    } catch (error) {
      throw new GroupsError(error.response.status, error.response.data.message)
    }
  },

  async deleteGroup (groupId) {
    try {
      await ApiService.customRequest({
        method: 'DELETE',
        url: `/groups/${groupId}`
      })
    } catch (error) {
      throw new GroupsError(error.response.status, error.response.data.message)
    }
  },

  async leaveGroup (groupID, groupMemberID) {
    try {
      await ApiService.customRequest({
        method: 'DELETE',
        url: `/groups/${groupID}/members`,
        data: { groupMemberIds: [groupMemberID] }
      }, '/v2')
    } catch (error) {
      throw new GroupsError(error.response.status, error.response.data.message)
    }
  }
}

export default GroupsService
export { GroupsService, GroupsError }
