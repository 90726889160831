import HealthService from '../../services/health'
import { stateMerge } from '../../util/stateMerge'

export const defaultAlert = {
  message: null,
  level: null,
  actions: [],
  showSeconds: 0
}

export const state = {
  prefetchError: '',
  prefetchStatusCode: 0,
  prefetching: 0,
  alert: {
    ...defaultAlert
  },
  maintenanceMode: false,
  maintenanceFlagError: null,
  maintenanceFlagStatusCode: 0,
  healthError: null,
  healthStatusCode: 0,
  healthPending: false
}

export const getters = {
  isPrefetching: state => Boolean(state.prefetching)
}

export const actions = {
  async refreshMaintenanceMode ({ commit }) {
    try {
      commit('maintenanceFlagRequest')
      try {
        const maintenanceFlag = await HealthService.fetchMaintenanceFlag()

        if (maintenanceFlag) {
          commit('setMaintenanceMode', maintenanceFlag)
          commit('maintenanceFlagSuccess')
          return
        }

        commit('maintenanceFlagSuccess')
      } catch (e) {
        commit('maintenanceFlagError', {
          errorMessage: e.message,
          errorCode: e.errorCode
        })
      }

      commit('healthRequest')
      await HealthService.checkRootbeerDependencies()

      commit('setMaintenanceMode', false)
      commit('healthSuccess')
    } catch (e) {
      commit('setMaintenanceMode', true)
      commit('healthError', {
        errorMessage: e.message,
        errorCode: e.errorCode
      })
    }
  }
}

export const mutations = {
  alert (state, alert) {
    stateMerge(state.alert, alert)
  },
  clearAlert (state) {
    stateMerge(state.alert, defaultAlert)
  },
  prefetchStart (state) {
    state.prefetching++
  },
  prefetchEnd (state) {
    state.prefetching--
    if (state.prefetching < 0) {
      state.prefetching = 0
    }
  },
  prefetchError (state, error) {
    state.prefetching--
    if (state.prefetching < 0) {
      state.prefetching = 0
    }
    state.prefetchError = error.message
    state.prefetchStatusCode = 0
  },
  setMaintenanceMode (state, maintenanceFlag) {
    state.maintenanceMode = maintenanceFlag
  },
  healthRequest (state) {
    state.healthError = null
    state.healthStatusCode = 0
    state.healthPending = true
  },
  healthSuccess (state) {
    state.healthPending = false
  },
  healthError (state, { errorMessage, errorCode }) {
    state.healthError = errorMessage
    state.healthStatusCode = errorCode
    state.healthPending = false
  },
  maintenanceFlagRequest (state) {
    state.maintenanceFlagError = null
    state.maintenanceFlagStatusCode = 0
    state.healthPending = true
  },
  maintenanceFlagSuccess (state) {
    state.healthPending = false
  },
  maintenanceFlagError (state, { errorMessage, errorCode }) {
    state.maintenanceFlagError = errorMessage
    state.maintenanceFlagStatusCode = errorCode
    state.healthPending = false
  }
}

export const app = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
