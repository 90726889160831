import { setupWorker } from 'msw/browser'
import { handlers } from './handlers'

const ApiMocksService = {
  _baseURL: undefined,
  init (baseURL) {
    this._baseURL = baseURL
  },
  setupWorker () {
    return setupWorker(...handlers(this._baseURL))
  }
}

export default ApiMocksService
