<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 458.01 458.01">
    <g id="Layer_1" data-name="Layer 1">
      <circle :class="`fill-${fillVariant}`" cx="229.01" cy="229.01" r="228.66"/>
      <path :class="`fill-${symbolVariant}`" d="M184.92,362.91q0-17.45,12.62-28.64T229,323.07q18.86,0,31.47,11.2t12.61,28.64q0,17.44-12.61,28.64T229,402.75q-18.87,0-31.47-11.2T184.92,362.91Zm76.86-73.08H197.42L187.28,55.26h84.63Z"/>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'alert-icon',
  props: {
    fillVariant: {
      type: String,
      default: 'brand'
    },
    symbolVariant: {
      type: String,
      default: 'white'
    }
  }
}
</script>
