import { questionFormats } from '../../components/question/QuestionConstants'
import AssessmentService from '../../services/assessment'
import { sleep } from '../../util/async'
import { getValue } from '../../util/object'
import { stateMerge } from '../../util/stateMerge'

export function questionFormat (format) {
  if (format.endsWith('likert')) return 'likert'
  if (format.endsWith('continua')) return 'continua'
  return format
}

export function reducePageAnswers (page) {
  return page.questionBlocks[0].questions.reduce((answers, question) => {
    if (question.answer) answers[question.id] = question.answer
    return answers
  }, {})
}

async function pingReport (reportUrl) {
  let pingCount = 0
  let pingError = null
  do {
    await sleep(200)
    try {
      await AssessmentService.reportUpload(reportUrl)
      return true
    } catch (error) {
      pingCount++
      pingError = error
    }
  } while (pingCount < 25)

  throw pingError
}

const state = {
  accessCode: null,
  pageIntro: true,
  pending: false,
  paging: false,
  answering: false,
  assessmentError: '',
  assessmentErrorCode: 0,
  panel: 0,
  learnerID: null,
  style: null,
  extraPriority: null,
  angle: null,
  vector: null,
  priorities: [],
  continua: [],
  page: null,
  progress: 0,
  questionsMax: null,
  questionsAnswered: 0,
  questionBlocks: [],
  blockFormat: null,
  answers: {},
  reporting: false,
  reportUploading: false,
  reportName: null,
  reportUrl: null,
  reportError: '',
  reportErrorCode: 0
}

export const getters = {
  currentProgress: state => state.questionsMax ? state.questionsAnswered / state.questionsMax : 0,
  currentQuestions: state => state.questionBlocks,
  completed: state => state.questionsMax ? state.questionsAnswered >= state.questionsMax : false,
  answers: state => state.answers,
  panels: (state, _, __, rootGetters) => {
    let panels = []
    if (state.questionBlocks) {
      panels = state.questionBlocks.reduce((acc, block, index) => {
        const questions = acc.concat(block.questions.map((question) => {
          // normalize formats
          const format = questionFormat(question.format)
          return Object.assign({}, question, { format })
        }))

        // add intro panel if necessary
        if (state.pageIntro && block.intro.length) {
          questions.unshift({
            id: `intro-${state.page}-${index}`,
            metadata: {
              questionLabel: block.intro,
              blockFormat: questionFormat(state.blockFormat)
            },
            interstitial: state.page === 1
          })
        }
        return questions
      }, [])
    }

    // insert a review step once complete
    if (state.questionsAnswered >= state.questionsMax) {
      if (state.page === 999) {
        panels.push({
          id: `reveal-${state.page}`,
          format: questionFormats.REVEAL
        })
      }
    }

    return panels
  }
}

export const mutations = {
  reportRequest (state) {
    state.reporting = true
    state.reportUploading = false
    state.reportUrl = null
    state.reportError = ''
    state.reportErrorCode = 0
  },
  reportUpload (state) {
    state.reporting = false
    state.reportUploading = true
  },
  reportSuccess (state, report) {
    state.reportUrl = report.reportUrl
    state.reportName = report.reportName
    state.reporting = false
    state.reportUploading = false
    state.reportError = ''
    state.reportErrorCode = 0
  },
  reportError (state, error) {
    state.reportUrl = null
    state.reporting = false
    state.reportUploading = false
    state.reportError = error.errorMessage
    state.reportErrorCode = error.errorCode
  },
  reportClear (state) {
    state.reportUrl = null
    state.reporting = false
    state.reportUploading = false
    state.reportError = ''
    state.reportErrorCode = 0
  },
  detailRequest (state) {
    state.pending = true
    state.assessmentError = ''
    state.assessmentErrorCode = 0
  },
  detailSuccess (state, details) {
    state.pending = false
    state.assessmentError = ''
    state.assessmentErrorCode = 0
    stateMerge(state, details)
  },
  detailError (state, error) {
    state.pending = false
    state.assessmentError = error.errorMessage
    state.assessmentErrorCode = error.errorCode
  },
  pageRequest (state) {
    state.paging = true
    state.questionBlocks = []
    state.assessmentError = ''
    state.assessmentErrorCode = 0
  },
  pageSuccess (state, page) {
    state.paging = false
    state.assessmentError = ''
    state.assessmentErrorCode = 0
    stateMerge(state, page)
  },
  pageAnswers (state, answers) {
    stateMerge(state.answers, answers)
  },
  pageIntro (state, page) {
    let blockFormat = getValue(page, 'questionBlocks.0.questions.0.format')
    const blockType = getValue(page, 'questionBlocks.0.questions.0.type')
    if (blockType === 'EQ DiSC Specific' && blockFormat === '5_point_likert') {
      blockFormat = '5_point_eqlikert'
    }
    state.pageIntro = state.page === 1 || blockFormat !== state.blockFormat
    state.blockFormat = blockFormat
  },
  pageError (state, error) {
    state.paging = false
    state.assessmentError = error.errorMessage
    state.assessmentErrorCode = error.errorCode
  },
  saveAnswersRequest (state) {
    state.answering = true
    state.assessmentError = ''
    state.assessmentErrorCode = 0
  },
  saveAnswersSuccess (state, page) {
    state.answering = false
    state.answers = {}
    state.assessmentError = ''
    state.assessmentErrorCode = 0
    stateMerge(state, page)
  },
  saveAnswersError (state, error) {
    state.answering = false
    state.assessmentError = error.errorMessage
    state.assessmentErrorCode = error.errorCode
  },
  setPanel (state, panel) {
    state.panel = panel
  },
  completeAssessment (state) {
    state.questionsAnswered = 1
    state.questionsMax = 1
  },
  clearAnswers (state) {
    state.blockFormat = null
    state.answering = false
    state.assessmentError = ''
    state.assessmentErrorCode = 0
    state.panel = 0
    state.page = null
    state.answers = {}
  }
}

export const actions = {
  async complete ({ dispatch, commit }) {
    commit('completeAssessment')
    await dispatch('account/completeAssessment', null, { root: true })
    await dispatch('account/fetchProfile', null, { root: true })
    return true
  },
  async report ({ commit }, { accessCode, reportOptions }) {
    commit('reportRequest')
    try {
      const report = await AssessmentService.report(accessCode, reportOptions)
      commit('reportUpload')

      await pingReport(report.reportUrl)
      commit('reportSuccess', report)

      return report
    } catch (error) {
      commit('reportError', {
        errorCode: error.errorCode,
        errorMessage: error.message
      })
    }
  },
  async detail ({ commit }, { accessCode }) {
    commit('detailRequest')
    try {
      const details = await AssessmentService.detail(accessCode)
      commit('detailSuccess', details)
      return details
    } catch (error) {
      commit('detailError', {
        errorCode: error.errorCode,
        errorMessage: error.message
      })
      return false
    }
  },
  async page ({ commit }, { accessCode }) {
    commit('pageRequest')
    try {
      const page = await AssessmentService.page(accessCode)
      page.accessCode = accessCode
      commit('pageSuccess', page)
      commit('pageIntro', page)
      commit('pageAnswers', reducePageAnswers(page))

      return page
    } catch (error) {
      commit('pageError', {
        errorCode: error.errorCode,
        errorMessage: error.message
      })
      return false
    }
  },
  async saveAnswers ({ state, commit }) {
    if (!state.answering) {
      commit('saveAnswersRequest')
      try {
        const nextPage = await AssessmentService.answer(state.accessCode,
          Object.keys(state.answers)
            .map(id => ({ id, value: state.answers[id] }))
        )
        commit('saveAnswersSuccess', nextPage)
        commit('pageIntro', nextPage)

        return nextPage
      } catch (error) {
        commit('saveAnswersError', {
          errorCode: error.errorCode,
          errorMessage: error.message
        })
        return false
      }
    }
    return false
  }
}

export const assessment = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
