/**
 * Capitalizes first letter of string
 *
 * @param {string} str - string
 * @returns {string} uppercased string
 */
export const capitalizeFirstLetter = str =>
  str.replace(/^\w/, char => char.toUpperCase())

/**
 * Returns last word of string separated by spaces
 *
 * @param { String } str - string to get last word from
 * @returns { String } last word of string
 */
export const getLastWord = (str) => {
  const arr = str.split(' ')
  return arr[arr.length - 1]
}

/**
 * Returns first 4 characters of string
 *
 * @param {string} str - string
 * @returns {string} first 4 characters of string
 */
export const first4Chars = str => str.substring(0, 4)

/**
 * Returns last 4 characters of string
 *
 * @param {string} str - string
 * @returns {string} last 4 characters of string
 */
export const last4Chars = str => str.substring(str.length - 4)

/**
 * Converts string to lowercase
 *
 * @param {string} str - string
 * @returns {string} lowercased string
 */
export const lower = str => str.toLowerCase()

/**
 * Converts string to uppercase
 *
 * @param {string} str - string
 * @returns {string} uppercased string
 */
export const upper = str => str.toUpperCase()

/**
 * Prevents wrap between last word of string and graphical element
 *
 * @param {string} str - string
 * @param {'caret' | 'download'} variant - which graphical object to render
 * @param {boolean} [disabled=false] - whether graphical object is in disabled state
 * @returns {string} formatted string with spans to prevent wrap
 */
export const noWrapLast = (str, variant, disabled) => {
  const wordToWrap = getLastWord(str)
  const replacements = {
    caret: `<span class="word-break-none">${wordToWrap}<span class="caret"></span></span>`,
    download: `<span class="word-break-none">${wordToWrap}<img  alt="" class="link--download__image" src="/images/download-icon${
      disabled ? '-disabled' : ''
    }.svg"/></span>`
  }

  // The replace function only replaces the first occurrence of the found word.
  // When the last word appears in the middle of the string, it replaces that occurrence,
  // leaving the last word unchanged. this is to ensure that the substitution is only done on the last word
  const escapedWordToWrap = wordToWrap.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  const regexLastWordOnly = new RegExp(`${escapedWordToWrap}(?!.*${escapedWordToWrap})`)

  // need an extra span in case of rollovers
  return `<span>${str.replace(regexLastWordOnly, replacements[variant])}</span>`
}

export const markdownListToHtml = text =>
  text
    .replace(/ {2,}/g, '')
    .replace(/(\n-[\s\S]*?)(?=(\n\n|$))/g, '\n<ul>$1\n</ul>')
    .replace(/((\n)-(\W?)(.*?)(?=[\n]))/g, '\n<li>$4</li>')

/**
 * Removes spaces from string
 * @param {string} - title to format
 * @return {string} - formatted title
 */
export const noSpaces = title => title.replace(/\s/g, '')

export const checkSimilarity = (s1, s2) => {
  let longer = s1
  let shorter = s2

  if (s1.length < s2.length) {
    longer = s2
    shorter = s1
  }

  const longerLength = longer.length
  if (longerLength === 0) return 1.0
  return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)

  function editDistance (s1, s2) {
    s1 = s1.toLowerCase()
    s2 = s2.toLowerCase()

    const costs = []
    for (let i = 0; i <= s1.length; i++) {
      let lastValue = i
      for (let j = 0; j <= s2.length; j++) {
        if (i === 0) {
          costs[j] = j
        } else {
          if (j > 0) {
            let newValue = costs[j - 1]
            if (s1.charAt(i - 1) !== s2.charAt(j - 1)) newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1
            costs[j - 1] = lastValue
            lastValue = newValue
          }
        }
      }
      if (i > 0) costs[s2.length] = lastValue
    }
    return costs[s2.length]
  }
}

export const markdownLinksToJsx = (linkId, str) =>
  function render (h) {
    const matches = str.matchAll(/([^[]*)\[([^[\]]+)\]\(([^()]+)\)([^[]*)/g)
    return [...matches].map((match) => {
      return <span>
            <span domPropsInnerHTML={match[1]}></span>
            <router-link id={linkId} to={match[3]} domPropsInnerHTML={match[2]}></router-link>
            <span domPropsInnerHTML={match[4]}></span>
          </span>
    })
  }

export const parseMarkdownLinks = str => {
  const matches = str.matchAll(/([^[]*)\[([^[\]]+)\]\(([^()]+)\)([^[]*)/g)
  const matchArray = [...matches]
  return matchArray
}
