<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.42 15.42">
    <g>
      <line :class="`x__stroke stroke-${variant}`" x1="1.71" y1="1.71" x2="13.71" y2="13.71"/>
      <line :class="`x__stroke stroke-${variant}`" x1="1.71" y1="13.71" x2="13.71" y2="1.71"/>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'x-icon',
  props: {
    variant: {
      type: String,
      default: 'brand'
    }
  }
}
</script>

<style lang="scss">
.x__stroke {
  fill: none;
  stroke-linecap: round;
  stroke-width: 3.42px;
}
</style>
