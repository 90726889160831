import ApiService from '../api'
import { ExtendableError } from '../../util/error'

class LocationError extends ExtendableError {
  constructor (errorCode, message) {
    super(message)
    this.name = 'LocationError'
    this.message = message
    this.errorCode = errorCode
  }
}

const LocationService = {

  /**
   * Fetch a list of all countries
   * @throws LocationError
   */
  async fetchCountries () {
    try {
      const response = await ApiService.customRequest({
        method: 'GET',
        url: '/countries'
      })
      return response.data
    } catch (error) {
      throw new LocationError(error.response.status, error.response.data.message)
    }
  },

  /**
   * Fetch a list of all region for a given country
   * @throws LocationError
   */
  async fetchRegions (countryId) {
    try {
      const response = await ApiService.customRequest({
        method: 'GET',
        url: `/countries/${countryId}/regions`
      })
      return response.data
    } catch (error) {
      throw new LocationError(error.response.status, error.response.data.message)
    }
  }
}

export default LocationService
export { LocationService, LocationError }
