import { LocationService } from '../../services/location'

/**
 * @module Location Module
 * @description Vuex module responsible for fetching geographic data
 */

const state = {
  pending: false,
  status: 0,
  error: '',
  countries: [],
  regions: []
}

const actions = {
  async countries ({ commit }) {
    commit('countriesRequest')
    try {
      const countries = await LocationService.fetchCountries()
      commit('fetchCountriesSuccess', countries)
    } catch (e) {
      commit('fetchCountriesError', {
        errorMessage: e.message,
        errorCode: e.errorCode
      })
    }
  },

  async regions ({ commit }, countryId) {
    commit('regionsRequest')
    try {
      const regions = await LocationService.fetchRegions(countryId)
      commit('fetchRegionsSuccess', regions)
    } catch (e) {
      commit('fetchRegionsError', {
        errorMessage: e.message,
        errorCode: e.errorCode
      })
    }
  }
}

const mutations = {
  countriesRequest (state) {
    state.pending = true
    state.status = 0
    state.error = ''
    state.countries = []
    state.regions = []
  },
  fetchCountriesSuccess (state, countries) {
    state.pending = false
    state.status = 200
    state.countries = countries
  },
  fetchCountriesError (state, { errorMessage, errorCode }) {
    state.pending = false
    state.status = errorCode
    state.error = errorMessage
    state.countries = []
    state.regions = []
  },
  regionsRequest (state) {
    state.pending = true
    state.status = 0
    state.error = ''
    state.regions = []
  },
  fetchRegionsSuccess (state, regions) {
    state.pending = false
    state.status = 200
    state.regions = regions
  },
  fetchRegionsError (state, { errorMessage, errorCode }) {
    state.pending = false
    state.status = errorCode
    state.error = errorMessage
    state.regions = []
  }
}

export const location = {
  namespaced: true,
  state,
  actions,
  mutations
}
