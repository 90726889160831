<template>
  <b-alert ref="ribbon"
    fade
    v-bind="attrs"
    :variant="variant"
    :show="show"
    v-on="$listeners"
    class="ribbon d-flex justify-content-center align-items-center"
  >
    <span v-if="variant" class="ribbon__message">
      <!--  @slot default slot to enter ribbon messaging -->
      <slot>
        <p class="ribbon__message--default">{{ label }}</p>
      </slot>
    </span>
  </b-alert>
</template>

<script>

/**
 * @module Ribbon
 * Presents children content an a themeable full width color band. Intended for Error and Success messaging for a large site wide action
 * This is a convenience wrapper for Bootstrap Vue's Alert component and will take the same options.
 */
export default {
  name: 'ribbon',
  inheritAttrs: false,
  props: {

    /**
     * Optional label for when slot isn't passed
     */
    label: {
      type: String,
      default: ''
    },

    /**
     * Bootstrap Theme color (primary, secondary, success, warning, danger, info, etc...)
     */
    variant: {
      type: String,
      default: null
    },

    /**
     * Shows the alert. If set to a number, it will show for that many seconds.
     */
    show: {
      type: [Boolean, Number, String],
      default: true
    }
  },
  computed: {
    attrs () {
      const { variant, ...rest } = this.$attrs
      return rest
    }
  },
  methods: {

    /**
     * Hides ribbon content and resets background to none
     */
    clear () {
      // eslint-disable-next-line vue/no-mutating-props
      this.variant = null
    }
  }
}
</script>

<style lang="scss">
@import "../../styles/base";
.ribbon {
  .alert {
    padding: $spacer;
    width: 100%;
    font-weight: $font-weight-bold;
    font-size: $font-size-md;
    border-radius: 0;
    min-height: 100px;
    text-align: center;
    transition: $transition-base;

    @include media-breakpoint-up('lg') {
      min-height: 76px;
      font-size: $input-btn-font-size;
    }
  }
  &__message {
    &--default {
      margin-bottom: 0;
      color: $light;
    }
  }
}

@each $color, $value in $theme-colors {
  .ribbon.alert-#{$color} {
    background-color: $value;
    color: $white;

    a.link {
      color: darken($value, 25%);
    }
  }
}

.ribbon.alert-dismissible .close {
  top: auto;
  padding: $spacer;
  color: $white;
  opacity: 1;
  &:hover {
    opacity: 0.5;
  }
}
</style>
