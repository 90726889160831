// import { http, HttpResponse } from 'msw'

const OrganizationServiceMocks = (baseURL) => [
  // http.get(`${baseURL}/organization/example-mock`, () => {
  //   return new HttpResponse.json({
  //     exampleProperty: 'This was a successful mock!'
  //   })
  // })
]

export default OrganizationServiceMocks
