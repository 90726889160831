import ApiService from '../api'
import { ExtendableError } from '../../util/error'

class DemographicsError extends ExtendableError {
  constructor (errorCode, message) {
    super(message)
    this.name = 'DemographicsError'
    this.message = message
    this.errorCode = errorCode
  }
}

const DemographicsService = {

  /**
   * Fetch translated demographics questions
   * @param { string } accessCode
   * @returns AssessmentPage
   * @throws DemographicsError
   */
  async fetchQuestions (accessCode) {
    try {
      const response = await ApiService.customRequest({
        method: 'GET',
        url: '/assessment/demographics/' + accessCode
      }, '/v3')

      return response.data.questionBlocks[0]
    } catch (error) {
      throw new DemographicsError(error.response.status, error.response.data.message)
    }
  },

  /**
   * Save responses to demographics questions
   * @param { object } demographicsResponse
   * @param { string } demographicsResponse.accessCode
   * @param { object[] } demographicsResponse.answers
   * @param { number } demographicsResponse.answers[].id
   * @param { number } demographicsResponse.answers[].value
   * @param { string } demographicsResponse.answers[].otherValue
   * @throws DemographicsError
   */
  async saveResponses (demographicsResponse) {
    const requestData = {
      method: 'POST',
      url: '/assessment/demographics/save',
      data: demographicsResponse
    }

    try {
      await ApiService.customRequest(requestData, '/v3')
      return true
    } catch (error) {
      throw new DemographicsError(error.response.status, error.response.data.message)
    }
  }
}

export default DemographicsService
export { DemographicsService, DemographicsError }
