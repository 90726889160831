import i18next from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

import { initOptions } from './i18nextOptions'

i18next
  .use(Backend)
  .use(LanguageDetector)
  .init(initOptions)

export default i18next
