/**
 * @module content.constants
 * @description a collection of commonly referenced primitive values
 */

/**
 * separates hierarchical nodes in a node path
 */
export const PATH_SEPARATOR = '_'

/**
 * matches all nodes in a path
 */
export const PATH_COMPONENTS_REGX = /([a-z]+)(?=[\d]+)?/gi

export const ANI_FRAME_BASE_PATH = '/images/frames/'

export const STYLE_ORDER = {
  dc: 1,
  d: 2,
  di: 3,
  id: 4,
  i: 5,
  is: 6,
  si: 7,
  s: 8,
  sc: 9,
  cs: 10,
  c: 11,
  cd: 12
}

export const STRETCH_QUADRANT_MAP = {
  D: ['CD', 'D', 'Di'],
  i: ['Di', 'i', 'iS'],
  S: ['CS', 'S', 'iS'],
  C: ['CS', 'C', 'CD']
}

export const THEME_COLORS = [
  'primary',
  'secondary',
  'success',
  'info',
  'warning',
  'confirm',
  'danger',
  'disabled',
  'white',
  'light',
  'dark',
  'brand',
  'medium',
  'lightgray',
  'darkblue',
  'alertblue',
  ...Object.keys(STYLE_ORDER)
]

export const STYLES = Object.keys(STYLE_ORDER)

export const UPPERCASE_STYLES = [
  'DC',
  'D',
  'DI',
  'iD',
  'i',
  'iS',
  'Si',
  'S',
  'SC',
  'CS',
  'C',
  'CD'
]

export const TOKEN_REGX = /{?([\w]+)(\(|{)(([\w]+)(\)|}))(([^(){}]*)})?/g

export const TOKEN_CONTEXTS = {
  primary: 'learner',
  secondary: 'peerLearner',
  fname: '.firstName',
  lname: '.lastName',
  style: '.style.style',
  department: '.organization.department',
  org: '.organization.organizationName',
  organization: '.organization.organizationName',
  company: '.organization.organizationName'
}

export const CATALYST_SITE_ID = 1

export const CSS_VARIABLES = {
  discColors: {
    green: '#009e3d',
    red: '#cd3741',
    blue: '#00a0d1',
    yellow: '#f3cc23'
  },
  rem: 16,
  font: {
    color: '#323131'
  }
}
