<template>
  <app-layout-base class="app-layout--open" :showAlerts="showAlerts">
    <template #main>
      <slot />
    </template>
    <template #footer>
      <app-footer :loggedIn="loggedIn" :assigneeEmail="assigneeEmail" />
    </template>
  </app-layout-base>
</template>

<script>
import { appLayouts } from './AppConstants'
import AppLayoutBase from './AppLayoutBase'
import AppFooter from './AppFooter'

export default {
  name: appLayouts.OPEN,
  components: { AppLayoutBase, AppFooter },
  props: {
    showAlerts: {
      type: Boolean,
      default: true
    },
    assigneeEmail: {
      type: String,
      default: ''
    },
    loggedIn: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss">
@import "../styles/base";
.app-layout.app-layout--open {
  height: 100%;
  display: flex;
  flex-direction: column;
  #app-main {
    height: auto;
    flex-grow: 1;
    flex-shrink: 0;
  }
  #app-footer {
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: flex-end;
  }
}
</style>
