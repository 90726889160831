<template>
  <b-modal
    hide-footer
    ref="browserWarningModal"
    :title-class="['h3', 'm-none']"
    :body-class="['p-none']"
    :title="$t('ui.browserWarning.title')"
  >
    <template #modal-header-close>
      <x-icon class="browser-warning__close" variant="dark" />
    </template>

    <div class="browser-warning__action">
      <i18n path="ui.browserWarning.modalBody" tag="p">
        <b-button variant="link" @click="hideModal">
          {{ $t('ui.browserWarning.confirmIE') }}
        </b-button>
      </i18n>
    </div>

    <hr class="browser-warning__separator" />
    <h3 class="browser-warning__text">{{ $t('ui.browserWarning.supportedBrowsers') }}</h3>
    <p
      class="browser-warning__supported-browsers"
      v-for="(browser, index) in $t('ui.browserWarning.browsers').split('<br>')"
      :key="index"
      v-html="browser"
    />
  </b-modal>
</template>

<script>
import XIcon from '../icons/XIcon.vue'

export default {
  name: 'browser-warning',
  components: { XIcon },
  methods: {
    showModal () {
      this.$refs.browserWarningModal.show()
    },
    hideModal () {
      this.$refs.browserWarningModal.hide()
    }
  },
  mounted () {
    this.showModal()
  }
}
</script>

<style lang="scss">
@import "../../styles/base";

.browser-warning {
  &__close {
    width: 1rem;
    height: 1rem;
  }

  &__action {
    padding-top: 0.4rem;

    @include media-breakpoint-down("sm") {
      padding-top: 0;
    }

    .btn-link {
      padding: 0;
      min-width: 0;
      margin-bottom: calc(1rem / 16);
    }
  }

  &__separator {
    text-transform: uppercase;
    line-height: 1.5rem;
    position: relative;

    @include media-breakpoint-down("sm") {
      width: 100%;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      line-height: 17px;
    }

    @include media-breakpoint-up("md") {
      margin-top: 0.1875rem;
    }

    .separator__text {
      overflow: hidden;
      text-align: center;
      display: block;

      &::before,
      &::after {
        content: "";
        display: block;
        height: 0.75rem;
        width: 1px;
        background-color: $gray-700;
        margin: 0 auto;

        @include media-breakpoint-down("sm") {
          display: inline-block;
          height: 1px;
          width: 50%;
          vertical-align: middle;
          position: relative;
        }
      }

      @include media-breakpoint-down("sm") {
        &::before {
          right: 0.5rem;
          margin-left: -50%;
        }

        &::after {
          left: 0.5rem;
          margin-right: -50%;
        }
      }
    }
  }

  &__text {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  &__supported-browsers:nth-child(even) {
    margin-bottom: 0;
  }
}
</style>
