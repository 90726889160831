<template>
  <transition name="fade">
    <span
      v-if="loading"
      class="loadingSpinner"
      :class="{ fullScreen, fixed }"
    >
      <b-spinner class="loadingSpinner__icon" :variant="variant" large />
    </span>
  </transition>
</template>

<script>

/**
 * @module LoadingSpinner
 * Spinner with transparent mask
 */
export default {
  name: 'loading-spinner',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'primary'
    },
    fullScreen: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/base';

.loadingSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  z-index: $zindex-fixed + 2000;

  &__icon {
    width: 3rem;
    height: 3rem;
  }

  &.fixed {
    position: fixed;
  }

  &.fullScreen {
    min-height: 100vh;
    position: static;
  }
}
</style>
