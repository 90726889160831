import FeatureFlags from '@darwin/feature-flags'

export const initialState = {
  fetching: false,
  initialized: false,
  context: {},
  flags: {},
  error: ''
}

const state = Object.assign({}, initialState)

const getters = {
  current: state => Object.keys(state.flags).reduce((acc, key) => ({
    ...acc,
    [key]: state.flags[key].current
  }), {})
}

const actions = {
  async initialize ({ commit }) {
    commit('initializeRequest')

    try {
      const promisedInit = (args) => new Promise((resolve) => {
        FeatureFlags.init(args)

        FeatureFlags.on('ready', () => {
          const flags = FeatureFlags.allFlags()
          commit('updateFlags', flags)
          resolve()
        })
      })

      await promisedInit({
        clientType: 'LAUNCH_DARKLY',
        clientOptions: {
          apiClientKey: window.env.PUBLIC_LAUNCH_DARKLY_CLIENT_KEY
        },
        context: {
          anonymous: true
        }
      })

      FeatureFlags.on('change',
        (flags) => commit('updateFlags', flags)
      )

      commit('initializeSuccess')
    } catch (e) {
      commit('initializeError', e.message)
      return false
    }
  },
  async updateContext ({ commit }, context) {
    commit('updateContextRequest')

    try {
      FeatureFlags.updateContext(context)

      commit('updateContextSuccess', context)
    } catch (e) {
      commit('updateContextError', e.message)
      return false
    }
  }
}

const mutations = {
  initializeRequest (state) {
    state.fetching = true
    state.initialized = false
    state.context = {}
    state.flags = {}
    state.error = ''
  },
  initializeSuccess (state) {
    state.fetching = false
    state.initialized = true
  },
  initializeError (state, error) {
    state.fetching = false
    state.initialized = false
    state.error = error
  },
  updateContextRequest (state) {
    state.fetching = true
    state.error = ''
  },
  updateContextSuccess (state, context) {
    state.fetching = false
    state.context = context
  },
  updateContextError (state, error) {
    state.fetching = false
    state.error = error
  },
  updateFlags (state, flags) {
    const expandedFlags = Object.keys(flags).reduce((acc, flag) => {
      if (typeof flags[flag] === 'boolean') {
        acc[flag] = { previous: null, current: flags[flag] }
      } else {
        acc[flag] = flags[flag]
      }
      return acc
    }, {})

    state.flags = Object.assign({}, state.flags, expandedFlags)
  }
}

export const featureFlags = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
