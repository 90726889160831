import BootstrapVue from 'bootstrap-vue'
import Vue from 'vue'
import Vue2TouchEvents from 'vue2-touch-events'
import { VuePlugin } from 'vuera'
import './fonts'
import App from './hocs/App'

import i18n, { updateHtmlLangAttribute } from './i18n'

import I18NextVue from 'i18next-vue'
import i18next from './i18next'

import router, { page } from './router'
import ApiService from './services/api'
import ApiMocksService from './services/mock/browser'
import StorageService from './services/storage'
import TextService from './services/text'
import store from './store'
import './styles/main.scss'
import './util/filters/filters'
import pendo from './util/integrations/pendo'
import logRocket from './util/integrations/logRocket'
import { getPageTitleForRouteName } from './util/pageTitle'
import waitForValue from './util/waitForValue'

// Initialize LogRocket first so we can collect error metrics as early as possible
logRocket.initialize()

if (window.env.PUBLIC_LAUNCH_DARKLY_CLIENT_KEY) {
  store.dispatch('featureFlags/initialize').then(() => {
    waitForValue(
      () => store.getters['featureFlags/current'].CRM_SUPPORT_CHAT,
      true,
      false,
      10000
    )
      .then(() => {
        if (store.getters['featureFlags/current'].CRM_SUPPORT_CHAT) {
          function insertCRMChat () {
            if (
              window.env &&
              window.env.PUBLIC_CRM_SUPPORT_CHAT_STYLE &&
              window.env.PUBLIC_CRM_SUPPORT_CHAT_EXTRA_STYLE &&
              window.env.PUBLIC_CRM_SUPPORT_CHAT_EMBEDDED &&
              window.env.PUBLIC_CRM_SUPPORT_CHAT
            ) {
              const stylesLink = document.createElement('link')
              stylesLink.rel = 'stylesheet'
              stylesLink.href = window.env.PUBLIC_CRM_SUPPORT_CHAT_STYLE

              const extraStylesLink = document.createElement('link')
              extraStylesLink.rel = 'stylesheet'
              extraStylesLink.href = window.env.PUBLIC_CRM_SUPPORT_CHAT_EXTRA_STYLE

              const eswScript = document.createElement('script')
              eswScript.type = 'text/javascript'
              eswScript.src = window.env.PUBLIC_CRM_SUPPORT_CHAT_EMBEDDED

              document.head.appendChild(stylesLink)
              document.head.appendChild(extraStylesLink)
              document.head.appendChild(eswScript)

              eswScript.onload = () => {
                const catalystScript = document.createElement('script')
                catalystScript.type = 'text/javascript'
                catalystScript.src = window.env.PUBLIC_CRM_SUPPORT_CHAT

                document.head.appendChild(catalystScript)

                catalystScript.onload = () => store.dispatch('account/populateSupportChatFields')

                window.embedded_svc.addEventHandler(
                  'onHelpButtonClick',
                  () => store.dispatch('account/populateSupportChatFields')
                )
              }
            }
          }

          // Check if DOM is already loaded
          if (document.readyState === 'loading') {
            // If DOM is still loading, wait for DOMContentLoaded event
            document.addEventListener('DOMContentLoaded', insertCRMChat)
          } else {
            // If DOM is already loaded, insert CRM chat immediately
            insertCRMChat()
          }
        }
      })
  })
}

ApiMocksService.init(window.env.PUBLIC_ROOT_API)
const ApiMocksServiceWorker = ApiMocksService.setupWorker()
ApiMocksServiceWorker.start({ onUnhandledRequest: 'bypass' })
if (window.env.PUBLIC_NODE_ENV === 'development') {
  ApiMocksServiceWorker.listHandlers().forEach((handler) => {
    // eslint-disable-next-line no-console
    console.log(handler.info.header)
  })
}

pendo.initialize()

// Set the base URL of the API
ApiService.init(window.env.PUBLIC_ROOT_API)
ApiService.mountRequestInterceptor()
ApiService.mountResponseInterceptor()

// If token exists set header
if (StorageService.getToken()) {
  ApiService.setAuthHeader(StorageService.getToken())
}

/*
  Both the i18next-vue and vue-i18n utilize the same $t method. So to maintain compatibility
  with both plugins, we have to store the original $t method from vue-i18n in a separate variable
  and then assign it back to the Vue prototype after the i18next-vue the plugin has been installed,
  this way we preserve the old plugin's $t method while introducing a new alias, $tt, for the i18next-vue.
  This ensures seamless functionality despite potential conflicts between the two plugins,
  and thus the loading order in this integration is important.
*/
const vuei18nT = Vue.prototype.$t

Vue.use(I18NextVue, { i18next })
Vue.prototype.$tt = Vue.prototype.$t
Vue.prototype.$t = vuei18nT

Vue.use(BootstrapVue)
Vue.use(Vue2TouchEvents)
Vue.use(VuePlugin)
Vue.config.productionTip = false
Vue.config.ignoredElements = ['disc-graph']

// Validate login status and redirect to authorized destination
router.beforeEach(async (to, from, next) => {
  if (window.$vm && store.getters['account/organizationChanged'] &&
    store.getters['account/loggedIn'] &&
    !StorageService.getFeatureAnnoucementViewed('organization_changed') &&
    to.path === '/') {
    if (window.$vm.$bvModal) { window.$vm.$bvModal.show('organization-changed') }
  }

  i18next.on('initialized', (options) => {
    const i18nextLanguage = options.lng || i18next.options.fallbackLng[0]
    updateHtmlLangAttribute(i18nextLanguage)
  })

  i18next.on('languageChanged', (lng) => {
    updateHtmlLangAttribute(lng)
  })

  if (to.query.firstName || to.query.lastName || to.query.avatar) {
    const hasFirstAndLastName = (to.query.firstName && to.query.lastName)

    StorageService.setDemoUser({
      ...(hasFirstAndLastName && { firstName: to.query.firstName }),
      ...(hasFirstAndLastName && { lastName: to.query.lastName }),
      ...(to.query.avatar && { avatarUrl: to.query.avatar })
    })
  }

  router.pendingRoute = to

  // logout
  if (to.path === '/logout') {
    await store.dispatch('account/logout')
    return next('/login')
  }

  // SSO callback
  if (to.matched.length && to.matched[0].name === 'ssoCallback') {
    try {
      const user = await store.dispatch('account/authenticateSso', {
        code: to.query.code,
        federation: to.params.federation,
        apiState: to.query.state
      })

      const parsedState = JSON.parse(decodeURIComponent(user.state))

      if (user.userName.toLowerCase() !== parsedState.username.toLowerCase()) {
        return next({
          name: 'errorSsoMismatch',
          params: { enteredEmail: parsedState.username, ssoEmail: user.userName }
        })
      }

      if (parsedState.ac) {
        const success = await store.dispatch('account/accessCode', parsedState.ac)
        if (!success) {
          return next('/error')
        }
      }

      // Redirect the user to the page they first tried to visit or home
      return next(to.query?.redirect || '/')
    } catch {
      return next('/login')
    }
  }

  // admin login
  if (to.query.token) {
    const success = await store.dispatch('account/adminAuth', to.query.token)
    if (success) {
      return next()
    }
    return next('/login')
  }

  const isPublic = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(
    record => record.meta.onlyWhenLoggedOut
  )
  const loggedIn = store.getters['account/loggedIn']

  if (!isPublic && !loggedIn) {
    store.dispatch('account/logout')
    return to.fullPath === '/'
      ? next('/login')
      : next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
  }

  if (loggedIn && !!to.query.ac) {
    return store
      .dispatch('account/fetchProfile')
      .then((success) => {
        if (!success) {
          return next('/error')
        }

        if (!store.getters['account/accessCodes'].includes(to.query.ac)) {
          store
            .dispatch('account/logout')
            .then(() => next({ name: 'login', query: to.query }))
        } else {
          next()
        }
      })
      .catch(() => {
        next('/error')
      })
  }

  if (loggedIn && onlyWhenLoggedOut) {
    return next('/')
  }

  // add profile prefetch if needed
  if (!isPublic && loggedIn) {
    return store
      .dispatch('account/fetchProfile')
      .then((success) => {
        if (!success) {
          return next('/error')
        }

        if (
          !store.getters['account/accessCode'] &&
          to.path !== '/access-code'
        ) {
          return next('/access-code')
        }

        if (
          store.getters['account/isMissingProfileFields'] &&
          to.path !== '/login/profile'
        ) {
          return next('/login/profile')
        }

        next()
      })
      .catch(() => {
        next('/error')
      })
  }

  next()
})

// We have to invoke the text fetch call in beforeResolve,
// this will allow that any beforeRouteEnter guards from main.js is
// executed first, followed by whatever beforeRouteEnter guards from the HOCs
// and lastly try to pull any text text from the API.
//
// This way we can do any permission verification in a HOC and avoid
// getting a premature text pull error, while still having it globally available.
//
// https://router.vuejs.org/guide/advanced/navigation-guards.html#the-full-navigation-resolution-flow
router.beforeResolve(async (to, _, next) => {
  const route = page(to)
  if (to.matched.some(record => record.meta.pullPageText)) {
    store.commit('app/prefetchStart')

    if (to.meta.page) {
      store.dispatch('text/page', route).then((success) => {
        if (success) {
          i18n.mergeLocaleMessage(
            store.state.text.locale,
            store.state.text.messages
          )
          return next()
        }
        return next('/error')
      })
    } else {
      const language = i18next.resolvedLanguage ?? i18next.language
      const extraParameters = to.meta.context === 'compare'
        ? {
            userId: store.getters['account/accountID'],
            comparedToUserId: Number.parseInt(to.params.peerLearnerId)
          }
        : {}
      await TextService.process({
        language,
        content: to.meta.content ?? 'catalyst-learner-ui-dynamic',
        context: to.meta.context,
        extraParameters

      }).then(async (response) => {
        if (i18next.hasResourceBundle(language, to.meta.context)) {
          i18next.removeResourceBundle(language, to.meta.context)
        }

        i18next.addResourceBundle(
          language,
          to.meta.context,
          response[0].texts
        )

        return next()
      }).catch(() => next('/error'))
    }

    store.commit('app/prefetchEnd')
  } else {
    next()
  }
})

router.afterEach(async (to, from) => {
  // there are pages that have the same name so we have to differentiate them

  if (!to.meta.dynamicTitle) {
    const pageName = to.name !== 'landing' ? to.name : `landing${to.path.split('/').at(2)}`
    const pageTitle = await getPageTitleForRouteName(pageName, to.params)
    document.title = pageTitle
  }

  if (to.name !== 'assessment' || (parseInt(to.params.page) !== parseInt(from.params.page))) {
    // The condition above keeps the health check for all actual page changes.
    // During the assessment, it will only run when saving a set of questions, not between questions.
    // parseInt() because when coming from a direct link from the browser, it ends up doing a String/Int Compare

    store.dispatch('app/refreshMaintenanceMode')
  }
  if (to.query.warn === 'blocked-popup') {
    window.$vm.$bvModal.show('blockedPopupWarning-announcement')
  }

  // When a user is signed in under SSO, we show a message on the first page they see
  // However, we can't tell the store to hide the message until they have gone to the next page
  // or it won't show on the first page.
  // The user in the vuex store doesn't update with the local storage one unless you refresh.
  const storedUser = StorageService.getUser()
  if (storedUser && storedUser.showSsoAlert) {
    const updatedUser = { ...storedUser, showSsoAlert: false, hideSsoAlertNextPage: true }
    StorageService.saveUser(updatedUser)
  } else if (storedUser && !storedUser.showSsoAlert && storedUser.hideSsoAlertNextPage) {
    store.commit('account/ssoAlertHidden')
  }

  // Salesforce Support Chat Widget -- update current page information
  if (window.embedded_svc) {
    const journeyIdx = window.embedded_svc.settings.extraPrechatFormDetails
      .findIndex((detail) => detail.label === 'Journey')

    const journeyField = {
      label: 'Journey',
      transcriptFields: ['Journey__c'],
      value: window.origin + to.fullPath
    }

    if (journeyIdx) {
      window.embedded_svc.settings.extraPrechatFormDetails[journeyIdx] = journeyField
    } else {
      window.embedded_svc.settings.extraPrechatFormDetails.push(journeyField)
    }
  }

  // focus body on every page load for accessibility and for
  // the skip link to be the first focusable element in every
  // page on page load, except for the cookie consent banner
  document.querySelector('body').focus()
})

window.$vm = new Vue({
  // Change the lang dynamically whenever the i18n lang changes
  watch: {
    i18n: {
      handler: updateHtmlLangAttribute(i18n.locale),
      immediate: true
    }
  },
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#shell')
