import { uniq } from 'lodash'

export function setValue (obj, path, value) {
  const a = path.split('.')
  let o = obj
  while (a.length - 1) {
    const n = a.shift()
    if (!(n in o)) o[n] = {}
    o = o[n]
  }
  o[a[0]] = value
}

export function getValue (obj, path) {
  path = path.replace(/\[(\w+)\]/g, '.$1')
  path = path.replace(/^\./, '')
  const a = path.split('.')
  let o = obj
  while (a.length) {
    const n = a.shift()
    if (o == null) return
    if (!(n in o)) return
    o = o[n]
  }
  return o
}

export const findAllByKey = (obj, keyToFind) =>
  uniq(Object.entries(obj)
    .reduce((acc, [key, value]) => (key === keyToFind)
      ? acc.concat(value)
      : (typeof value === 'object')
          ? acc.concat(findAllByKey(value, keyToFind))
          : acc
    , []))
