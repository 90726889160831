export const appLayouts = {
  DEFAULT: 'app-layout-default',
  OPEN: 'app-layout-open',
  NOSCROLL: 'app-layout-no-scroll',
  MINIMAL: 'app-layout-minimal'
}

export const learnerStyles = {
  d: ['DC', 'D', 'Di'],
  i: ['iD', 'i', 'iS'],
  s: ['Si', 'S', 'SC'],
  c: ['CS', 'C', 'CD']
}
