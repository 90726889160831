import AppLayoutDefault from '../app/AppLayoutDefault.vue'
import {
  compose,
  mapGettersToProps
} from 'vuex-compose'

const wrapper = compose(
  mapGettersToProps({
    userName: 'account/currentUserFirstName',
    pathways: 'account/pathways',
    loggedIn: 'account/loggedIn',
    assessmentComplete: 'account/assessmentsComplete',
    assessmentStarted: 'account/assessmentsStarted',
    avatar: 'account/currentUserAvatar',
    items: 'account/pathways',
    assigneeEmail: 'account/currentAssigneeEmail',
    isDemoAccount: 'account/isDemoAccount',
    isDemoUser: 'account/isDemoUser',
    isFacilitator: 'account/isFacilitatorAccount',
    isOrganizationAdminAccount: 'account/isOrganizationAdminAccount',
    featureFlags: 'featureFlags/current',
    hasDashboardAccess: 'account/hasDashboardAccess'
  })
)

export default wrapper(AppLayoutDefault)
