// import { http, HttpResponse, passthrough } from 'msw'

const TextServiceMocks = (_baseURL) => [
  /* http.post(`${window.env.PUBLIC_DARWIN_TEXT_API}/api/v4/texts/process`, async ({ request }) => {
    const body = await request.json()
    const { content, context } = body
    if (content === 'catalyst-learner-ui-dynamic' && context === 'compare') {
      return new HttpResponse([
        {
          content: 'catalys-learner-ui-dynamic',
          language: 'en_US',
          version: 1,
          context: 'compare',
          texts: {
            section1_sticky: 'section1_sub1_halo',
            section1_sub1_body: '<B>{{secondary.fname}} has a CS style.</B> You might’ve noticed that {{secondary.fname}} is deliberate and thoughtful, often taking a methodical approach and prioritizing quality. And {{secondary.fname}} might tend to consider carefully before giving input, wanting time and space to think things through. That’s pretty typical of someone with the CS style.  ',
            section1_sub1_halo: 'secondary',
            section1_sub1_subtitle: '{{secondary.department}} | {{secondary.company}}',
            section1_sub1_title: '{{secondary.fname}} {{secondary.lname}}',
            section1_sub2_group: 'tips',
            section1_sub2_group_bullets1_bullet1: 'demonstrating expertise',
            section1_sub2_group_bullets1_bullet2: 'doing things right',
            section1_sub2_group_bullets1_bullet3: 'maintaining stability',
            section1_sub2_group_label: '{{secondary.fname}} cares about:',
            section1_sub2_group1_: 'tips',
            section1_sub2_group1_bullets2_bullet1: 'a job calls for precision',
            section1_sub2_group1_bullets2_bullet2: 'a problem calls for complex logic',
            section1_sub2_group1_bullets2_bullet3: 'ensuring stability',
            section1_sub2_group1_label: 'Pull {{secondary.fname}} in when:',
            section1_sub2_group2_: 'tips',
            section1_sub2_group2_bullets3_bullet1: 'not having time to perfect the work',
            section1_sub2_group2_bullets3_bullet2: 'working in a chaotic environment',
            section1_sub2_group2_bullets3_bullet3: 'working with unpredictable people',
            section1_sub2_group2_label: '{{secondary.fname}} may be stressed by:',
            section2_: 'maps-together',
            section2_body: 'To understand how you and {{secondary.fname}} can better work together, start by checking out your DiSC<sup>®</sup> maps side by side. How do your workplace priorities compare?',
            section2_row_tabs_: 'mobileTabs',
            section2_row_tabs_tab1_: "{{secondary.fname}}'s {{secondary.style}} style",
            section2_row_tabs_tab1_bullets_bullet1: 'Stability',
            section2_row_tabs_tab1_bullets_bullet2: 'Accuracy',
            section2_row_tabs_tab1_bullets_bullet3: 'Support',
            section2_row_tabs_tab1_bullets_bullet4: 'Collaboration',
            section2_row_tabs_tab1_graph: 'learner=secondary&variant=color&border&priorities&product=71',
            section2_row_tabs_tab1_label: 'At work, {{secondary.fname}} tends to prioritize:',
            section2_row_tabs_tab2_: 'Your {{primary.style}} style',
            section2_row_tabs_tab2_bullets_bullet1: 'Challenge',
            section2_row_tabs_tab2_bullets_bullet2: 'Accuracy',
            section2_row_tabs_tab2_bullets_bullet3: 'Results',
            section2_row_tabs_tab2_bullets_bullet4: 'Action',
            section2_row_tabs_tab2_graph: 'learner=primary&variant=color&border&priorities&product=71',
            section2_row_tabs_tab2_label: 'At work, you tend to prioritize:',
            section2_title: 'Your maps together',
            section3_: 'similarities-differences',
            section3_body: 'Your DiSC assessments tell us a lot about what you and {{secondary.fname}} have in common, and where you differ. Here are some highlights:',
            section3_comparehalo: '0',
            section3_sub1_group_bullets_bullet1: 'Tend to avoid risk',
            section3_sub1_group_bullets_bullet2: 'Are enterprising',
            section3_sub1_group_bullets_bullet3: 'Initiate new projects',
            section3_sub1_group_bullets_bullet4: 'Are reserved',
            section3_sub1_group_bullets_bullet5: "Don't mind working alone",
            section3_sub1_group_label: 'You & {{secondary.fname}}',
            section3_sub1_title: 'Similarities',
            section3_sub2_group1_bullets_bullet1: 'Prefer a faster pace',
            section3_sub2_group1_bullets_bullet2: 'Are energetic',
            section3_sub2_group1_bullets_bullet3: 'Are more attracted to practical options',
            section3_sub2_group1_bullets_bullet4: 'Are driven',
            section3_sub2_group1_bullets_bullet5: 'Are less structured',
            section3_sub2_group1_label: 'You',
            section3_sub2_group2_bullets_bullet1: 'Prefers a slower pace',
            section3_sub2_group2_bullets_bullet2: 'Is calm',
            section3_sub2_group2_bullets_bullet3: 'Is more attracted to creative options',
            section3_sub2_group2_bullets_bullet4: 'Is patient',
            section3_sub2_group2_bullets_bullet5: 'Is more structured',
            section3_sub2_group2_label: '{{secondary.fname}}',
            section3_sub2_title: 'Differences',
            section3_title: 'Your similarities & differences',
            section4_: 'tendencies text-center',
            section4_body: 'See how you and {{secondary.fname}} compare on different continua. ',
            section4_continua1: 'scaleName=Accommodating_StrongWilled',
            section4_continua1_labelLeft: 'Accommodating',
            section4_continua1_labelRight: 'Strong-willed',
            section4_continua1_row_col1_bullets: 'Tends to overlook advice and input from others<br>Tends to hold tightly to own ideas<br>May come across as stubborn at times',
            section4_continua1_row_col1_label: "{{primary.fname}}'s tendencies:",
            section4_continua1_row_col2_bullets: 'Tends to welcome input from others<br>Tends to be fairly willing to set own ideas aside <br>May come across as a bit hesitant at times',
            section4_continua1_row_col2_label: "{{secondary.fname}}'s tendencies:",
            section4_continua1_title: "You're very strong-willed. {{secondary.fname}} is somewhat accommodating.",
            section4_continua2: 'scaleName=Outgoing_Private&reversed',
            section4_continua2_labelLeft: 'Private',
            section4_continua2_labelRight: 'Outgoing',
            section4_continua2_row_col1_bullets: 'Tends to avoid social situations that require meeting a lot of new people<br>Tends to be very comfortable working independently<br>May come across as reserved and difficult to evaluate',
            section4_continua2_row_col1_label: "{{primary.fname}}'s tendencies:",
            section4_continua2_row_col2_bullets: 'Tends to be indifferent about social situations that require meeting a lot of new people<br>Tends to be more comfortable working independently<br>May come across as somewhat reserved and difficult to evaluate',
            section4_continua2_row_col2_label: "{{secondary.fname}}'s tendencies:",
            section4_continua2_title: "You're very private. {{secondary.fname}} is somewhat private. ",
            section4_continua3: 'scaleName=Feeling_Logic',
            section4_continua3_labelLeft: 'Focused on feelings',
            section4_continua3_labelRight: 'Focused on logic',
            section4_continua3_row_col1_bullets: 'Looks at things critically<br>Expects others to be logical<br>Is quick to see errors',
            section4_continua3_row_col1_label: "{{primary.fname}}'s tendencies:",
            section4_continua3_row_col2_bullets: 'Takes an analytical approach<br>Wants to understand the rationale<br>Spots flaws in arguments',
            section4_continua3_row_col2_label: "{{secondary.fname}}'s tendencies:",
            section4_continua3_title: "You're very focused on logic. {{secondary.fname}} is somewhat focused on logic. ",
            section4_continua4: 'scaleName=Tactful_Frank',
            section4_continua4_labelLeft: 'Tactful',
            section4_continua4_labelRight: 'Frank',
            section4_continua4_row_col1_bullets: 'Speaks directly and gets right to the point<br>Tends not to hold back thoughts and feelings<br>May come across as blunt or insensitive at times',
            section4_continua4_row_col1_label: "{{primary.fname}}'s tendencies:",
            section4_continua4_row_col2_bullets: 'Finds the most diplomatic way to convey information<br>Tends to hold back certain thoughts and feelings<br>May come across as vague or indirect at times',
            section4_continua4_row_col2_label: "{{secondary.fname}}'s tendencies:",
            section4_continua4_title: "You're very frank. {{secondary.fname}} is very tactful. ",
            section4_continua5: 'scaleName=UnStructured_Structured&reversed',
            section4_continua5_labelLeft: 'Structured',
            section4_continua5_labelRight: 'Unstructured',
            section4_continua5_row_col1_bullets: 'Prefers playing things by ear<br>Would rather improvise than follow a plan<br>Likes to keep options open',
            section4_continua5_row_col1_label: "{{primary.fname}}'s tendencies:",
            section4_continua5_row_col2_bullets: 'Likes to follow clear plans<br>Seeks closure<br>Tends to see decisions as final ',
            section4_continua5_row_col2_label: "{{secondary.fname}}'s tendencies:",
            section4_continua5_title: "You're very unstructured. {{secondary.fname}} is somewhat structured. ",
            section4_continua6: 'scaleName=Realities_Possibilities',
            section4_continua6_labelLeft: 'Focused on realities',
            section4_continua6_labelRight: 'Focused on possibilities',
            section4_continua6_row_col1_bullets: 'Wants to know the tangible benefits of an idea<br>Prefers to use tested methods<br>Likes a clear, concrete strategy',
            section4_continua6_row_col1_label: "{{primary.fname}}'s tendencies:",
            section4_continua6_row_col2_bullets: 'Favors unconventional ideas<br>Enjoys brainstorming<br>Tends to keep long term goals in mind',
            section4_continua6_row_col2_label: "{{secondary.fname}}'s tendencies:",
            section4_continua6_title: "You're somewhat focused on realities. {{secondary.fname}} is somewhat focused on possibilities. ",
            section4_continua7: 'scaleName=Skeptical_Accepting',
            section4_continua7_labelLeft: 'Skeptical',
            section4_continua7_labelRight: 'Accepting',
            section4_continua7_row_col1_bullets: 'Tends to question and test other people’s ideas<br>Tends to anticipate and plan for what could go wrong<br>May come across as overly critical at times',
            section4_continua7_row_col1_label: "{{primary.fname}}'s tendencies:",
            section4_continua7_row_col2_bullets: 'Tends to be mostly receptive to other people’s ideas<br>Tends to expect things to go relatively well<br>May come across as a bit too easygoing at times',
            section4_continua7_row_col2_label: "{{secondary.fname}}'s tendencies:",
            section4_continua7_title: "You're very skeptical. {{secondary.fname}} is somewhat accepting.",
            section4_continua8: 'scaleName=SteadyPace_FastPace&reversed',
            section4_continua8_labelLeft: 'Fast-paced',
            section4_continua8_labelRight: 'Steady-paced',
            section4_continua8_row_col1_bullets: 'Tends to prefer working at a fairly fast pace<br>Comfortable with lots of activity and tight deadlines <br>May come across as a bit too hasty at times',
            section4_continua8_row_col1_label: "{{primary.fname}}'s tendencies:",
            section4_continua8_row_col2_bullets: 'Tends to prefer working at a measured pace<br>Dislikes last-minute deadlines or sudden changes<br>May come across as overly cautious at times',
            section4_continua8_row_col2_label: "{{secondary.fname}}'s tendencies:",
            section4_continua8_title: "You're somewhat fast-paced. {{secondary.fname}} is very steady-paced. ",
            section4_details_expand: 'DETAILS',
            section4_title: 'Compare your tendencies',
            section5_: 'working-together',
            section5_body: 'Your traits and preferences can show up in all sorts of ways in different situations. Explore the scenarios below to learn more about how this might impact your relationship with {{secondary.fname}}.',
            section5_tabs_: 'horizontalTabs',
            section5_tabs_tab1: 'Tension',
            section5_tabs_tab1_body1: "Remember, you're very frank, and {{secondary.fname}} is very tactful. ",
            section5_tabs_tab1_body2: "You're very strong-willed, and {{secondary.fname}} is somewhat accommodating.",
            section5_tabs_tab1_group1_: 'benefits',
            section5_tabs_tab1_group1_bullets_bullet1: 'You tend to be straightforward, which may help surface important issues more quickly.',
            section5_tabs_tab1_group1_bullets_bullet2: "{{secondary.fname}}'s agreeable nature helps prevent tension.",
            section5_tabs_tab1_group1_title: 'Benefits',
            section5_tabs_tab1_group2_: 'roadblocks',
            section5_tabs_tab1_group2_bullets_bullet1: '{{secondary.fname}}  may sometimes be surprised or offended by your bluntness.',
            section5_tabs_tab1_group2_bullets_bullet2: 'Because you can be so insistent with your perspective, you may sometimes neglect to really consider where {{secondary.fname}} is coming from. ',
            section5_tabs_tab1_group2_title: 'Roadblocks',
            section5_tabs_tab1_group3_bullets_bullet1: "Pay attention to {{secondary.fname}} 's reactions to gauge the impact of your words.",
            section5_tabs_tab1_group3_bullets_bullet2: 'Remember to ask for opinions since {{secondary.fname}} may not be comfortable offering them freely.',
            section5_tabs_tab1_group3_title: 'Tips',
            section5_tabs_tab1_title: 'What if things get tense?',
            section5_tabs_tab2: 'Connecting',
            section5_tabs_tab2_body1: "Remember, you're very private, and {{secondary.fname}} is somewhat private.",
            section5_tabs_tab2_body2: "You're very focused on logic, and {{secondary.fname}} is somewhat focused on logic. ",
            section5_tabs_tab2_group1_: 'benefits',
            section5_tabs_tab2_group1_bullets_bullet1: 'You both probably appreciate each other’s desire for personal space.',
            section5_tabs_tab2_group1_bullets_bullet2: 'You and {{secondary.fname}} probably make an effort to understand each other’s perspectives objectively.   ',
            section5_tabs_tab2_group1_title: 'Benefits',
            section5_tabs_tab2_group2_: 'roadblocks',
            section5_tabs_tab2_group2_bullets_bullet1: 'Because you both tend to keep to yourselves, you might miss out on chances to connect. ',
            section5_tabs_tab2_group2_bullets_bullet2: 'Because you both tend to keep your distance emotionally, you might not look for opportunities to connect. ',
            section5_tabs_tab2_group2_title: 'Roadblocks',
            section5_tabs_tab2_group3_bullets_bullet1: 'Take advantage of opportunities to collaborate and catch up with each other.  ',
            section5_tabs_tab2_group3_bullets_bullet2: 'Don’t assume that because you’re both logical, your feelings (both yours and {{secondary.fname}}’s) are inconsequential.',
            section5_tabs_tab2_group3_title: 'Tips',
            section5_tabs_tab2_title: 'What if you and {{secondary.fname}} are trying to connect?',
            section5_tabs_tab3: 'Collaboration',
            section5_tabs_tab3_body1: "Remember, you're very unstructured, and {{secondary.fname}} is somewhat structured. ",
            section5_tabs_tab3_body2: "You're somewhat fast-paced, and {{secondary.fname}} is very steady-paced.",
            section5_tabs_tab3_group1_: 'benefits',
            section5_tabs_tab3_group1_bullets_bullet1: '{{secondary.fname}}’s tendency to plan around different contingencies likely helps you two avoid preventable pitfalls.',
            section5_tabs_tab3_group1_bullets_bullet2: '{{secondary.fname}} pays close attention to how much time is needed to do things right.',
            section5_tabs_tab3_group1_title: 'Benefits',
            section5_tabs_tab3_group2_: 'roadblocks',
            section5_tabs_tab3_group2_bullets_bullet1: 'While {{secondary.fname}} likely sees joint decisions as final, you may tend to think of them as more flexible.  ',
            section5_tabs_tab3_group2_bullets_bullet2: "You may become frustrated with {{secondary.fname}}'s cautious pace.",
            section5_tabs_tab3_group2_title: 'Roadblocks',
            section5_tabs_tab3_group3_bullets_bullet1: 'Be mindful that {{secondary.fname}} may find improvisation and a lack of preparation stressful.',
            section5_tabs_tab3_group3_bullets_bullet2: 'Let {{secondary.fname}} know when you feel strongly that more urgency is called for.',
            section5_tabs_tab3_group3_title: 'Tips',
            section5_tabs_tab3_title: 'What if you and {{secondary.fname}} are working on a project together?',
            section5_tabs_tab4: 'Buy-in',
            section5_tabs_tab4_body1: "Remember, you're very skeptical, and {{secondary.fname}} is somewhat accepting. ",
            section5_tabs_tab4_body2: "You're somewhat focused on realities, and {{secondary.fname}} is somewhat focused on possibilities. ",
            section5_tabs_tab4_group1_: 'benefits',
            section5_tabs_tab4_group1_bullets_bullet1: 'Your skepticism may help uncover potential problems.',
            section5_tabs_tab4_group1_bullets_bullet2: 'You may be able to help {{secondary.fname}} envision realistic strategies for bringing ideas to life. ',
            section5_tabs_tab4_group1_title: 'Benefits',
            section5_tabs_tab4_group2_: 'roadblocks',
            section5_tabs_tab4_group2_bullets_bullet1: '{{secondary.fname}} may think that you come across as too cynical.',
            section5_tabs_tab4_group2_bullets_bullet2: 'While you may tend to emphasize the tangible benefits of an idea, {{secondary.fname}} may be more interested in seeing the bigger picture.  ',
            section5_tabs_tab4_group2_title: 'Roadblocks',
            section5_tabs_tab4_group3_bullets_bullet1: 'Explain the rationale for any concerns you may have.',
            section5_tabs_tab4_group3_bullets_bullet2: 'Identify how your idea supports long term goals and contributes to the bigger picture.  ',
            section5_tabs_tab4_group3_title: 'Tips',
            section5_tabs_tab4_title: "What if you're trying to get buy-in from {{secondary.fname}}?",
            section5_title: 'Working together',
            section6_recommendedLinks1: 'mustHave=disc/you',
            section6_recommendedLinks1_description: 'Want to keep exploring? Try one of these pages:',
            section6_recommendedLinks1_head: 'More to explore',
            section6_recommendedLinks1_recommendedLink1: 'openInNewTab=false',
            section6_recommendedLinks1_recommendedLink1_image: '/images/Home/understand-your-relationships.svg',
            section6_recommendedLinks1_recommendedLink1_linkTo: '[You and other styles](/disc/you/understand-your-relationships)',
            section6_recommendedLinks1_recommendedLink2: 'openInNewTab=false',
            section6_recommendedLinks1_recommendedLink2_image: '/images/Home/building-better-relationships.svg',
            section6_recommendedLinks1_recommendedLink2_linkTo: '[Build better relationships](/disc/you/building-better-relationships)',
            section6_recommendedLinks1_recommendedLink3: 'openInNewTab=false',
            section6_recommendedLinks1_recommendedLink3_image: '/images/Home/overview.svg',
            section6_recommendedLinks1_recommendedLink3_linkTo: '[The DiSC model](/disc/basics/overview)'
          }
        }
      ])
    }
    return passthrough()
  }) */
]

export default TextServiceMocks
