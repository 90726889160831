import AppLayoutOpen from '../app/AppLayoutOpen.vue'
import { compose, mapGettersToProps } from 'vuex-compose'

const wrapper = compose(
  mapGettersToProps({
    loggedIn: 'account/loggedIn',
    assigneeEmail: 'account/currentAssigneeEmail'

  })
)

export default wrapper(AppLayoutOpen)
