export const tabNames = ['group-map', 'style-insights', 'group-insights', 'conversation-starters']

export const isValidTab = tab => tabNames.includes(tab)
export const getTabIndex = tab => tabNames.indexOf(tab)
export const filterGroupsResponse = (groups) => {
  return groups.filter((group) => {
    let hiddenMembers = 0

    if (group.details?.members) {
      const groupHasOwner = group.details.members.find(member => member.owner)
      if (!groupHasOwner) return true

      group.details.members.forEach(member => {
        if (!member.owner && member.hidden) {
          hiddenMembers += 1
        }
      })
    }

    return !(hiddenMembers === (group.memberCount - 1))
  }).reduce((acc, cur) => [...acc, cur.details ?? cur], [])
}
