import { get } from 'lodash'
import { createCookie, eraseCookie, getCookie } from './storage'

const profileCookieName = 'profile_data'
const loggedInString = 'loggedIn'
const completeString = 'assmntComplete'
const upgradeString = 'assmntUpgrade'
const visitCountCookieName = 'visit_count'

export const getProfileAvatarUrl = profile =>
  profile.avatarUrl && profile.avatarFileName !== 'findmypicture'
    ? profile.avatarUrl
    : '/images/avatar/avatar.svg'

export function makeSessionProfileCookie (complete, upgrade) {
  // this is mainly for Qualtrics. Don't error if it breaks for some reason.
  try {
    if (getCookie(profileCookieName).includes(loggedInString)) {
      // only write to cookie once per session
      return
    }

    const cookieData = [loggedInString]
    if (complete) {
      cookieData.push(completeString)
    }
    if (upgrade) {
      cookieData.push(upgradeString)
    }
    createCookie(profileCookieName, cookieData)
  } catch {}
}

export function visitCountCookie (countVisit) {
  try {
    if (getCookie(visitCountCookieName)) {
      return
    }

    const cookieData = countVisit
    createCookie(visitCountCookieName, cookieData)
  } catch {}
}

export function clearSessionProfileCookie () {
  eraseCookie(profileCookieName)
}

export const getProfileFullName = ({ firstName, lastName }) => `${firstName} ${lastName}`

export const getProfileInitials = ({ firstName, lastName }) => (firstName ? firstName.substring(0, 1) : '') + (lastName ? lastName.substring(0, 1) : '')

export const getLearnerStyle = (learner, defaultStyle = 'C') => get(learner, 'style.style', defaultStyle)

export const getCurrentOrganization = (organizations, profile) => {
  if (organizations.length && profile.organizationID) {
    return organizations.find((organization) => organization.organizationId === profile.organizationID)
  }

  return null
}

export const flatProfilesToGraphFormat = (profiles) =>
  profiles.map(
    ({ firstName, lastName, style, angle, vector, avatarUrl }) => ({
      firstName,
      lastName,
      avatarUrl,
      style: {
        style,
        angle,
        vector
      }
    }))
