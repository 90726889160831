// import { http, HttpResponse } from 'msw'

const HealthServiceMocks = (baseURL) => [
  // http.get(`${baseURL}/api/v1/health/maintenance`, () => {
  //   return new HttpResponse(true, { status: 200 })
  // })
  // http.get(`${baseURL}/healthz/dependencies`, () => {
  //   return new HttpResponse(null, { status: 200 })
  // })
]

export default HealthServiceMocks
