import { findKey } from 'lodash'

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
}

export const isFirefox =
  navigator.userAgent.toLowerCase().indexOf('firefox') > -1

export const browserImageRotationSupport = () => {
  const imgTag = document.createElement('img')
  return imgTag.style.imageOrientation !== undefined
}

export const querySelectors = {
  FOCUSEABLE:
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]'
}
export const rangeTrackEls = [
  'webkit-slider-runnable-track',
  'moz-range-track',
  'ms-track'
]
export const filterEls = (htmlCollection, filterFunc) =>
  Array.prototype.filter.apply(htmlCollection, [filterFunc])
export const mapEls = (htmlCollection, mapFunc) =>
  Array.prototype.map.apply(htmlCollection, [mapFunc])

export const elArray = htmlCollection =>
  Array.prototype.map.apply(htmlCollection, [el => el])

export const currentBreakpoint = () =>
  findKey(
    breakpoints,
    bp => bp === breakpoints.xl || bp >= window.innerWidth
  )
