import { merge } from 'lodash'

const USER_KEY = 'disc_user'
const LOCALE_KEY = 'locale'
const PENDO_KEY = 'pendo_info'
const LOGROCKET_KEY = 'logrocket_info'
const DEMO_USER_KEY = 'demo_user'
const FEATURE_ANNOUNCEMENT_KEY = (feature) => `${feature}_announcement_viewed`

/**
 * Manage how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instance
 */

const StorageService = {
  getUser () {
    const user = JSON.parse(localStorage.getItem(USER_KEY))
    return user ?? null
  },

  getToken () {
    const user = this.getUser()
    return user?.tokens?.access_token ?? null
  },

  getTokenSet () {
    const user = this.getUser()
    return user?.tokens ?? null
  },

  setDemoUser ({ firstName, lastName, avatarUrl }) {
    localStorage.setItem(DEMO_USER_KEY, JSON.stringify(merge({
      firstName,
      lastName,
      avatarUrl
    })))
  },

  getDemoUser () {
    const demoUser = JSON.parse(localStorage.getItem(DEMO_USER_KEY))
    return demoUser ?? null
  },

  clearDemoUser () {
    localStorage.removeItem(DEMO_USER_KEY)
  },

  saveUser (user) {
    localStorage.setItem(USER_KEY, JSON.stringify(user))
  },

  clearUser () {
    localStorage.removeItem(USER_KEY)
    localStorage.removeItem(PENDO_KEY)
    localStorage.removeItem(LOGROCKET_KEY)
  },

  getLocale () {
    return localStorage.getItem(LOCALE_KEY)
  },

  setLocale (locale) {
    localStorage.setItem(LOCALE_KEY, locale)
  },

  getPendoInfo () {
    return JSON.parse(localStorage.getItem(PENDO_KEY))
  },

  setPendoInfo ({ identified, profileAdded, orgAdded, comparisonsAdded }) {
    localStorage.setItem(PENDO_KEY, JSON.stringify(merge(this.getPendoInfo(), {
      identified,
      profileAdded,
      orgAdded,
      comparisonsAdded
    })))
  },

  getLogRocketInfo () {
    return JSON.parse(localStorage.getItem(LOGROCKET_KEY))
  },

  setLogRocketInfo (newInfo) {
    localStorage.setItem(LOGROCKET_KEY, JSON.stringify(merge(this.getLogRocketInfo(), newInfo)))
  },

  setFeatureAnnoucementViewed (feature) {
    localStorage.setItem(FEATURE_ANNOUNCEMENT_KEY(feature), true)
  },

  getFeatureAnnoucementViewed (feature) {
    return localStorage.getItem(FEATURE_ANNOUNCEMENT_KEY(feature))
  },

  deleteFeatureAnnouncement (feature) {
    localStorage.removeItem(FEATURE_ANNOUNCEMENT_KEY(feature))
  }
}

export default StorageService
