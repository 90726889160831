<template>
  <div id="app-layout" class="app-layout">
    <div class="alert-wrapper" v-if="showAlerts">
      <browser-warning v-if="showBrowserWarning" />
    </div>
    <header v-if="$slots['header']" id="app-header" class="header header--app">
      <slot name="header" />
    </header>
    <main id="app-main" class="app__main" role="main" tabindex="-1">
      <slot name="main" />
    </main>
    <footer id="app-footer" class="footer footer--app">
      <slot name="footer" />
    </footer>
  </div>
</template>
<script>
import BrowserWarning from '../components/forms/BrowserWarning'

export default {
  name: 'app-layout-base',
  components: { BrowserWarning },
  data: () => ({}),
  props: {
    showAlerts: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    showBrowserWarning () {
      const ua = window.navigator.userAgent
      const isIE = ua.indexOf('MSIE ') >= 0 || ua.indexOf('Trident/') >= 0
      return this.$route.meta.browserWarning && isIE
    }
  }
}
</script>
<style lang="scss">
@import '../styles/base';

.app-layout {
  min-height: 100vh;
}

#app-main {
  height: 100%;
}

#app-header,
#app-main {
  background: $white;
}

.alert-wrapper {
  position: fixed;
  top: 0;
  z-index: 1050;
  width: 100%;

  & > div:not(:first-child) {
    border-top: 2px solid rgba($color:$white, $alpha: 0.5);
    margin-top: -2px;
  }
}

.osano-cm-dialog {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);
}

.osano-cm-link:hover {
  color: $blue
}

.osano-cm-toggle:hover {
  color: $blue
}

.osano-cm-toggle:hover {
  text-decoration: underline;
}

.osano-cm-widget {
  display: none;
}

.osano-cm-button {
  box-shadow: 0px 2px 2px 0.05rem rgba(0,0,0, 0.15);
  border: 0;
  padding: 15px 0;
}

.osano-cm-button--type_deny {
  border: 1pt solid rgb(243, 204, 35);
}

.osano-cm-toggle__input:disabled:checked + .osano-cm-toggle__switch {
  background-color: #545454 !important;
  border-color: #545454 !important;
}

</style>
