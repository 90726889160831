import TextService from './services/text'
import store from './store'

export const initOptions = {
  debug: false,
  partialBundledLanguages: true,
  backend: {
    loadPath: '{{lng}},{{ns}}',
    request: async (options, url, payload, callback) => {
      store.commit('app/prefetchStart')

      try {
        const [lng, ns] = url.split(',')

        const data = await TextService.process({
          context: ns,
          language: lng
        })

        callback(null, {
          data: data[0].texts,
          status: 200
        })
      } catch (e) {
        callback(null, {
          status: 500
        })
      }

      store.commit('app/prefetchEnd')
    }
  },
  defaultNS: 'common',
  // i18next plural resolver only supports langs with dashes
  // but we use underscores in our APIs, therefore
  // we have to define with dashes here and transform when
  // we do the API call. :{
  supportedLngs: [
    'en-US',
    'eo' // Esperanto is used as "Debug" language
  ],
  ns: ['common'],
  interpolation: {
    escapeValue: false
  },
  resources: {}
}
