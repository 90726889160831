import AssessmentService from '../../services/assessment'

const state = {
  pending: false,
  assessmentPage: undefined,
  answers: {},
  error: undefined
}

const getters = {
  page: state => state.assessmentPage?.page,
  progress: state => state.assessmentPage?.progress,
  pageQuestions: state => state.assessmentPage?.questionBlocks?.[0].questions,
  answers: state => state.answers
}

const actions = {
  async fetchPage ({ commit, rootGetters }) {
    commit('fetchPageRequest')
    try {
      const accessCode = rootGetters['account/accessCode']
      const assessmentPage = await AssessmentService.page(accessCode)

      commit('fetchPageSuccess', assessmentPage)
      return assessmentPage.page
    } catch (error) {
      commit('fetchPageError', {
        errorCode: error.errorCode,
        errorMessage: error.message
      })
      return false
    }
  },
  async savePageAnswers ({ state, commit, rootGetters }) {
    commit('savePageAnswersRequest')
    try {
      const accessCode = rootGetters['account/accessCode']
      const answers = Object.keys(state.answers).map(questionId => ({
        id: questionId,
        value: state.answers[questionId]
      }))

      const nextAssessmentPage = await AssessmentService.answer(accessCode, answers)

      commit('savePageAnswersSuccess', nextAssessmentPage)
      return nextAssessmentPage.page
    } catch (error) {
      commit('savePageAnswersError', {
        errorCode: error.errorCode,
        errorMessage: error.message
      })
      return false
    }
  }
}

const mutations = {
  fetchPageRequest (state) {
    state.pending = true
    state.error = undefined
    state.assessmentPage = undefined
    state.answers = {}
  },
  fetchPageSuccess (state, assessmentPage) {
    state.pending = false
    state.assessmentPage = assessmentPage
  },
  fetchPageError (state, { errorCode, errorMessage }) {
    state.pending = false
    state.error = { errorCode, errorMessage }
  },
  savePageAnswersRequest (state) {
    state.pending = true
    state.error = undefined
  },
  savePageAnswersSuccess (state, assessmentPage) {
    state.pending = false
    state.answers = {}
    state.assessmentPage = assessmentPage
  },
  savePageAnswersError (state, { errorMessage, errorCode }) {
    state.pending = false
    state.error = { errorMessage, errorCode }
  },
  answerQuestion (state, { questionId, answer }) {
    state.answers = {
      ...state.answers,
      [questionId]: answer
    }
  },
  clearAnswers (state) {
    state.answers = {}
  }
}

export const newAssessment = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
