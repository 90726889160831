// import { http, HttpResponse } from 'msw'

const DemographicsServiceMocks = (baseURL) => [
  // http.get(`${baseURL}/demographics/example-mock`, () => {
  //   return new HttpResponse.json({
  //     exampleProperty: 'This was a successful mock!'
  //   })
  // })
]

export default DemographicsServiceMocks
