// import store from '../../store'

// const groupGetResponseMock = () => {
//   const currentLearner = store.getters['account/currentUserProfile']

//   return {
//     groupID: 1234567654321,
//     created: '2019-08-24T14:15:22Z',
//     createdBy: 927332,
//     updated: '2019-08-24T14:15:22Z',
//     updatedBy: 927332,
//     name: 'My Test Group',
//     description: 'My Description',
//     context: 'Catalyst',
//     type: 1,
//     category1: 1,
//     category2: 1,
//     status: 'Active',
//     visibility: 'AllMembers',
//     members: [
//       {
//         groupID: 1234567654321,
//         groupMemberID: 101,
//         created: '2019-08-24T14:15:22Z',
//         createdBy: 927332,
//         updated: '2019-08-24T14:15:22Z',
//         updatedBy: 927332,
//         memberType: 'Member',
//         owner: false,
//         personalizedFlags:
//         {
//           favorite: false,
//           hidden: true
//         },
//         learner: { ...currentLearner }
//       },
//       {
//         groupID: 230,
//         groupMemberID: 102,
//         created: '2019-08-24T14:15:22Z',
//         createdBy: 927332,
//         updated: '2019-08-24T14:15:22Z',
//         updatedBy: 927332,
//         memberType: 'Member',
//         owner: false,
//         personalizedFlags:
//         {
//           favorite: false,
//           hidden: false
//         },
//         learner: {
//           accountID: 102,
//           firstName: 'Maria',
//           lastName: 'Jones',
//           email: 'test@test.com',
//           dataConsentDT: '2019-08-24T14:15:22Z',
//           location: 'Minneapolis',
//           department: 'Technology',
//           title: 'string',
//           publicProfileSearch: true,
//           companyProfileSearch: true,
//           privacyAccepted: true,
//           avatarFileName: 'image001.png',
//           avatarUrl: 'https://placepengu.in/200x200/?2',
//           pathways: [
//             {
//               name: 'Management',
//               route: '/Management',
//               order: 1,
//               pages: [
//                 {
//                   route: '/Management',
//                   order: 1
//                 }
//               ],
//               textCode: 'Introduction'
//             }
//           ],
//           style: {
//             style: 'CD',
//             angle: 250,
//             vector: 0.731,
//             extras: [
//               {
//                 category: 71,
//                 style: 'D'
//               },
//               {
//                 category: 114,
//                 style: 'S'
//               }
//             ],
//             efforts: [
//               {
//                 productCategoryID: 114,
//                 style: 'S',
//                 value: 28.4,
//                 angle: 171
//               }
//             ]
//           },
//           peopleCard: [
//             {
//               code: 'SC_hc_02'
//             }
//           ],
//           language: {
//             id: 1,
//             name: 'English',
//             isoCode: 'en_us'
//           },
//           assessments: [
//             {
//               isComplete: true,
//               accessCode: 'ABCDE12345',
//               name: 'Catalyst: Everything DiSC',
//               completedDT: '2019-08-24T14:15:22Z',
//               assignedDT: '2019-08-24T14:15:22Z',
//               questionsAnswered: 140,
//               questionsMax: 410,
//               partner: {
//                 accountID: 101,
//                 companyName: 'My Company',
//                 contactLine1: 'Attn: Person',
//                 contactLine2: '123 Main St.',
//                 contactLine3: 'Unit E',
//                 contactLine4: 'City State Zip',
//                 contactLine5: 'ContactEmail@something.com',
//                 url: 'http://partner.com'
//               },
//               admin: {
//                 accountID: 101,
//                 distributorID: 765334,
//                 logo: 'https://images/4447334',
//                 companyName: 'ClientName',
//                 companyInfo1: 'Ex: Contact Name',
//                 companyInfo2: 'Ex: Company Name',
//                 companyInfo3: 'Ex: Company Address line 1',
//                 companyInfo4: 'Ex: Company Address line 2',
//                 companyInfo5: 'Ex: Contact Phone',
//                 companyInfo6: 'Ex: Contact Website',
//                 companyInfo7: 'Ex: Contact Email',
//                 url: 'http://clienturl',
//                 assigneeEmail: 'user@test.com',
//                 skipDemographics: true
//               },
//               fromProduct: 1,
//               productCategoryID: 114,
//               reportOptions: [
//                 {
//                   reportID: 12,
//                   assignDT: '2019-08-24T14:15:22Z'
//                 }
//               ]
//             }
//           ],
//           organizationID: 80002342,
//           departmentID: 173,
//           locationID: 111,
//           countryID: 90001,
//           stateID: 728,
//           regionID: 728,
//           partner: {
//             accountID: 101,
//             companyName: 'My Company',
//             contactLine1: 'Attn: Person',
//             contactLine2: '123 Main St.',
//             contactLine3: 'Unit E',
//             contactLine4: 'City State Zip',
//             contactLine5: 'ContactEmail@something.com',
//             url: 'http://partner.com'
//           },
//           continua: [
//             {
//               scaleName: 'Patient_Driven',
//               value: 23
//             }
//           ],
//           admin: {
//             accountID: 101,
//             distributorID: 765334,
//             logo: 'https://images/4447334',
//             companyName: 'ClientName',
//             companyInfo1: 'Ex: Contact Name',
//             companyInfo2: 'Ex: Company Name',
//             companyInfo3: 'Ex: Company Address line 1',
//             companyInfo4: 'Ex: Company Address line 2',
//             companyInfo5: 'Ex: Contact Phone',
//             companyInfo6: 'Ex: Contact Website',
//             companyInfo7: 'Ex: Contact Email',
//             url: 'http://clienturl',
//             assigneeEmail: 'user@test.com',
//             skipDemographics: true
//           },
//           hasCatalystData: true,
//           userRoles: [
//             {
//               userRole: {
//                 userRoleID: 1,
//                 userID: 1,
//                 roleTypeID: 1,
//                 created: '2019-08-24T14:15:22Z'
//               },
//               roleType: {
//                 roleTypeID: 1,
//                 brandID: 1,
//                 roleName: 'Catalyst Learner'
//               },
//               userRoleAccess: [
//                 {
//                   userRoleID: 1,
//                   accessTypeID: 1,
//                   accessRefID: 802,
//                   grantedBy: 101,
//                   created: '2019-08-24T14:15:22Z'
//                 }
//               ]
//             }
//           ],
//           catalystLastLoginDT: '2019-08-24T14:15:22Z'
//         }
//       }
//     ]
//   }
// }

/* export const groupGetSearchResponseMock = () => {
  return {
    groupID: 1234567654321,
    name: 'My Test Group',
    updated: '2019-08-24T14:15:22Z',
    category1: 1,
    category2: 1,
    memberCount: 2,
    details: {
      ...groupGetResponseMock()
    }
  }
} */

const GroupsServiceMocks = (baseURL) => [
  /*  http.get(`${baseURL}/api/v1/groups/1234567654321?include=members`, () => {
    return new HttpResponse(
      groupGetResponseMock()
    )
  }),
  http.put(`${baseURL}/api/v2/groups/:groupId/members/:memberId/personalizedFlags`, () => {
    return new HttpResponse({
        favorite: true, hidden: false
      })
  }) */
]

export default GroupsServiceMocks
