<template>
  <div class="nav" id="nav-menu">
    <div class="nav__entries">
      <ul class="nav__pathways">
        <li
          v-for="link in items.pathways.links"
          :key="link.label"
          class="nav__pathways-link"
        >
          <router-link
            @click.native="closeNavMenu()"
            :to="link.value"
            :id="`nav--pathways__link--${noSpaces(link.label)}`"
          >
            {{ $tt(link.label)}}
          </router-link>
        </li>
        <li class="nav__pathways-link" v-if="items.getAdvice">
          <router-link
            @click.native="closeNavMenu()"
            :to="items.getAdvice.value"
            :id="`nav--links__link--${noSpaces(items.getAdvice.label)}`"
          >
            {{ $tt(items.getAdvice.label)}}
          </router-link>
        </li>
        <li class="nav__pathways-link nav__pathways-link--with-badge" v-if="items.projectCheetah">
          <a
            @click.native="closeNavMenu()"
            :href="items.projectCheetah.value"
            :id="`nav--links__link--${noSpaces(items.projectCheetah.label)}`"
          >
            {{ $tt(items.projectCheetah.label)}}
          </a>
          <badge badgeText="PLUS" />
        </li>
      </ul>

      <ul class="nav__pathways">
        <li
          v-for="tab in items.pathways.tabs"
          :key="tab.label"
          :class="['nav__pathways-link', { 'nav__pathways-link--active': isActivePanel(tab.label) }]"
        >
          <a
            :href="`#${tab.label}`"
            :id="`nav--pathways__link--${noSpaces(tab.label)}`"
            v-html="$tt(tab.label)"
            @click.prevent='setActivePanel(tab.label)'
          />

          <b-collapse class="nav__pathways-link__collapse" :visible="isActivePanel(tab.label) && mobile">
            <nav-modules :pathway="tab" @closeNavMenu="closeNavMenu()" />
          </b-collapse>
        </li>
      </ul>

      <ul class="nav__links">
        <li class="nav__links-link" v-if="items.facilitator && isFacilitator">
          <router-link
            @click.native="closeNavMenu()"
            :to="items.facilitator.value"
            :id="`nav--links__link--${noSpaces(items.facilitator.label)}`"
          />
            {{$tt(items.facilitator.label)}}
          <router-link/>
        </li>
        <li class="nav__links-link" v-if="items.reports">
          <router-link
            @click.native="closeNavMenu()"
            :to="items.reports.value"
            :id="`nav--links__link--${noSpaces(items.reports.label)}`"
          >
            {{ $tt(items.reports.label)}}
          </router-link>
          <router-link
            v-if="isDemoAccount"
            @click.native="closeNavMenu()"
            :to="{ name:'logout' }"
            id="nav-greeting__logout"
            class="nav__links-logout"
          >
            {{ $tt('account.signout')}}
          </router-link>
        </li>
        <li class="nav__links-link" v-if="items.settings && !isDemoAccount && !isDemoUser">
          <router-link
            @click.native="closeNavMenu()"
            :to="items.settings.value"
            :id="`nav--links__link--${noSpaces(items.settings.label)}`"
          >
          {{ $tt(items.settings.label) }}
          </router-link>
          <router-link
            @click.native="closeNavMenu()"
            :to="{ name:'logout' }"
            id="nav-greeting__logout"
            class="nav__links-logout"
          >
            {{ $tt('account.signout')}}
          </router-link>
        </li>
      </ul>

      <ul class="nav__footer">
        <li>
          <a id="termsHeader"
              href="/Terms"
              target="_blank">
            {{ $tt('ui.terms')}}
          </a>
        </li>
        <li>
          <a id="privacyPolicyHeader"
              href="/PrivacyPolicy"
              target="_blank">
            {{ $tt('ui.privacy') }}
          </a>
        </li>
      </ul>
    </div>

    <!-- right screen menu content -->
    <div class="nav__content" v-if="!mobile">
      <div id="nav--content__default" v-if="!activePanel">
        <hero-tagline
          class="nav-hero"
          variant="header"
        />
      </div>

      <div
        v-for="tab in activeTabs"
        :key="tab.label"
        :id="`nav--content__${tab.label}`"
      >
        <nav-modules :pathway="tab" @closeNavMenu="closeNavMenu()" />
      </div>
    </div>
  </div>
</template>

<script>
import { vueWindowSizeMixin } from 'vue-window-size'
import { breakpoints } from '../../util/dom'
import { noSpaces } from '../../util/string'
import Badge from '../badge/Badge.vue'
import HeroTagline from '../logo/HeroTagline'
import NavModules from './NavModules.vue'

export default {
  name: 'nav-menu',
  components: { HeroTagline, NavModules, Badge },
  mixins: [vueWindowSizeMixin],
  data: () => ({
    mobileBreakpoint: breakpoints.md,
    activePanel: null
  }),
  props: {

    /**
     * array of objects that the component will display
     */
    items: {
      type: Object,
      default: () => ({})
    },

    /**
     * Indicates whether the default logo should be shown instead of a submenu.
     * Acts as a reset for the menu when we open/close.
     */
    isMenuOpen: {
      type: Boolean,
      default: true
    },

    /**
     * Hides settings info for the demo experience
     * Mostly won't be true but defaulting to that for safety
     */
    isDemoAccount: {
      type: Boolean,
      default: true
    },

    isDemoUser: {
      type: Boolean,
      default: true
    },

    /**
     * Boolean we use to change header logo
     * if user viewing as Facilitator role
     */
    isFacilitator: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    noSpaces,

    setActivePanel (panel) {
      this.activePanel = (this.activePanel !== panel) ? panel : null
    },

    isActivePanel (panel) {
      return (this.activePanel === panel)
    },

    closeNavMenu () {
      return this.$emit('closeNavMenu')
    }
  },
  computed: {
    mobile () {
      return this.windowWidth < this.mobileBreakpoint
    },
    activeTabs () {
      return this.items.pathways.tabs.filter(t => t.label === this.activePanel)
    }
  },
  watch: {
    isMenuOpen (to) {
      if (to) {
        this.setActivePanel(null)
      }
    }
  }
}
</script>

<style lang="scss">
@import "../../styles/base";

#nav-main-pathways {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100%;
  padding-top: 5rem;

  @include media-breakpoint-down('sm') {
    padding-top: 4.375rem;
  }

  .nav {
    background: $white;
    height: 100%;
    width: 100%;
    max-width: 1200px;
    display: grid;
    grid-template-columns: 33% 1fr;
    grid-template-rows: 1fr;
    padding: 2rem;
    padding-right: 0;
    overflow: hidden;
    ul {
      margin-bottom: 0;
      list-style: none;
      li {
        margin-left: 0;
      }
    }
    &__entries {
      padding-right: 2rem;

      @include media-breakpoint-up('md') {
        overflow: auto;
      }
    }

    &__pathways,
    &__links {
      display: flex;
      flex-direction: column;

      &-link {
        &--active {
          > a:first-child {
            font-weight: 700;

            &::after {
              width: 100%;
            }
          }
        }
        &--with-badge {
          .badge {
            vertical-align: top;
          }
        }

        > a:first-child {
          position: relative;
          display: inline-block;
          color: $dark;
          line-height: normal;
          transition: all 0.1s ease;
          padding-bottom: 0.5rem;

          &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            width: 0;
            height: 0.25rem;
            border-radius: 0.125rem;
            background-color: $brand;
            transition:
              width 0.25s cubic-bezier(
                0.445,
                0.05,
                0.55,
                0.95
              );
          }

          &:hover {
            text-decoration: none;
            font-weight: 700;

            &::after {
              width: 100%;
            }
          }
        }
      }
    }

    &__pathways {
      &-link {
        margin-bottom: 1.5rem;

        > a:first-child {
          font-size: 1.875rem;
        }

        &__collapse {
          padding-top: 1rem;
          margin-bottom: 0.5rem;
          visibility: hidden;
        }
      }
    }

    &__links {
      &-link {
        padding-top: 1.5rem;
        padding-bottom: 1rem;
        border-top: 0.125rem solid $gray-100;

        &:last-of-type {
          border-bottom: 0.125rem solid $gray-100;
        }

        > a:first-child {
          font-size: 1.25rem;
        }
      }

      &-logout {
        display: block;
        color: $dark;
        font-size: 0.875rem;
        margin-top: 1.2rem;
        margin-bottom: 0.55rem;

        &::after {
          content: none;
        }

        &:hover {
          text-decoration: underline;
        }

        @include media-breakpoint-up('md') {
          display: none;
        }
      }
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      margin-top: 1.5rem;
      max-width: 350px;
      a {
        color: $dark;
        font-size: 0.875rem;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      padding-left: 2rem;
      border-left: 0.25rem solid $gray-100;
      overflow: hidden;

      > div {
        flex: 1;
        padding-right: 2rem;
        overflow-x: hidden;
      }
    }

    @include media-breakpoint-down('sm') {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      padding: 0;
      overflow: scroll;

      &__entries {
        padding: 2rem 1rem;
      }

      &__pathways {
        &-link {
          &__collapse {
            visibility: visible;
          }
        }
      }

      &__content {
        display: none;
      }
    }
  }
}
</style>
