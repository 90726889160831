<template>
  <div class="app__footer">
    <div class="footer--app__catalyst">
      <div class="footer--app__content">
        <div class="footer--app__title logo logo--company">
          <img
            class="footer--app__catalyst--logo"
            alt="Catalyst"
            src="/images/logo-catalyst-white.svg"
          />
        </div>
        <div class="footer--app__disclaimer">
          {{ $tt("ui.copyright") }} {{ $tt("ui.reserved") }}
        </div>
        <div class="footer--app__graphics">
          <div>{{ $tt("ui.graphicsUsed") }}</div>
          <div>{{ $tt("ui.graphicsUsedList") }}</div>
        </div>
      </div>
    </div>
    <div class="footer--app__tech-support">
      <div class="footer--app__content">
        <div class="footer--app__title">
          <h3>{{ $tt("ui.needHelp") }}</h3>
        </div>
        <nav class="footer--app__links">
          <label class="footer-title">{{ $tt("ui.footerTechSupportHoursTitle") }}</label>
          <div class="collapsible">
            <p class="tech--support">
              {{ $tt("ui.techSupportHours") }}
            </p>
          </div>
          <label class="footer-title">{{ $tt("ui.footerEmailTitle") }}</label>
          <div class="collapsible">
            <a
            id="needHelpFooter"
            class="link link--footer tech--support--link"
            :href="`mailto:${$tt('ui.needHelpEmail')}`"
            >{{ $tt("ui.needHelpEmail") }}</a
          >
          </div>
          <label class="footer-title">{{ $tt("ui.footerPhoneTitle") }}</label>
          <ul>
            <li class="collapsible">
              <p class="footer--app__title--small">
                {{ $tt("ui.region.americas.name") }}
              </p>
              <a
                id="regionAmericasFooter"
                class="link link--footer link--tel"
                :href="`tel:${$tt('ui.region.americas.number')}`"
                >{{ $tt("ui.region.americas.number") }}</a
              >
            </li>
            <li class="collapsible">
              <p class="footer--app__title--small">
                {{ $tt("ui.region.europe.name") }}
              </p>
              <a
                id="regionEuropeFooter"
                class="link link--footer link--tel"
                :href="`tel:${$tt('ui.region.europe.number')}`"
                >{{ $tt("ui.region.europe.number") }}</a
              >
            </li>
            <li class="collapsible">
              <p class="footer--app__title--small">
                {{ $tt("ui.region.asia.name") }}
              </p>
              <a
                id="regionAsiaFooter"
                class="link link--footer link--tel"
                :href="`tel:${$tt('ui.region.asia.number')}`"
                >{{ $tt("ui.region.asia.number") }}</a
              >
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="footer--app__more-information">
      <div class="footer--app__content">
        <div class="footer--app__title">
          <h3>{{ $tt("ui.moreInformation") }}</h3>
        </div>
        <nav class="footer--app__links">
          <ul>
            <li>
              <a tabindex=0
                id="manageCookiesFooter"
                class="link link--footer"
                v-on:click="showCookies"
                @focus="manageFocused"
                @blur="manageBlurred"
                @keydown.enter="showCookiesKeyboard"
              >
                {{ $tt("ui.manageCookies") }}
              </a>
            </li>
            <li>
              <a
                id="termsFooter"
                class="link link--footer"
                href="/Terms"
                target="_blank"
              >
                {{ $tt("ui.terms") }}
              </a>
            </li>
            <li>
              <a
                id="privacyPolicyFooter"
                class="link link--footer"
                href="/PrivacyPolicy"
                target="_blank"
              >
                {{ $tt("ui.privacy") }}
              </a>
            </li>
            <li>
              <a
                id="accessibilityStatementFooter"
                class="link link--footer"
                href="/accessibility-statement"
                target="_blank"
              >
                {{ $tt("ui.a11y") }}
              </a>
            </li>
          </ul>
        </nav>
        <div class="footer--app__post" v-if="loggedIn && assigneeEmail">
          <div class="footer--app__title title--catalyst">
            <h3>{{ $tt("ui.otherQuestions") }}</h3>
          </div>
          <nav class="footer--app__links">
            <a
              id="assigneeLinkFooter"
              :href="`mailto:${assigneeEmail}`"
              class="link link--footer link--email"
              v-html="assigneeEmail"
            />
          </nav>
        </div>
      </div>
    </div>
    <div class="footer--app__wiley">
      <img
        class="footer--app__wiley--logo"
        alt="Wiley"
        src="/images/Wiley_Wordmark_white.svg"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'app-footer',
  data () {
    return {
      manageCookieFocused: false
    }
  },
  props: {
    assigneeEmail: {
      type: String,
      default: ''
    },
    loggedIn: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    showCookies () {
      Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')
    },
    manageFocused: function () {
      this.manageCookieFocused = true
    },
    manageBlurred: function () {
      this.manageCookieFocused = false
    },
    showCookiesKeyboard (e) {
      if (this.manageCookieFocused) {
        setTimeout( // Prevents dialog from immediately closing when opened with keyboard
          function () {
            Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')
          }, 50)
      }
    }
  }
}
</script>
<style lang="scss">
@import "../styles/base";
.app__footer {
  position: relative;
  display: grid;
  background-color: $primary;
  color: $white;
  padding: 1rem 1rem 0;
  z-index: $zindex-fixed - 1; // don't want to go over header
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, min-content);
  grid-template-areas:
    "more-information"
    "tech-support"
    "catalyst"
    "wiley";

  @include media-breakpoint-up("md") {
    column-gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, min-content);
    grid-template-areas:
      "catalyst tech-support more-information"
      "wiley     wiley       wiley";
  }

  @include media-breakpoint-up("lg") {
    padding: 1rem 4rem 0;
    column-gap: 8rem;
  }

  .footer--app {
    &__content {
      max-width: 17rem;
      font-size: 0.875rem;

      .footer-title {
        font-size: 1rem;
      }
    }
    &__more-information {
      grid-area: more-information;
      padding-bottom: 0.5rem;
    }
    &__tech-support {
      grid-area: tech-support;
      border-bottom: 1px solid adjust-color($color: #f2f2f2, $alpha: -0.75);
      border-top: 1px solid adjust-color($color: #f2f2f2, $alpha: -0.75);
      padding-bottom: 1rem;
      @include media-breakpoint-up("md") {
        border: none;
      }
    }
    &__catalyst {
      grid-area: catalyst;
      margin-bottom: 3rem;
      &--logo {
        margin: 2rem 0 1.5rem;
        max-width: 7rem;
      }
    }
    &__wiley {
      border-top: 1px solid adjust-color($color: #f2f2f2, $alpha: -0.75);
      grid-area: wiley;

      @include media-breakpoint-up("md") {
        text-align: center;
      }

      &--logo {
        margin: 2rem 0;
        max-width: 7rem;
      }
    }
    &__catalyst,
    &__more-information,
    &__tech-support {
      @include media-breakpoint-up("md") {
        padding-bottom: 0;
        margin-bottom: 3rem;
      }
    }
    &__links {
      ul {
        list-style: none;
        li {
          margin-left: 0;
        }
      }
      .collapsible {
        @include media-breakpoint-up("xl") {
          display: flex;
          justify-content: space-between;
        }
      }
      .link--footer {
        color: color-rules-blue($lightBg: false, $variant: "primary");
        display: block;
        margin: 1.5rem 0;
        &:hover {
          color: color-rules-blue($lightBg: false, $variant: "hover");
        }
        &:last-child {
          @include media-breakpoint-up("md") {
            margin-bottom: 0;
          }
        }
      }
      .link--tel {
        margin-top: 0;
        @include media-breakpoint-up("lg") {
          margin: 0;
        }
      }
      .link--email {
        margin: 1.5rem 0;
      }
      .tech--support {
        margin: 0;
        padding-bottom: 1.5rem;
        color: $white;
      }
      .tech--support--link {
        margin: 0;
        padding-bottom: 1.5rem;
      }
    }
    &__disclaimer,
    &__graphics {
      font-weight: lighter;
    }
    &__disclaimer {
      margin-bottom: 1rem;
    }
    &__title {
      &.title--catalyst {
        margin-top: 3rem;
      }
      h3,
      &--small {
        color: $white;
        font-weight: lighter;
      }

      h3 {
        margin: 2rem 0 1.5rem;
        font-size: $font-size-xmd;
      }
      &--small {
        margin: 1rem 0 0;
        @include media-breakpoint-up("lg") {
          margin: 0;
        }
      }
    }
  }
}
#manageCookiesFooter {
  cursor: pointer;
}
</style>
