export const questionEvents = {
  UP: 'up',
  DOWN: 'down',
  PREV: 'prev',
  NEXT: 'next',
  GOTO: 'goto',
  CONFIRM: 'confirm',
  SUBMIT: 'submit',
  REVIEW: 'review',
  REVEAL: 'reveal',
  FIX: 'fix'
}

export const questionOptionNavKeys = [
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown'
]

export const questionEventVariants = {
  DEFAULT: 'confirm',
  review: 'outline-confirm',
  fix: 'danger'
}

export const questionPanels = {
  STATIC: 'question-panel-static',
  REVIEW: 'question-panel-review',
  REVEAL: 'question-panel-style-reveal',
  INPUT: 'question-panel-input',
  AVATAR: 'question-panel-avatar',
  CONTINUA: 'question-panel-continua',
  DEFAULT: 'question-input-base',
  BUTTONS: 'question-panel-button-group',
  CHECKBOX: 'question-panel-checkbox',
  SINGLE_SELECT: 'question-panel-single-select',
  LIKERT: 'question-panel-likert',
  MULTI_SELECT: 'question-panel-multi-select'
}

export const questionFormats = {
  LIKERT: 'likert',
  EQLIKERT: 'eqlikert',
  CONTINUA: 'continua',
  INFO: 'info',
  REVIEW: 'review',
  REVEAL: 'reveal',
  SINGLE_SELECT: 'multiple_choice',
  MULTI_SELECT: 'multi_select'
}

export const panelsByFormat = {
  [questionFormats.LIKERT]: questionPanels.LIKERT,
  [questionFormats.CONTINUA]: questionPanels.CONTINUA,
  [questionFormats.STATIC]: questionPanels.STATIC,
  [questionFormats.REVIEW]: questionPanels.REVIEW,
  [questionFormats.REVEAL]: questionPanels.REVEAL,
  [questionFormats.SINGLE_SELECT]: questionPanels.SINGLE_SELECT,
  [questionFormats.MULTI_SELECT]: questionPanels.MULTI_SELECT
}

export const questionSelectors = {
  PANEL_ID_PREFIX: '__question__',
  PANEL_INPUT_ID_PREFIX: '__question__input__',
  PANEL_CLASS: '.question'
}

const e = questionEvents

export const questionEventsDefault = [
  e.UP,
  e.DOWN,
  e.PREV,
  e.NEXT,
  e.GOTO
]

export const questionEventsDirty = [
  e.CONFIRM
]

export const questionEventsValid = [
  e.SUBMIT,
  e.REVIEW,
  e.REVEAL
]

export const questionEventsInvalid = [
  e.FIX
]
