import { ExtendableError } from '../../util/error'
import ApiService from '../api'

class AssessmentError extends ExtendableError {
  constructor (errorCode, message) {
    super(message)
    this.name = 'AssessmentError'
    this.message = message
    this.errorCode = errorCode
  }
}

const AssessmentService = {

  /**
   * Fetch the results or lack thereof from an assessment by accessCode
   * @param {String} accessCode - identifier of current assessment and learner
   * @returns {Promise<AssessmentDetail>} - model of the current assessment including,
   * questions, progress metrics and text.
   */
  async detail (accessCode) {
    try {
      const assessment = await ApiService.customRequest({
        method: 'GET',
        url: `/assessment/${accessCode}`
      })
      return assessment.data
    } catch (error) {
      throw new AssessmentError(error.response.status, error.response.data.message)
    }
  },

  /**
   * Fetch the pdf report version of an assessments results by accessCode
   * @param {String} accessCode - identifier of current assessment and learner
   * @param {Array} reportOptions - optional sections to generate
   * @returns {Promise<{reportName:String, reportUrl:String }>} - Name and link to downloadable pdf,
   * questions, progress metrics and text.
   */
  async report (accessCode, reportOptions) {
    try {
      const url = `/assessment/pdf/${accessCode}` + (reportOptions ? `?reportOptions=${reportOptions.join(',')}` : '')
      const assessment = await ApiService.customRequest({
        method: 'GET',
        url
      })
      return assessment.data
    } catch (error) {
      throw new AssessmentError(error.response.status, error.response.data.message)
    }
  },

  async reportUpload (reportUrl) {
    try {
      await ApiService.customRequest({
        method: 'GET',
        url: reportUrl,
        ignoredInterceptors: [404],
        withCredentials: false,
        transformRequest: (data, headers) => {
          delete headers.common.Authorization
          headers.get.Range = 'bytes=0-0'
          return data
        }
      }, '', '')
    } catch (error) {
      throw new AssessmentError(error.response.status, error.response.data.message)
    }
  },

  /**
   * Fetch the current assessment page values
   * @param {String} accessCode - identifier of current assessment and learner
   * @returns {Promise<Assessment>} - model of the current assessment including,
   * questions, progress metrics and text.
   */
  async page (accessCode) {
    try {
      const requestData = {
        method: 'GET',
        url: `/assessment/page/${accessCode}`
      }

      const currentPage = await ApiService.customRequest(requestData)
      return currentPage.data
    } catch (error) {
      if (error.response) {
        throw new AssessmentError(error.response.status, error.response.data.message)
      } else {
        throw new AssessmentError(503, 'Service Unavailable')
      }
    }
  },

  /**
   * Save a page of answers to recieve the following assessment page
   * @param {String} accessCode - assessment code to associate answers to
   * @param {Array[{id:Number, value:Number}]} answers - Assessment question answers with question id and user input value
   * @returns {Promise<Assessment>} - model for the next page including questions, progress metrics and text
   */
  async answer (accessCode, answers = []) {
    try {
      const postData = {
        method: 'POST',
        url: '/assessment/save',
        data: {
          accessCode,
          answers
        }
      }

      const nextPage = await ApiService.customRequest(postData)
      return nextPage.data
    } catch (error) {
      if (error.response) {
        throw new AssessmentError(error.response.status, error.response.data.message)
      } else {
        throw new AssessmentError(503, 'Service Unavailable')
      }
    }
  }
}

export default AssessmentService
export { AssessmentError, AssessmentService }
