/**
 * Returns a promise that waits for the getCurrentValue function result to equal
 * the expectedValue. If the expectedValue is undefined, the promise waits for the
 * getCurrentValue result to be truthy.
 * @param {function} getCurrentValue Function that should return the expected value
 * @param {any} expectedValue Value to expect
 * @param {any} fallbackValue Value returned if timeout is reached and the condition was not met
 * @param {number} timeout Maximum time to wait
 */
const waitForValue = (
  getCurrentValue,
  expectedValue,
  fallbackValue,
  timeout = 5000
) => new Promise((resolve) => {
  const currentValue = getCurrentValue()

  const shouldResolve = (currentValue) => {
    return expectedValue === undefined
      ? !!currentValue
      : currentValue === expectedValue
  }

  if (shouldResolve(currentValue)) {
    resolve(currentValue)
  } else {
    const timeoutId = setTimeout(() => {
      resolve(fallbackValue)
    }, timeout)

    const unwatch = window.$vm.$watch(
      () => getCurrentValue(),
      (value) => {
        if (shouldResolve(value)) {
          clearTimeout(timeoutId)
          unwatch()
          resolve(value)
        }
      }
    )
  }
})

export default waitForValue
