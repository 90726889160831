import { withHandlers } from 'vue-compose'
import {
  compose,
  mapGettersToProps,
  mapMutationsToHandlers,
  mapStateToProps
} from 'vuex-compose'
import App from '../app/App.vue'
// import store from '../store'

// let refreshMaintenanceFlag = true

// NOTE: seeing timeouts on the API hitting redis for this right now
// going to explore other options for handling it better on the back-end
// and bring this back when that's done

// function watchMaintenanceFlag () {
//   setTimeout(async () => {
//     await store.dispatch('app/refreshMaintenanceMode')

//     if (refreshMaintenanceFlag) {
//       watchMaintenanceFlag()
//     }
//   }, 30000)
// }

const wrapper = compose(
  // withHooks({
  //   created () {
  //     watchMaintenanceFlag()
  //   },
  //   destroyed () {
  //     refreshMaintenanceFlag = false
  //   }
  // }),
  withHandlers({
    goToGroups () {
      this.$router.push({
        name: 'groups',
        hash: '#create'
      }, () => {})
      // push(route, onComplete)
      // Modifies the onComplete to a noop
      // so that we suppress the NavigationRedirect
      // that can come out of this, since it is expected.
    }
  }),
  mapStateToProps('app', {
    alert: 'alert',
    maintenanceMode: 'maintenanceMode'
  }),
  mapGettersToProps({
    showSsoAlert: 'account/showSsoAlert',
    prefetching: 'app/isPrefetching'
  }),
  mapMutationsToHandlers({
    dismiss: 'app/clearAlert'
  })
)

export default wrapper(App)
