<template>
  <app-layout-base class="app-layout--minimal" :showAlerts="showAlerts">
    <template #header>
      <logo-brand-mark />
    </template>
    <template #main>
      <slot />
    </template>
    <template #footer>
      <app-footer :assigneeEmail="assigneeEmail" :loggedIn="loggedIn" />
    </template>
  </app-layout-base>
</template>

<script>
import AppLayoutBase from './AppLayoutBase'
import LogoBrandMark from '../components/logo/LogoBrandMark'
import AppFooter from './AppFooter'
import { appLayouts } from './AppConstants'

/**
 * @module AppLayoutMinimal
 * @description AppLayoutMinimal is an open template for transactional pages a loggedOut user may interact with such as reset password. This template centers content beneath a minimal header.
 */
export default {
  name: appLayouts.MINIMAL,
  components: {
    AppLayoutBase,
    LogoBrandMark,
    AppFooter
  },
  props: {
    assigneeEmail: {
      type: String,
      default: ''
    },
    showAlerts: {
      type: Boolean,
      default: true
    },
    loggedIn: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
@import '../styles/base';

.app-layout--minimal {
  // padding-top: 115px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  #app-header {
    flex-shrink: 1;
    margin-bottom: space(4);
  }

  .logo-brand-mark {
    margin-left: 3rem;
    margin-top: 3rem;
  }

  #app-main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-basis: 100%;
  }
}
</style>
