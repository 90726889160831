// import { http, HttpResponse } from 'msw'

const UserServiceMocks = (baseURL) => [
  // http.get(`${baseURL}/user/example-mock`, () => {
  //   return new HttpResponse({
  //     exampleProperty: 'This was a successful mock!'
  //   })
  // })
]

export default UserServiceMocks
