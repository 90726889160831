import router from '../../router'

const errorHandlers = {
  handle503Error: () => {
    const query = {}
    if (router.pendingRoute) {
      if (router.pendingRoute.name === 'serviceUnavailable') return
      query.retry = router.pendingRoute.fullPath
    }

    // use window.location here to avoid router cancellation errors
    return window.location.replace(router.resolve({ name: 'serviceUnavailable', query }).href)
  },
  handle404Error: () => {
    const query = {}
    if (router.pendingRoute) {
      if (router.pendingRoute.name === 'notFound') return
      query.retry = router.pendingRoute.fullPath
    }

    // use window.location here to avoid router cancellation errors
    return window.location.replace(router.resolve({ name: 'notFound', query }).href)
  }
}

export default errorHandlers
