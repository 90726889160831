import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './translations'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en-US',
  fallbackLocale: 'en-US',
  silentTranslationWarn: true,
  messages
})

export const updateHtmlLangAttribute = (locale) => {
  const rootElement = document.getElementsByTagName('html')
  rootElement[0].setAttribute('lang', locale)
}

export default i18n
