export default {
  groups: {
    colleaguesGroupAltText: 'Colleagues in this group',
    group: 'group',
    groups: 'groups',
    groupMenu: 'group menu',
    noGroups: {
      text: 'Want to learn more about the groups you work with every day? Create a group DiSC<sup>®</sup> map and get insights about your team.',
      cta: 'Create a group'
    },
    private: {
      text: 'Groups are disabled when you’re not sharing. To create group DiSC<sup>®</sup> maps and get insights on your teams, share with colleagues.',
      cta: '@:privateMessage.shareButton'
    },
    mismatchingMembers: 'A group member has been removed. They’ve either left Catalyst<sup>™</sup> or are no longer sharing.',
    wizard: {
      back: '@:ui.back',
      thingsToKnow: {
        title: 'Things to know',
        newTitle: 'Things to know...',
        start: 'Get started',
        bullets: {
          1: '1',
          2: '2',
          3: '3',
          definition1: '<b>Groups are private.</b> Only you can see your group page.',
          definition2: 'You can <b>add anyone in your organization who’s on Catalyst<sup>™</sup></b> and sharing with colleagues.',
          definition3: 'You can have <b>up to 25 people</b> in a group.',
          newDefinition1: 'You can <b>make this group private and visible only to you or share it</b> with people in the group.',
          newDefinition3: 'You can have <b>up to 26 people</b> in a group.'
        }
      },
      groupInfo: {
        title: 'Group information',
        continue: '@:ui.continue',
        name: {
          label: 'Group name',
          invalidFeedback: {
            required: 'This is a required entry',
            maxLength: 'Your group name can’t be more than 40 characters long.',
            noConflict: 'You already have a group with that name. Please enter a unique name for this group.'
          }
        },
        type: {
          label: 'Group type',
          options: {
            projectTeam: 'Project team',
            directReports: 'My direct reports',
            department: 'Department',
            colleagues: 'Close colleagues',
            leadTeam: 'Leadership team',
            other: 'Other'
          },
          invalidFeedback: '@:forms.general.invalid'
        },
        role: {
          label: 'Your role',
          options: {
            managerSup: 'Manager/supervisor',
            projectManager: 'Project manager',
            contributor: 'Individual contributor',
            hr: 'HR representative',
            exec: 'Executive',
            other: 'Other'
          },
          invalidFeedback: '@:forms.general.invalid'
        },
        sharing: {
          label: 'Share group with colleagues',
          status: 'On | Off'
        }
      },
      addColleagues: {
        title: 'Add colleagues',
        description: '<i>Size limit: 25 people</i>',
        saveAndView: 'Save & view group',
        save: '@:ui.save',
        search: '@:ui.search',
        limitMsg: 'You’ve reached the size limit of 25 people.',
        oneSelected: '<b><i>1 colleague selected</i></b>',
        multiSelected: '<b><i>{0} colleagues selected</i></b>'
      }
    },
    groupCard: {
      new: 'CREATE GROUP',
      switch: 'SWITCH GROUPS'
    },
    deleteModal: {
      title: 'Are you sure you want to delete this group?',
      ok: 'Yes, delete it',
      cancel: 'No, don’t delete it'
    },
    groupList: {
      title: 'Group list ({count} person) | Group list ({count} people)',
      styleTitle: '{style}-style list ({count} person) | {style}-style list ({count} people)',
      filter: '@:ui.filter:',
      filters: {
        D: '@:products.default.styles.D.name ({count})',
        i: '@:products.default.styles.i.name ({count})',
        S: '@:products.default.styles.S.name ({count})',
        C: '@:products.default.styles.C.name ({count})'
      },
      next: '@:ui.next',
      previous: '@:ui.previous',
      pageCount: '@:ui.pageCount',
      priorities: {
        label: 'Priority: | Priorities:',
        dc: '@:products.default.styles.C.priority, @:products.default.styles.DC.priority, @:products.default.styles.D.priority',
        d: '@:products.default.styles.DC.priority, @:products.default.styles.D.priority, @:products.default.styles.Di.priority',
        di: '@:products.default.styles.D.priority, @:products.default.styles.Di.priority, @:products.default.styles.i.priority',
        id: '@:groups.groupList.priorities.di',
        i: '@:products.default.styles.Di.priority, @:products.default.styles.i.priority, @:products.default.styles.iS.priority',
        is: '@:products.default.styles.i.priority, @:products.default.styles.iS.priority, @:products.default.styles.S.priority',
        si: '@:groups.groupList.priorities.is',
        s: '@:products.default.styles.iS.priority, @:products.default.styles.S.priority, @:products.default.styles.SC.priority',
        sc: '@:products.default.styles.S.priority, @:products.default.styles.SC.priority, @:products.default.styles.C.priority',
        cs: '@:groups.groupList.priorities.sc',
        c: '@:products.default.styles.SC.priority, @:products.default.styles.C.priority, @:products.default.styles.CD.priority',
        cd: '@:groups.groupList.priorities.dc'
      },
      extraPriorities: {
        label: 'Extra priority: | Extra priorities:',
        dc: '@:products.default.styles.DC.priority',
        d: '@:products.default.styles.D.priority',
        di: '@:products.default.styles.Di.priority',
        id: '@:products.default.styles.iD.priority',
        i: '@:products.default.styles.i.priority',
        is: '@:products.default.styles.iS.priority',
        si: '@:products.default.styles.Si.priority',
        s: '@:products.default.styles.S.priority',
        sc: '@:products.default.styles.SC.priority',
        cs: '@:products.default.styles.CS.priority',
        c: '@:products.default.styles.C.priority',
        cd: '@:products.default.styles.CD.priority'
      },
      groupOwner: 'Group owner',
      moreInfo: 'More info',
      noColleagues: 'No colleagues found'
    },
    members: 'members',
    details: {
      count: '{count} person | {count} people',
      editGroup: 'Edit Group'
    },
    editMenu: {
      edit: 'Edit group info',
      managePeople: 'Manage people',
      delete: 'Delete',
      leave: 'Leave group',
      hideUnhide: 'Unhide group | Hide group'
    },
    drawer: {
      search: 'Search groups',
      showMore: 'Show more groups ({groupCount})',
      showFewer: 'Show fewer groups',
      yourGroups: 'Your groups',
      showMoreContinua: 'Show all continua',
      showFewerContinua: 'Show fewer continua',
      groupsOwned: 'Groups',
      groupsShared: 'Groups shared with you',
      noSharedGroups: 'No shared groups',
      noGroups: 'No groups here',
      hiddenGroups: 'Hidden groups',
      showHiddenGroups: 'Show hidden groups',
      collapseHiddenGroups: 'Collapse hidden groups'
    },
    chart: {
      subtitle: 'Your group is made up of {D}% @:products.default.styles.D.name @:ui.style, {i}% @:products.default.styles.i.name @:ui.style, {S}% @:products.default.styles.S.name @:ui.style, and {C}% @:products.default.styles.C.name @:(ui.style).'
    },
    announcement: {
      title: 'Introducing Groups!',
      body: 'Project teams, friends, whatever you need! Group your colleagues together on one DiSC<sup>®</sup> map and work better together.',
      button: 'Create your first group',
      changingOrgs: {
        title: 'You\'ve changed organizations!',
        description: 'Welcome to {organizationName}.',
        subtitle: 'What\'s different?',
        cards: {
          colleagues: 'Your colleagues',
          colleaguesInfo: 'You\'ll only have access to colleagues from your current organization',
          groups: 'Your groups',
          groupsInfo: 'You\'ll only have access to groups from your current organization'
        },
        moreInfo: 'If you have questions, reach out to your admin at <a href="mailto:adminEmail">{adminEmail}</a> '
      }
    }
  },
  styleInsights: {
    discGraphAlt: 'DiSC map showing all group members with {groupStyle} style'
  }
}
