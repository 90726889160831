import { jwtDecode } from 'jwt-decode'
import { get, isEmpty } from 'lodash'
import { ExtendableError } from '../../util/error'
import pendo from '../../util/integrations/pendo'
import ApiService, { CancelToken, isCancel } from '../api'
import StorageService from '../storage'
import { CATALYST_SITE_ID } from '../../util/content/constants'

class AuthenticationError extends ExtendableError {
  constructor (errorCode, message) {
    super(message)
    this.name = 'AuthenticationError'
    this.message = message
    this.errorCode = errorCode
  }
}

class AvatarError extends ExtendableError {
  constructor (errorCode, message) {
    super(message)
    this.name = 'AvatarError'
    this.message = message
    this.errorCode = errorCode
  }
}

class PasswordResetError extends ExtendableError {
  constructor (errorCode, message) {
    super(message)
    this.name = 'PasswordResetError'
    this.message = message
    this.errorCode = errorCode
  }
}

class UserError extends ExtendableError {
  constructor (errorCode, message) {
    super(message)
    this.name = 'UserError'
    this.message = message
    this.errorCode = errorCode
  }
}

class PeopleError extends ExtendableError {
  constructor (errorCode, message) {
    super(message)
    this.name = 'PeopleError'
    this.message = message
    this.errorCode = errorCode
  }
}

class EmailCommunicationPreferencesError extends ExtendableError {
  constructor (errorCode, message) {
    super(message)
    this.name = 'EmailCommunicationPreferencesError'
    this.message = message
    this.errorCode = errorCode
  }
}

let searchToken = null

function cancelSearch () {
  if (searchToken) {
    searchToken.cancel()
  }

  searchToken = CancelToken.source()
  return searchToken
}

export function parsePeopleResult (response, size) {
  const people = get(response.data, 'content')
  const count = get(response.data, '_links.count')

  return {
    people,
    count,
    pages: Math.max(1, Math.ceil(count / size))
  }
}

const UserService = {

  /**
   * Verify learner by email
   * @param { string } data - parameters to be sent to request
   * @param { string } loginPostData.Email
   * @param { string } loginPostData.AccessCode
   */
  verifyEmail: async function ({ email, accessCode }) {
    const requestData = {
      method: 'POST',
      url: '/account/email',
      ignoredInterceptors: [404],
      data: {
        email,
        accessCode
      }
    }

    try {
      await ApiService.customRequest(requestData)
      return true
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.message)
    }
  },

  /**
   * Create a new learner account
   * @param { object } signupPostData - form data containing fields for registering a new learner account
   * @param { string } signupPostData.FirstName
   * @param { string } signupPostData.LastName
   * @param { string } signupPostData.Email
   * @param { string } signupPostData.Password
   * @param { string } signupPostData.Location
   * @param { string } signupPostData.Department
   * @param { string } signupPostData.Title
   * @param { string } signupPostData.PublicProfileSearch?
   * @param { string } signupPostData.CompanyProfileSearch?
   * @param { int } signupPostData.ImageID
   * @returns Auth
   * @throws AuthenticationError
   */
  register: async function (postBody, isSso = false) {
    const requestData = {
      method: 'POST',
      url: '/account',
      data: postBody
    }

    if (isSso) {
      try {
        await ApiService.customRequest(requestData)
        return true
      } catch (error) {
        throw new AuthenticationError(error.response.status, error.response.data.message)
      }
    } else {
      return processAuth(requestData)
    }
  },

  /**
   * Login the user and sync to localStorage
   * @param { object } loginPostData
   * @param { string } loginPostData.Email
   * @param { string } loginPostData.Password
   * @param { string } loginPostData.AccessCode
   * @returns Auth
   * @throws AuthenticationError
   */
  login: async function ({ ...postBody }) {
    const requestData = {
      method: 'POST',
      url: '/auth',
      data: { ...postBody }
    }
    return processAuth(requestData)
  },

  async exchangeToken () {
    const requestData = {
      method: 'GET',
      url: '/auth/exchange-token'
    }

    try {
      const response = await ApiService.customRequest(requestData, '/v4')
      return response.data
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.message)
    }
  },

  // Request to get if the user have access to partner dashboard
  async getCatalystFeatures () {
    const requestData = {
      method: 'GET',
      url: '/catalyst/features'
    }

    try {
      const response = await ApiService.customRequest(requestData, '/v4')
      return response.data
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.message)
    }
  },

  loginSso: async function (code, federation, state) {
    const requestData = {
      method: 'POST',
      url: '/sso/' + federation + '/' + code,
      params: { state }
    }
    return processAuth(requestData, true)
  },

  refresh: async function () {
    try {
      const user = StorageService.getUser()

      const decodedRefreshToken = jwtDecode(user.tokens.refresh_token)

      ApiService.removeAuthHeader()

      const requestData = {
        method: 'POST',
        url: '/protocol/openid-connect/token',
        baseURL: decodedRefreshToken.iss,
        skipAuthRefresh: true,
        data: new URLSearchParams({
          grant_type: 'refresh_token',
          refresh_token: user.tokens.refresh_token,
          client_id: decodedRefreshToken.azp
        })
      }

      const response = await ApiService.customRequest(requestData, '', '')
      const tokens = response.data

      const updatedUser = { ...user, tokens }

      StorageService.saveUser(updatedUser)
      ApiService.setAuthHeader(updatedUser.tokens.access_token)

      return updatedUser.tokens.access_token
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.message)
    }
  },

  adminLogin: async function (token) {
    const requestData = {
      method: 'GET',
      url: `/auth/token/${token}`
    }
    return processAuth(requestData)
  },

  federation: async function (email) {
    const requestData = {
      method: 'POST',
      url: '/auth/federation',
      baseURL: window.env.PUBLIC_USERS_API,
      data: { email, siteId: CATALYST_SITE_ID },
      ignoredInterceptors: [404]
    }

    try {
      const response = await ApiService.customRequest(requestData)

      return response.data
    } catch (error) {
      const errorData = error.response.data
      if (error.response.status === 404 && typeof errorData === 'string' &&
        errorData.startsWith('No federations')) {
        return {}
      }

      throw new AuthenticationError(error.response.status, error.response.data.message)
    }
  },

  /**
   * Logout the current user by removing the token from storage
   * Will also remove auth headers from future requests
   */
  async logout () {
    // Remove the token and remove header from Api Service
    StorageService.clearUser()

    const requestData = {
      method: 'POST',
      url: '/auth/invalidate',
      skipAuthRefresh: true
    }

    try {
      const response = await ApiService.customRequest(requestData)
      return response.data
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.message)
    }
  },

  /**
   * Post image data to store as user avatar
   * @param { object } avatarPostData
   * @param { string } avatarPostData.ImageData - base-64 encoded image data
   * @param { string } avatarPostData.ImageType - image type ("JPG", "PNG", etc.)
   */
  async avatar (avatarPostData) {
    const requestData = {
      method: 'POST',
      url: '/account/avatar',
      data: avatarPostData
    }

    try {
      const response = await ApiService.customRequest(requestData)
      return response.data
    } catch (error) {
      throw new AvatarError(error.response.status, error.response.data.message)
    }
  },

  /*
  * handles communication for password reset requests
  */
  async requestPasswordReset (email) {
    const requestData = {
      method: 'POST',
      url: '/account/reset',
      data: { email }
    }
    try {
      await ApiService.customRequest(requestData)
      return true
    } catch (error) {
      throw new PasswordResetError(error.response.status)
    }
  },

  /**
  * saves the new password for an account based on the function code
  * @param { String } functionCode - alphanumeric string representing reset request
  * @param { String } password
  */
  async resetPassword (functionCode, password) {
    const requestData = {
      method: 'PUT',
      url: '/account/reset/',
      ignoredInterceptors: [404],
      data: { functionCode, password }
    }
    try {
      await ApiService.customRequest(requestData)
      return true
    } catch (error) {
      throw new PasswordResetError(error.response.status)
    }
  },

  /**
   * saves updated password for an account based on current password
   * @param { String } email
   * @param { String } currentPassword
   * @param { String } newPassword
   */
  async changePassword (email, currentPassword, newPassword) {
    const requestData = {
      method: 'PUT',
      url: '/account/password/',
      data: { email, currentPassword, newPassword }
    }
    try {
      await ApiService.customRequest(requestData)
      return true
    } catch (error) {
      throw new PasswordResetError(error.response.status)
    }
  },

  /**
   * handles request/response data for account profile
   * requires an access token to be set
   */
  async profile () {
    try {
      const response = await ApiService.customRequest({
        method: 'GET',
        url: '/account'
      })

      return response.data
    // Service will fail if not authenticated, or not authenticated with permissions to an account
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.message)
    }
  },

  async search (query) {
    try {
      const cancelToken = cancelSearch()
      const response = await ApiService.customRequest({
        method: 'POST',
        url: '/account/comparisons',
        data: { search: query },
        params: {
          offset: 0,
          size: 10
        },
        cancelToken: cancelToken.token
      })

      return parsePeopleResult(response, 10)
    } catch (error) {
      if (!isCancel(error)) {
        throw new PeopleError(error.response.status, error.message)
      }
    }
  },

  async facilitatorSearch (query) {
    try {
      const cancelToken = cancelSearch()
      const response = await ApiService.customRequest({
        method: 'POST',
        url: '/facilitator/people',
        data: { search: query },
        params: {
          offset: 0,
          size: 10
        },
        cancelToken: cancelToken.token
      })

      return parsePeopleResult(response, 10)
    } catch (error) {
      if (!isCancel(error)) {
        throw new PeopleError(error.response.status, error.message)
      }
    }
  },

  /**
   * handles request/response data for account profile
   * requires an access token to be set
   * @param options { Object }
   * @param options.size { Number } - "number of results per page"
   * @param options.offset { Number } - "page of results to start from, defaults to 0"
   */
  async comparisons ({
    page = 1,
    size = 24,
    ...data
  }) {
    try {
      const response = await ApiService.customRequest({
        method: 'POST',
        url: '/account/comparisons',
        data,
        params: {
          offset: Math.max(0, page - 1) * size,
          size
        }
      })

      const result = parsePeopleResult(response, size)

      // don't want to add filtered results
      if (!data || isEmpty(data)) {
        pendo.updateComparisons(result.count)
      }

      return result

    // Service will fail if not authenticated, or not authenticated with permissions to an account
    } catch (error) {
      throw new PeopleError(error.response.status, error.message)
    }
  },

  async facilitatorComparisons ({
    page = 1,
    size = 15,
    ...data
  }) {
    try {
      const response = await ApiService.customRequest({
        method: 'POST',
        url: '/facilitator/people',
        data,
        params: {
          offset: Math.max(0, page - 1) * size,
          size
        }
      })

      const result = parsePeopleResult(response, size)

      // don't want to add filtered results
      if (!data || isEmpty(data)) {
        pendo.updateComparisons(result.count)
      }

      return result

    // Service will fail if not authenticated, or not authenticated with permissions to an account
    } catch (error) {
      throw new PeopleError(error.response.status, error.message)
    }
  },

  async learner (learnerId) {
    try {
      const response = await ApiService.customRequest({
        method: 'GET',
        url: `/account/comparisons/${learnerId}`
      })
      return response.data
    // Service will fail if not authenticated, or not authenticated with permissions to an account
    } catch (error) {
      throw new PeopleError(error.response.status, error.response.data.message)
    }
  },

  async facilitatorLearner (learnerId) {
    try {
      const response = await ApiService.customRequest({
        method: 'GET',
        url: `/facilitator/people/detail/${learnerId}`
      })
      return response.data
    // Service will fail if not authenticated, or not authenticated with permissions to an account
    } catch (error) {
      throw new PeopleError(error.response.status, error.response.data.message)
    }
  },

  /**
   * handles profile update request
   * requires an access token to be set
   * @param { Object } updateData - form data containing fields for registering a new learner account
   * @param { String } updateData.firstName
   * @param { String } updateData.lastName
   * @param { String } updateData.email
   * @param { String } updateData.department
   * @param { String } updateData.country
   * @param { String } updateData.state
   * @param { String } updateData.city
   * @param { Boolean } [updateData.publicProfileSearch]
   * @param { Boolean } [updateData.companyProfileSearch]
   */
  async updateProfile (updateData) {
    try {
      return await ApiService.customRequest({
        method: 'PUT',
        url: '/account',
        data: updateData
      })
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.message)
    }
  },

  async updateEmailCommunicationPreferences (flags) {
    const requestData = {
      method: 'PUT',
      url: '/account/Notification-Emails',
      data: flags
    }
    try {
      const response = await ApiService.customRequest(
        requestData
      )
      return response.data
    } catch (error) {
      throw new EmailCommunicationPreferencesError(error.response.status, error.response.data.message)
    }
  },

  async getEmailCommunicationPreferences () {
    const requestData = {
      method: 'GET',
      url: '/account/Notification-Emails'
    }
    try {
      const response = await ApiService.customRequest(
        requestData
      )
      return response.data
    } catch (error) {
      throw new EmailCommunicationPreferencesError(error.response.status, error.response.data.message)
    }
  },

  /**
  * verifies password reset function code
  */
  async verifyResetCode (functionCode) {
    const requestData = {
      method: 'GET',
      url: '/account/reset/' + functionCode,
      ignoredInterceptors: [404]
    }
    try {
      await ApiService.customRequest(requestData)
      return true
    } catch (error) {
      throw new PasswordResetError(error.response.status)
    }
  },

  /**
   * checks if there is a user for a given Access Code
   * will return learnerID=0 for a valid Access Code that has no learner record
   * @param { string } accessCode
   */
  async fetchUserByAccessCode (accessCode) {
    try {
      const response = await ApiService.customRequest({
        method: 'GET',
        url: '/accessCode/learner/' + accessCode,
        ignoredInterceptors: [404]
      })

      return response.data
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.message)
    }
  },

  /**
   * Adds access code to the logged in account
   * @param { String } accessCode
   */
  async accessCode (accessCode) {
    let response = null
    try {
      response = await ApiService.customRequest({
        method: 'POST',
        url: '/accessCode',
        ignoredInterceptors: [404],
        data: { accessCode: accessCode }
      })

      return response.data
    } catch (error) {
      throw new UserError(error.response.status, error.response.data.message)
    }
  }
}

/*
* handles request/response data to Auth API
*/
async function processAuth (requestData, isSso = false) {
  try {
    const authResponse = await ApiService.customRequest(requestData)

    const authData = authResponse.data
    const usernamesMatch = authData.state ? JSON.parse(decodeURIComponent(authData.state)).username.toLowerCase() === authData.userName.toLowerCase() : true

    const customAccessToken = authData.accessToken
    delete authData.accessToken
    delete authData.expires

    let user = { ...authData, isSso, showSsoAlert: false }

    // only fully log in if get all info
    if (authResponse.status !== 206 && usernamesMatch) {
      user.showSsoAlert = isSso

      // Exchange token for Keycloak
      ApiService.setAuthHeader(customAccessToken)
      const keycloakResponse = await UserService.exchangeToken()
      const keycloakAccessToken = keycloakResponse.access_token

      user = { tokens: keycloakResponse, ...user }

      StorageService.saveUser(user)
      ApiService.setAuthHeader(keycloakAccessToken)
    }

    return user
  } catch (error) {
    throw new AuthenticationError(error.response.status, error.response.data.message)
  }
}

export default UserService
export { UserService, PeopleError, AuthenticationError, PasswordResetError, UserError, AvatarError, EmailCommunicationPreferencesError }
