import { TextService } from '../../services/text'

/**
 * @module Text Module
 * @description Vuex module responsible for fetching/retreiving page text
 */
const CACHE_LIMIT = 15

export const initialState = {
  fetching: false,
  locale: 'en-US',
  messages: {},
  messagesCache: [],
  cardText: {},
  cardRoute: '',
  errorCode: 0,
  error: ''
}

const state = Object.assign({}, initialState)

const actions = {
  async page ({ commit, state }, route) {
    commit('fetchRequest')

    try {
      const cacheObject = state.messagesCache.find(mc => mc.route === route)

      if (cacheObject) {
        commit('updateMessages', cacheObject.messages)
      } else {
        const messages = await TextService.fetchPage(route, state.locale)
        commit('updateMessages', messages)
        commit('updateMessagesCache', { route, messages })
      }

      return true
    } catch (e) {
      commit('fetchError', { errorCode: e.errorCode, errorMessage: e.message })
      return false
    }
  },
  async cardText ({ commit, state }, route) {
    commit('fetchRequest')

    try {
      const cacheObject = state.messagesCache.find(mc => mc.route === route)

      if (cacheObject) {
        commit('updateMessages', cacheObject.messages)
      } else {
        const cardText = await TextService.fetchPage(route, state.locale)
        commit('updateCardText', { cardText, route })
        commit('updateMessagesCache', { route, messages: cardText })
      }
    } catch (e) {
      commit('fetchError', { errorCode: e.errorCode, errorMessage: e.message })
    }
  }
}

const mutations = {
  fetchRequest (state) {
    state.fetching = true
  },
  fetchCards (state) {
    state.fetching = true
    state.cardRoute = ''
  },
  updateLocale (state, locale) {
    state.locale = locale
  },
  updateMessages (state, messages) {
    state.fetching = false
    state.messages = messages
  },
  updateMessagesCache (state, { route, messages }) {
    const hasCache = state.messagesCache.some(mc => mc.route === route)
    if (hasCache) return

    state.messagesCache.push({ route, messages })

    if (state.messagesCache.length > CACHE_LIMIT) {
      state.messagesCache.shift()
    }
  },
  updateCardText (state, { cardText, route }) {
    state.fetching = false
    state.cardText = cardText
    state.cardRoute = route
  },
  fetchError (state, { errorCode, errorMessage }) {
    state.fetching = false
    state.errorCode = errorCode
    state.error = errorMessage
  }
}

export const text = {
  namespaced: true,
  state,
  actions,
  mutations
}
