import { compose, mapGettersToProps } from 'vuex-compose'
import OrganizationChanged from '../components/modal/OrganizationChanged.vue'
import { withHandlers } from 'vue-compose'
import StorageService from '../services/storage'

const wrapper = compose(
  withHandlers({
    viewed (feature) {
      StorageService.setFeatureAnnoucementViewed(feature)
    }
  }),
  mapGettersToProps({
    loggedIn: 'account/loggedIn',
    assigneeEmail: 'account/currentAssigneeEmail',
    organizationName: 'organization/organizationName'
  })
)

export default wrapper(OrganizationChanged)
