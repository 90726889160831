<template>
  <app-layout-base
    class="app-layout--full"
    :class="{ masked: menuIsOpen }"
    :showAlerts="showAlerts"
  >
    <template #header>
      <focus-trap :active="menuIsOpen">
        <app-header
          class="app-layout__nav"
          v-model="menuIsOpen"
          @closeNavMenu="closeNavMenu()"
          :items="items"
          :userName="userName"
          :avatar="avatar"
          :isDemoAccount="isDemoAccount"
          :isDemoUser="isDemoUser"
          :isFacilitator="isFacilitator"
          :isOrganizationAdminAccount="isOrganizationAdminAccount"
          :goToAdminButtonFeatureFlag="goToAdminButtonFeatureFlag"
          :goToAccountDashboardFeatureFlag="accountDashboardFeatureFlag"
          :hasDashboardAccess="hasDashboardAccess"
          :goToIntegratedFacilitationFeatureFlag="goToIntegratedFacilitationFeatureFlag"
        />
      </focus-trap>
    </template>
    <template #main>
      <div
        :aria-hidden="menuIsOpen"
        :style="{ 'min-height': `calc(100vh - 80px - ${footerHeight}px)` }"
      >
        <slot />
      </div>
    </template>
    <template #footer v-if="!hideFooter">
      <app-footer
        :aria-hidden="menuIsOpen"
        :assigneeEmail="assigneeEmail"
        :loggedIn="loggedIn"
      />
    </template>
  </app-layout-base>
</template>

<script>
import { appLayouts } from './AppConstants'
import AppLayoutBase from './AppLayoutBase.vue'
import AppFooter from './AppFooter.vue'
import AppHeader from './AppHeader'
import { filter } from 'lodash'
import { vueWindowSizeMixin } from 'vue-window-size'
import FocusTrap from '../components/utils/FocusTrap'

export default {
  name: appLayouts.DEFAULT,
  components: { AppLayoutBase, AppFooter, AppHeader, FocusTrap },
  mixins: [vueWindowSizeMixin],
  computed: {
    sections () {
      return this.content.slice(1).map((section) => ({
        id: section.id,
        title: this.$tt(section.id + '_title')
      }))
    },
    items () {
      const visiblePathways = this.pathways.filter(
        (pathway) => pathway.order > 0
      )

      const settings = {
        label: this.$tt('ui.accountSettings'),
        value: { name: 'settings' }
      }

      const reports = {
        label: this.$tt('ui.downloadReports'),
        value: { name: 'reports' }
      }

      const facilitator = {
        label: this.$tt('ui.seeAsFacilitator'),
        value: { name: 'facilitator' }
      }

      const getAdvice = {
        label: this.$tt('ui.getAdvice'),
        value: { name: 'getAdvice' }
      }

      const projectCheetah = {
        label: 'Cheetah',
        value: '/plus/'
      }

      const pathways = {
        links: filter(visiblePathways, ['pages', null]),
        tabs: filter(visiblePathways, 'pages')
      }

      const preAssessmentMenuItems = { pathways, settings, facilitator }

      return this.assessmentComplete
        ? {
            ...preAssessmentMenuItems,
            reports,
            getAdvice,
            ...(this.projectCheetahFeatureFlag ? { projectCheetah } : {})
          }
        : preAssessmentMenuItems
    },
    projectCheetahFeatureFlag () {
      return this.featureFlags?.PROJECT_CHEETAH ?? false
    },
    goToAdminButtonFeatureFlag () {
      return this.featureFlags?.FEATURE_FLAG_ADMIN_ACCESS ?? false
    },
    accountDashboardFeatureFlag () {
      return this.featureFlags?.FEATURE_FLAG_ACCOUNT_DASHBOARD ?? false
    },
    goToIntegratedFacilitationFeatureFlag () {
      return this.featureFlags?.INTEGRATED_FACILITATION ?? false
    }
  },
  props: {
    userName: {
      type: String,
      default: ''
    },
    avatar: {
      type: String,
      default: ''
    },

    /**
     * { Array<Object> } render node tree { id, children, bindings }
     */
    pathways: {
      type: Array,
      required: true,
      default: () => []
    },

    assessmentComplete: {
      type: Boolean,
      default: false
    },

    loggedIn: {
      type: Boolean,
      default: false
    },

    assessmentStarted: {
      type: Boolean,
      default: false
    },

    assigneeEmail: {
      type: String,
      default: ''
    },
    showAlerts: {
      type: Boolean,
      default: true
    },

    /**
     * Hides settings info in header for the demo experience
     * Mostly won't be true but defaulting to that for safety
     */
    isDemoAccount: {
      type: Boolean,
      default: true
    },

    isDemoUser: {
      type: Boolean,
      default: true
    },
    /**
     * Boolean we use to change header logo
     * if user viewing as Facilitator role
     */
    isFacilitator: {
      type: Boolean,
      default: false
    },
    isOrganizationAdminAccount: {
      type: Boolean,
      default: false
    },
    featureFlags: {
      type: Object,
      default: () => {}
    },
    hasDashboardAccess: {
      type: Boolean,
      default: false
    },
    hideFooter: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    menuIsOpen: false,
    footerHeight: 0,
    scrollHeight: 0
  }),
  methods: {
    closeNavMenu () {
      this.menuIsOpen = false
    },
    getFooterHeight () {
      this.footerHeight = document.getElementById('app-footer').offsetHeight
    }
  },
  mounted () {
    this.getFooterHeight()
  },
  watch: {
    windowWidth () {
      this.getFooterHeight()
    },
    menuIsOpen (to, from) {
      if (to) {
        document.body.style.overflowY = 'hidden'
        this.scrollHeight = window.pageYOffset
      } else {
        document.body.style.overflowY = ''
        window.scrollTo(0, this.scrollHeight)
      }
    }
  }
}
</script>

<style lang="scss">
@import "../styles/base";

.ribbon {
  margin-top: 80px;
}

.app-layout--full {
  position: relative;
  min-height: 100%;

  #app-header {
    position: fixed;
    top: 0;
    min-height: 0;
    width: 100%;
    z-index: $zindex-fixed + 10;
    transition: 0.5s $easeInOutSine;
  }

  #app-main {
    padding-top: 80px;
    height: 100%;

    @include media-breakpoint-down("sm") {
      padding-top: 70px;
    }
  }
}

.app-layout--full.masked #app-header {
  min-height: 100%;
}

* {
  margin: 0;
  padding: 0;
}
</style>
