<template>
  <b-modal
    class="announcement"
    ref="modal"
    :id="`${feature}-announcement`"
    :title="title"
    header-class="announcement__header"
    title-class="h3 announcement__title"
    content-class="announcement__content"
    body-class="announcement__body"
    footer-class="announcement__footer"
    ok-only
    ok-variant="confirm"
    :ok-title="buttonText"
    @ok="$emit('ok')"
    @hide="$emit('viewed', feature)"
  >
    <template #modal-header-close>
      <x-icon class="announcement__close" variant="dark" />
    </template>
    <template #default>
      <p class="announcement__text" v-html="body"></p>
      <img class="announcement__image" :src="imagePath" alt="" />
    </template>
  </b-modal>
</template>

<script>
import XIcon from '../icons/XIcon.vue'

export default {
  name: 'feature-announcement',
  components: { XIcon },
  props: {
    title: {
      type: String,
      default: ''
    },
    body: {
      type: String,
      default: ''
    },
    imagePath: {
      type: String,
      default: ''
    },
    feature: {
      type: String,
      default: 'feature'
    },
    buttonText: {
      type: String,
      default: 'ok'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/base";

::v-deep .announcement {
  &__header {
    padding: 2rem 1rem 0;

    @include media-breakpoint-up('md') {
      padding: 2rem 2rem 0;
    }
  }

  &__title {
    margin: 0;
    width: 100%;
    text-align: center;
  }

  &__close {
    width: 1rem;
    height: 1rem;
  }

  &__content {
    max-height: 100%;
    overflow-y: hidden;
  }

  &__body {
    padding: 0;
    text-align: center;
    margin: 1rem 1rem 0;
    font-weight: $font-weight-normal;

    @include media-breakpoint-up('md') {
      margin: 1rem 2rem 0;
    }
  }

  &__image {
    max-width: 20rem;
  }

  &__footer {
    justify-content: center;
    padding: 2rem 1rem;

    @include media-breakpoint-up('md') {
      padding: 2rem;
    }
  }
}

::v-deep .modal-dialog {
  margin-top: 7rem;

  &.modal-md {
    max-width: 37rem;
  }

  p.announcement__text {
    margin-bottom: 2rem;
  }
}
</style>
