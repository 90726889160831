import ApiService from '../api'
import { ExtendableError } from '../../util/error'

class HealthError extends ExtendableError {
  constructor (errorCode, message) {
    super(message)
    this.name = 'HealthError'
    this.message = message
    this.errorCode = errorCode
  }
}

const HealthService = {

  /**
   * Fetch maintenance flag, if exists
   * @returns Boolean
   * @throws HealthError
   */
  async fetchMaintenanceFlag () {
    try {
      const response = await ApiService.customRequest({
        method: 'GET',
        url: '/health/maintenance'
      })

      return response.data.toString().toLowerCase() === 'true'
    } catch (error) {
      throw new HealthError(error.response.status, error.response.data.message)
    }
  },

  /**
   * Calls RootBeerAPI healthz
   * @returns Boolean
   * @throws HealthError
   */
  async checkRootbeerHealth () {
    try {
      await ApiService.customRequest({
        method: 'GET',
        url: '/healthz'
      }, '', '')
      return true
    } catch (error) {
      throw new HealthError(error.response.status, error.response.data.message)
    }
  },

  /**
   * Calls RootBeerAPI dependencies health check
   * @returns Boolean
   * @throws HealthError
   */
  async checkRootbeerDependencies () {
    try {
      await ApiService.customRequest({
        method: 'GET',
        url: '/healthz/dependencies',
        ignoredInterceptors: [503]
      }, '', '')
      return true
    } catch (error) {
      throw new HealthError(error.response.status, error.response.data.message)
    }
  }
}

export default HealthService
export { HealthService, HealthError }
